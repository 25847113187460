<template>
  <v-container fluid class="items-list">
    <ListTopPagination
      v-model="organizationsListOptions.page"
      :total-pages="pages"
      :expand-all="expandAll"
      model="organizations"
    />
    <v-data-table
      :headers="headers"
      :items="organizationsItems"
      :expanded.sync="expanded"
      :hide-default-footer="true"
      :item-class="itemStyle"
      :options.sync="organizationsListOptions"
      :loading="organizationsLoading"
      :loading-text="$i18n.t('organizations.loading')"
      :server-items-length="organizationsItems.length"
      item-key="id"
      show-expand
      class="elevation-1"
      @click:row="rowClick"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <OrganizationsListItemExpanded
          :item="item"
          :headers="headers"
        />
      </template>
    </v-data-table>
    <ListBottomPagination
      v-model="organizationsListOptions.page"
      :total-pages="pages"
    />
  </v-container>
</template>

<script>
import helper from '@/helper'
import ListTopPagination from '@/components/Shared/ListTopPagination'
import ListBottomPagination from '@/components/Shared/ListBottomPagination'
import allMethods from '@/components/allMethods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import OrganizationsListItemExpanded from '@/components/Organizations/OrganizationsListItemExpanded'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'OrganizationsList',

  components: {
    OrganizationsListItemExpanded,
    ListBottomPagination,
    ListTopPagination,
  },

  data () {
    return {
      page: 6,
      expanded: [],
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    headers () {
      const headers = [
        { text: this.$i18n.t('organizations.name'), value: 'name' },
        { text: this.$i18n.t('organizations.country'), value: 'country.name' },
        { text: this.$i18n.t('organizations.language'), value: 'language.name' },
      ]
      return headers
    },

    pages () {
      return Math.ceil(this.organizationsItemsTotal / this.organizationsListOptions.itemsPerPage)
    },
  },

  methods: {
    ...allMethods,

    rowClick (work, extra) {
      // Toggle expanded
      extra.expand(!extra.isExpanded)
    },

    closeAllExpanded () {
      this.expanded = []
    },

    expandAll () {
      if (this.expanded && this.expanded.length > 10) {
        this.closeAllExpanded()
      } else {
        this.expanded = this.organizationsItems
      }
    },

    itemStyle (item) {
      if (item.index % 2) { return 'odd-row' }
    },

    formatDateTime (date) {
      return helper.formatDateTime(date)
    },
  },
}
</script>

<style>
.v-data-table__expanded {
  cursor: pointer;
}
</style>
