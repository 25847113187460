const shared = {
  '@class': 'string',
  id: 'number',
  token: 'string',
  quantity: 'number',
  quantity_unit: 'reference',
}

const byModelName = {
  work_cost_items: {
    cost_type: 'string',
    description: 'string',
  },
  spare_part_items: {
    spare_part: 'reference',
  },
  expense_items: {
    expense: 'reference',
  },
}

export default {
  get: (modelName) => {
    return { ...shared, ...byModelName[modelName] }
  },
}
