<template>
  <v-container class="inspection-expansion">
    <v-dialog
      max-width="1200"
      v-model="showProductInstancesShowModal"
    >
      <ProductInstanceInfo
        :is-inspection="true"
        :load-inspection-product-instances="loadInspectionProductInstances"
      />
    </v-dialog>
    <v-row>
      <v-expansion-panels
        v-model="panel"
        accordion flat
      >
        <v-expansion-panel
          active-class="elevation-3"
        >
          <v-expansion-panel-header
            :class="'h2 ' + (!readOnly ? 'header-green' : '')"
          >
            {{ $i18n.t('inspection.productInstancesTitle').toUpperCase() }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-container
              v-if="loaded"
              fluid class="pa-0"
            >
              <v-row>
                <v-col cols="12">
                  <ProductInstancesList
                    :is-inspection="true"
                    :inspection-product-instances="productInstances"
                    :modal="true"
                    :read-only="readOnly"
                    :show-new-inspection-product-instance-form="showNewInspectionProductInstanceForm"
                    :load-inspection-product-instances="loadInspectionProductInstances"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-progress-linear indeterminate color="grey"/>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ProductInstancesList from '@/components/ProductInstances/ProductInstancesList'
import worksQueries from '@/components/Works/worksQueries'
import productInstancesQueries from '@/components/ProductInstances/productInstancesQueries'
import allMethods from '@/components/allMethods'
import ProductInstanceInfo from '@/components/ProductInstances/ProductInstanceInfo'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'InspectionProductInstances',

  components: { ProductInstanceInfo, ProductInstancesList },

  data () {
    return {
      loaded: false,
      productInstances: [],
      panel: 0,
      model: 'productInstances',
    }
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  created () {
    this.loadInspectionProductInstances()
    // Open expansion panel when read only in step 4
    if (this.readOnly) {
      this.panel = 0
    }
  },

  watch: {
    productInstanceDeleteTrigger (value) {
      this.loadInspectionProductInstances()
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...allMethods,

    showNewInspectionProductInstanceForm () {
      const activityPark = this.inspection.activityPark
      this.showProductInstanceEditModal('new', {
        activityPark: JSON.parse(JSON.stringify(activityPark)),
        callback: this.loadInspectionProductInstances,
      })
    },

    loadInspectionProductInstances () {
      if (!this.inspection.activityPark) { return }
      this.loaded = false
      const filters = {
        _activity_park_id: this.inspection.activityPark.id,
        _in_operation: true,
        offset: 0,
        limit: 1000,
        order: 'updated_at asc'
      }
      this.$store.dispatch('getListItems', {
        model: 'product_instances',
        queries: productInstancesQueries.forList,
        filters,
      }).then(response => {
        if (response && response.items) {
          this.productInstances = response.items
        }
        this.loaded = true
      })
    },
  },
}
</script>
