<template>
  <div class="multiselect-dropdown">
    <v-text-field
      v-model="searchTerm"
      :label="dynamicLabel"
      ref="input"
      hide-details
      single-line
      autocomplete="off"
      :clearable="false"
      v-bind="styleAttributes"
      @click="openMenu($event)"
      @focus="focus($event)"
      @keyup="openMenu($event)"
    >
      <template v-slot:append>
        <div class="input-actions">
          <div
            v-if="selectedOptions.length > 0"
            class="badge green-bg"
          >
            {{ selectedOptions.length }}
          </div>
          <v-btn
            v-if="selectedOptions.length > 0"
            fab small text
            @click.stop.prevent="clearSelected()"
          >
            <v-icon>
              fa-times
            </v-icon>
          </v-btn>
          <v-btn fab small text>
            <v-icon>
              {{ showMenu ? 'fa-angle-up' : 'fa-angle-down' }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-text-field>
    <v-menu
      v-model="showMenu"
      :activator="$refs.input && $refs.input.$el"
      offset-y
      :close-on-content-click="false"
    >
      <div style="max-height: 400px;">
        <v-list>
          <v-list-item v-if="options.length === 0">
            <v-list-item-title>{{ $i18n.t('general.searchByName') }} ...</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(selectedOption, i1) in selectedOptions.filter(item => !optionIds.includes(item.id))"
            :key="i1 + '-'"
            @click.stop="toggleOption(selectedOption)"
          >
            <v-list-item-action>
              <v-simple-checkbox
                :value="true"
                @click.stop="toggleOption(selectedOption)"
              />
            </v-list-item-action>
            <v-list-item-title>{{ selectedOption.summary }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(option, i2) in options"
            :key="i2"
            :light="selectedIds.includes(option.id)"
            @click.stop="toggleOption(option)"
          >
            <v-list-item-action>
              <v-simple-checkbox
                :value="selectedIds.includes(option.id)"
                @click.stop="toggleOption(option)"
              />
            </v-list-item-action>
            <v-list-item-title>{{ option.summary }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import api from '@/store/api'

export default {
  name: 'MultiselectDropdown',

  data () {
    return {
      showMenu: false,
      options: [],
      searchTerm: '',
      searchOption: null,
      loadingOptions: false,
      timer: null,
      menuOpeningDisabled: false,
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: '',
    },
    styleAttributes: {
      type: Object,
      default: () => {},
    },
    searchClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    loadOnFocus: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    showMenu () {
      // When after 2 seconds menu is closed, user has finished typing
      // clear search term to show selected options names
      setTimeout(() => {
        if (this.showMenu) { return }
        this.searchTerm = ''
      }, 2000)
    },

    searchTerm () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.searchOptionsByName(this.searchTerm)
      }, 300)
    },
  },

  computed: {
    selectedOptions () {
      return this.item[this.fieldName]
    },

    selectedIds () {
      return this.selectedOptions.map(item => item.id)
    },

    optionIds () {
      return this.options.map(item => item.id)
    },

    dynamicLabel () {
      if (this.selectedOptions.length > 0) {
        return this.selectedOptions.map(i => i.summary).join(', ')
      }
      return this.label
    },
  },

  methods: {
    clearSelected () {
      this.menuOpeningDisabled = true
      this.item[this.fieldName] = []
      this.showMenu = false
      this.searchTerm = ''
      setTimeout(() => {
        this.menuOpeningDisabled = false
      }, 400)
      this.$emit('change')
    },

    toggleOption (option) {
      if (this.selectedIds.includes(option.id)) {
        this.removeOption(option)
      } else {
        this.selectOption(option)
      }
      this.$emit('change')
    },

    focus (e) {
      if (this.loadOnFocus) {
        this.searchOptionsByName(this.searchTerm)
      }
      this.openMenu(e)
    },

    openMenu (e) {
      if (e && e.key === 'Escape') { return }
      setTimeout(() => {
        if (this.menuOpeningDisabled) {
          this.$refs.input.blur()
          return
        }
        this.showMenu = true
      }, 300)
    },

    removeOption (option) {
      const index = this.selectedIds.indexOf(option.id)
      this.$delete(this.item[this.fieldName], index)
    },

    selectOption (option) {
      // Filter with same objet exists
      if (this.selectedIds.includes(option.id)) { return }
      // Get new filter from dropdown
      this.$set(this.item[this.fieldName],
        this.selectedOptions.length,
        JSON.parse(JSON.stringify(option))
      )
    },

    searchOptionsByName (searchName) {
      this.loadingOptions = true
      api.searchOptionsByName({
        searchString: searchName,
        objectClass: this.searchClass,
        moduleClass: this.model,
        tokenOrId: this.item.token ? this.item.token : this.item.id,
        fieldName: this.fieldName,
        parent: this.parent,
        parentField: this.parentField,
      }).then(response => {
        this.$store.dispatch('globalErrorDisplay', response)
        if (response && response.items) {
          this.options = response.items
        }
        this.loadingOptions = false
      })
    },
  },
}
</script>

<style lang="scss">
.multiselect-dropdown {
  .input-actions {
    margin-right: -15px;
  }
  .v-btn--fab.v-size--small {
    width: 30px;
    height: 30px;
  }
  .badge {
    float: left;
    margin-top: 2px;
    margin-right: 5px;
    line-height: 18px;
  }
}
</style>
