<template>
  <v-container class="dialog-container">
    <v-row class="header-row">
      <v-col cols="10" class="h1">
        {{ $i18n.t('general.detailedSearch').toUpperCase() }}:
      </v-col>
      <v-col cols=2 class="text-right ma-0 pa-0">
        <v-btn small fab text @click="showProductInstancesSearchForm = false">
          <v-icon >
            fa-times
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="content-row mb-5">
      <v-col>
        <v-container
          fluid class="search-form"
          :style="{ height: dialogHeight + 'px' }"
        >
          <v-row>
            <v-col cols="12">
              {{ $i18n.t('general.searchExplanation') }}
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('productInstances.searchEquipmentInfoTitle') }}:
            </v-col>
            <v-col cols="12">
              {{ $i18n.t('productInstances.installationDate') }}
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="filters.installation_from_month"
                :items="Array.from({ length: 12 }, (_, i) => i + 1)"
                :label="$i18n.t('general.month_placeholder')"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filters.installation_from_year"
                :items="$store.getters.yearOptions"
                :label="$i18n.t('general.year_placeholder')"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="2" class="text-center align-self-center">
              <v-icon small>fa-minus</v-icon>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="filters.installation_to_month"
                :items="Array.from({ length: 12 }, (_, i) => i + 1)"
                :label="$i18n.t('general.month_placeholder')"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filters.installation_to_year"
                :items="$store.getters.yearOptions"
                :label="$i18n.t('general.year_placeholder')"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('productInstances.product_instance_type')"
                :load-on-focus="true"
                :style-attributes="textFieldFormatAttributes"
                search-class="product_instance_types"
                field-name="product_instance_type"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('productInstances.product_instance_category')"
                :load-on-focus="true"
                :style-attributes="textFieldFormatAttributes"
                search-class="product_instance_categories"
                field-name="product_instance_category"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('productInstances.manufacturer')"
                :load-on-focus="true"
                :style-attributes="textFieldFormatAttributes"
                search-class="manufacturers"
                field-name="manufacturer"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectString
                :item="filters"
                :label="$i18n.t('productInstances.productCode')"
                field-name="product_code"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectString
                :item="filters"
                :label="$i18n.t('productInstances.productNumber')"
                field-name="product_number"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.material"
                :items="$store.getters.materials"
                :label="$i18n.t('productInstances.material.title')"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.foundation"
                :items="$store.getters.foundations"
                :label="$i18n.t('productInstances.foundation.title')"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('productInstances.safety_platform_type')"
                search-class="safety_platform_types"
                field-name="safety_platform_type"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('productInstances.searchOrganizationInfoTitle') }}:
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="globalFilters"
                :label="$i18n.t('general.all') + ' ' + $i18n.t('organizations.title').toLocaleLowerCase()"
                search-class="organizations"
                field-name="organization"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12"><div class="h-spacer"/></v-col>
            <v-col cols="12" class="h2">
              {{ $i18n.t('works.searchActivityParkInfo') }}:
            </v-col>

            <v-col cols="12" class="mb-5">
              <MultiselectDropdown
                :item="globalFilters"
                :label="$i18n.t('general.all') + ' ' + $i18n.t('activityParks.title').toLocaleLowerCase()"
                search-class="activity_parks"
                field-name="activity_park"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="buttons-row">
      <v-col cols="6" >
        <v-btn
          color="#8cc477"
          dark
          v-bind="popupButtonFormatAttributes"
          @click="search()"
        >
          {{ $i18n.t('general.showSearchResults') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="#666"
          block
          outlined
          v-bind="popupButtonFormatAttributes"
          @click="clear()"
        >
          {{ $i18n.t('general.clearSearch') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import allMethods from '@/components/allMethods'
import MultiselectDropdown from '@/components/Shared/MultiselectDropdown'
import MultiselectString from '@/components/Shared/MultiselectString'
import moment from 'moment'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ProductInstancesSearchForm',

  components: {
    MultiselectString,
    MultiselectDropdown,
  },

  data () {
    return {
      model: 'productInstances',
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    filters () {
      return this.productInstancesFilters
    },
  },

  methods: {
    ...allMethods,

    search () {
      this.goToFirstPage()
      this.getListItems()
      this.showProductInstancesSearchForm = false
    },

    clear () {
      this.clearSearch()
      this.search()
    },
  },
}
</script>
