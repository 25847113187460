import axios from 'axios'
import api from '@/store/api/index'

export default {

  acceptCookiePolicy () {
    return axios.post('/api/people/accept_privacy_policy')
  },

  saveAttachment ({
    forObjectClass,
    forFieldName,
    forObjectId,
    attachmentType,
    file
  }) {
    const bodyFormData = new FormData()
    bodyFormData.set('attachments[][media_type]', attachmentType)
    bodyFormData.set('attachments[][attachable_association]', forFieldName)
    bodyFormData.append('attachments[][uploaded_data]', file)

    return new Promise(resolve => {
      axios.post('/api/' + forObjectClass + '/' + forObjectId + '/attachment?q=filename',
        bodyFormData,
        this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
          if (err.response && err.response.data) {
            resolve(err.response.data)
          }
          resolve([])
        })
    })
  },

  // Called by saveItem action
  // API is not updating images order by itself
  // Also when updating images order right after sorting by user (for new images)
  // relation between image and parent item is lost
  saveItemImagesOrder (item) {
    return new Promise(resolve => {
      if (item && item.images) {
        const promises = []
        item.images.forEach((image, index) => {
          promises.push(this.saveItem('attachments', {
            id: image.id,
            order: index,
          }, [], {}))
        })
        Promise.all(promises).then(responses => {
          resolve(responses)
        })
      } else {
        resolve(true)
      }
    })
  },

  changePassword (current_password, password, password_confirmation) {
    return this.sendPostRequestFormData('/api/change_password', {
      current_password,
      password,
      password_confirmation,
    })
  },

  saveItem (objectClass, item, queries = [], filters = {}) {
    return new Promise((resolve) => {
      if (item.id) { // item.token || - if no id and token, send POST instead
        axios.put(
          '/api/' + objectClass + '/' + (item.token || item.id) + '?' +
          queries.map(query => 'q[]=' + query).join('&') + '&' +
          Object.keys(filters).map(key => key + '=' + filters[key])
            .join('&'),
          item,
          this.requestOptions()
        ).then((response) => {
          if (response.data) {
            resolve(response)
          }
          resolve({})
        }).catch((err) => {
          resolve(err.response)
        })
      } else {
        this.getToken(objectClass, item, []).then(token => {
          if (token) {
            axios.post(
              '/api/' + objectClass + '/' + token, item,
              this.requestOptions()).then((response) => {
              if (response.data) {
                resolve(response)
              }
              resolve({})
            }).catch((err) => {
              resolve(err.response)
            })
          }
        })
      }
    })
  },

  saveActivityParkComment (id, comment) {
    return new Promise((resolve) => {
      if (id) {
        axios.post(
          '/api/activity_parks/new_comment/' + id,
          { comment: comment },
          this.requestOptions()
        ).then((response) => {
          if (response.data) {
            resolve(response)
          }
          resolve({})
        }).catch((err) => {
          resolve(err.response)
        })
      }
    })
  },

  hideActivityPark (id) {
    return new Promise((resolve) => {
      if (id) {
        axios.post(
          '/api/activity_parks/hide/' + id,
          { },
          this.requestOptions()
        ).then((response) => {
          if (response.data) {
            resolve(response)
          }
          resolve({})
        }).catch((err) => {
          resolve(err.response)
        })
      }
    })
  },

  toggleWorkInReport (id) {
    return new Promise((resolve) => {
      if (id) {
        axios.post(
          '/api/works/toggle_shown_on_report/' + id,
          { },
          this.requestOptions()
        ).then((response) => {
          if (response.data) {
            resolve(response)
          }
          resolve({})
        }).catch((err) => {
          resolve(err.response)
        })
      }
    })
  },

  sendProcessEventAction (objectClass, itemId, action, queries = []) {
    return new Promise((resolve) => {
      if (!objectClass || !itemId || !action) {
        resolve({})
      }
      const queriesUrl = queries.length > 0
        ? queries.map(q => 'q[]=' + q).join('&')
        : 'q=!id'
      const url = '/api/' + objectClass + '/' + itemId + '/' +
        action + '?' + queriesUrl
      axios.put(
        url,
        {},
        this.requestOptions()
      )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
          resolve(err.response.data)
        })
    })
  },
}
