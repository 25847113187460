import ratingsQueries from '@/components/Ratings/ratingsQueries'

export default {
  getRatingsListItems () {
    return new Promise(resolve => {
      this.ratingsLoading = true
      window.scrollTo(0, 0)
      this.$store.dispatch('getListItems', {
        model: 'ratings',
        filters: this.getRatingsListFilters(),
        queries: [],
      }).then(response => {
        resolve(response)
        this.$nextTick(() => {
          this.ratingsLoading = false
        })
      })
    })
  },

  getRatingsListFilters () {
    const filters = this.getListGeneralFilters('ratings')
    const ratingsFilters = { filters, ...this.activityParksFilters }
    delete ratingsFilters._activity_park_id

    if (ratingsFilters.limit) {
      filters.limit = ratingsFilters.limit
    }
    return filters
  },
}
