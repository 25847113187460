<template>
  <v-container
    fluid
    style="max-width: 1600px;"
    class="body-container pa-0 elevation-3"
  >
    <ListSearchToolbar
      model="ratings"
      @change="updateList"
    />
    <ListTitleToolbar v-if="ratingsItems.length > 0" model="ratings" />
    <RatingsList v-if="ratingsItems.length > 0" />
  </v-container>
</template>
<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ListSearchToolbar from '@/components/Shared/ListSearchToolbar'
import allMethods from '@/components/allMethods'
import RatingsList from '@/components/Ratings/RatingsList'
import ListTitleToolbar from '@/components/Shared/ListTitleToolbar'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'Ratings',

  data () {
    return {
      model: 'ratings',
    }
  },

  components: {
    ListSearchToolbar,
    ListTitleToolbar,
    RatingsList,
  },

  watch: {
    ratingsListOptionsTrigger () {
      this.getListItems()
    },
  },

  created () {
    this.getListItems()
  },

  computed: {
    ...mapFields(Object.keys(state)),

    ratingsListOptionsTrigger () {
      return JSON.stringify(this.ratingsListOptions)
    }
  },

  methods: {
    ...allMethods,

    updateList () {
      this.getListItems().then(() => {
        this.activityParksResultSetFilters = this.currentFilters
      })
    }
  }
}
</script>
