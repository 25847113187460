const worksDefaultFilters = {
  main_object_state_id: [],
  work_type: [],
  priority: [],
  responsiblePeople: []
}

const worksListDefaultOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: ['assigned_time'],
  sortDesc: [true],
}

const workEditModalDefaultProps = {
  dialog: false,
  id: null,
  activityPark: null,
  callback: null,
  template: 'default',
}

const worksListModalDefaultProps = {
  show: false,
  filters: JSON.parse(JSON.stringify(worksDefaultFilters)),
}

export default {
  worksItems: [],
  worksItemsTotal: 0,
  worksAllIds: [],

  workEditModalDefaultProps: JSON.parse(JSON.stringify(workEditModalDefaultProps)),
  workEditModalProps: JSON.parse(JSON.stringify(workEditModalDefaultProps)),
  worksListModalDefaultProps: JSON.parse(JSON.stringify(worksListModalDefaultProps)),
  worksListModalProps: JSON.parse(JSON.stringify(worksListModalDefaultProps)),

  workItemForEdit: {},
  worksLoading: true,
  workItemLoading: false,
  worksListDefaultOptions: worksListDefaultOptions,
  worksDefaultFilters: worksDefaultFilters,
  worksListOptions: JSON.parse(JSON.stringify(worksListDefaultOptions)),
  worksFilters: JSON.parse(JSON.stringify(worksDefaultFilters)),
  deleteReasonsByModel: {
    works: {
      work_dismantled: 'works.removeReason.work_dismantled',
      work_fixed: 'works.removeReason.work_fixed',
      work_removed: 'works.removeReason.work_removed',
      work_unnecessary: 'works.removeReason.work_unnecessary',
      work_updated: 'works.removeReason.work_updated',
    },
    activityParks: {
      one: 'one',
      two: 'two',
    },
  },
  workDeleteConfirmForWorkId: null,

  showWorksSearchForm: false,
  showWorksEditModal: false,
  workPriorityColors: {
    c: '#74cfd1',
    d: '#9cc55b',
    b: '#e6cb53',
    a: '#dd553d',
    x: '#666',
  },
  workStateIds: {
    new: 17,
    proposed: 9,
    shoppingCart: 10,
    ordered: 11,
    completed: 12,
    assigned: 21, // For home page inspection works
  },
  workPriorities: ['a', 'b', 'c', 'd', 'x'],
  workActiveStates: [17, 10],
  workTypeIds: {
    extra: 51,
    actionPlan: 5,
    task: 52,
  },
  workTypeCustomerReport: {
    id: 85,
    summary_fi: 'Asiakasraportti',
    summary_en: 'Customer report',
    summary_se: 'Kundens egen rapportering',
  },
  workSearchWorkTypeQuickButtonWorkTypeId: 5,
  workObjectStates: [],
}
