import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import { updateField, getField } from 'vuex-map-fields'
import appActions from '@/store/actions/appActions'
import sessionActions from '@/store/actions/sessionActions'
import itemActions from '@/store/actions/itemActions'
import fileActions from '@/store/actions/fileActions'
import moment from 'moment'
import i18n from '@/locale/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations: {
    updateField,
  },
  actions: {
    ...appActions,
    ...sessionActions,
    ...itemActions,
    ...fileActions,
  },
  getters: {
    getField,

    yearOptions: () => {
      const optionsCount = 30
      const currentYear = moment().format('YYYY')
      return [...new Array(10)].map((_, i) => {
        return currentYear - i
      })
    },

    materials: state => {
      return Object.keys(state.staticLists.material).map(key => {
        const text = state.staticLists.material[key]
        return {
          text: i18n.t(text),
          value: key,
        }
      })
    },

    foundations: state => {
      return Object.keys(state.staticLists.foundation).map(key => {
        const text = state.staticLists.foundation[key]
        return {
          text: i18n.t(text),
          value: key,
        }
      })
    },

    product_codes: state => {
      return Object.keys(state.staticLists.product_code).map(key => {
        const text = state.staticLists.product_code[key]
        return {
          text: i18n.t(text),
          value: i18n.t(text).toUpperCase(),
        }
      })
    },

    workTypes: state => {
      return Object.keys(state.staticLists.cost_types).map(key => {
        const text = state.staticLists.cost_types[key]
        return {
          text: i18n.t(text),
          value: key,
        }
      })
    },
  },
  modules: {
  }
})
