<template>
  <v-dialog
    v-model="showDialog"
    width="800"
  >
    <v-container fluid class="white" ma-0 pa-5>
      <v-row v-if="saving">
        <v-col cols="12">
          <v-progress-linear
            indeterminate
            color="grey"
            class="my-5"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <h2>{{ $i18n.t('activityParks.giveFeedback') }}</h2>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="feedbackText"
            v-bind="textFieldFormatAttributes"
            rows="8"
          />
        </v-col>
        <v-col cols="6">
          <v-btn
            color="#8cc477"
            dark
            v-bind="popupButtonFormatAttributes"
            @click="sendComment"
          >
            {{ $i18n.t('general.send') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            color="#666"
            block
            outlined
            v-bind="popupButtonFormatAttributes"
            @click="showDialog = false"
          >
            {{ $i18n.t('general.close') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/store/state'
import allMethods from '@/components/allMethods'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParkCommentForm',

  data () {
    return {
      saving: false,
      feedbackText: '',
      dialog: true,
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    ...allMethods,

    sendComment () {
      this.saving = true
      this.sendActivityParkComment(this.activityParkFeedbackModalProps.id, this.feedbackText)
        .then(success => {
          // If got error, it is shown in the action already
          // If comment post was ok, close the form
          if (success) {
            this.activityParkFeedbackModalProps.callback()
            this.showDialog = false
          }
          this.saving = false
        })
    },
  },
}
</script>
