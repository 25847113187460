export default {
  app: {
    attachment: {
      error: {
        media_type_mismatch: 'Wrong media type'
      },
    },
  },
  general: {
    actions: 'Funktioner',
    add: 'Lägg till',
    addNewFile: 'Lägg till ny fil',
    addPicture: 'Lägg till en bild',
    address: 'Adress',
    all: 'Alla',
    cancelAndCloseForm: 'Avbryt',
    city: 'Stad',
    clearSearch: 'Ta bort sökning',
    clock: 'Kl.',
    close: 'Stäng',
    county: 'Landskap',
    detailedSearch: 'Begränsa sökning',
    downloadImage: 'Download image',
    expandAll: 'Öppna alla',
    feedback: 'Feedback',
    files: 'Filer',
    home: 'Framsida',
    hours: 'Timmar',
    loading: 'Loading..',
    logout: 'Logga ut',
    minutes: 'Minuter',
    month_placeholder: 'mån',
    name: 'Namn',
    next: 'Nästa',
    no_items_found: 'Inga objekt hittade',
    objectState: 'Status',
    organization: 'Organisation',
    page: 'Sida',
    pleaseFillAllRequiredFields: 'Please fill all required fields',
    pressEnterForMultiple: 'Tryck ENTER för att lägga till fler',
    previous: 'Föregående',
    quantity: 'St',
    save: 'Spara',
    saveChanges: 'Spara ändringarna',
    saved: 'Sparat',
    search: 'Sök',
    searchByName: 'Sök med namn',
    searchExplanation: 'Fyll i ett eller fler sökkriterier',
    searching: 'Söker...',
    searchResults: 'Sökresultat',
    searchTerm: 'Sökord',
    searchText: 'Sök...',
    send: 'Skicka',
    showSearchResults: 'Visa sökkriterier',
    userManual: 'Användarmanual',
    year_placeholder: 'åååå',
  },
  home: {
    aligned: 'Bekräftad',
    allAligned: 'Bekräftade',
    allUnAligned: 'Obekräftade',
    announcements: 'Meddelande',
    events: 'Händelser',
    inspected: 'Besiktad',
    inspectionWorks: 'Besiktningar',
    lastLoginDate: 'Du var senast aktiv',
    latestInspections: 'Senaste besiktningar',
    reserveWork: 'Reservera arbete',
    startInspection: 'Starta besiktning',
    unaligned: 'Obekräftade',
    welcome: 'Välkommen',
    works: 'Senaste arbetsordrar',
  },
  user: {
    address: 'Gatuadress',
    changePassword: 'Byt lösenord',
    email: 'E-post',
    firstName: 'Förnamn',
    index: 'Postnummer',
    lastName: 'Efternamn',
    myInfo: 'Egen information',
    newPassword: 'Nytt lösenord',
    newPassword2: 'Bekräfta nytt lösenord',
    phone: 'Telefonnummer',
    state: 'Postort',
    organization: 'Organisationer',
  },
  change_password: {
    change_is_mandatory: 'Det nya lösenordet får inte vara samma som det gamla',
    fields_are_mandatory: 'Alla fält ska ifyllas',
    invalid_password: 'Fel lösenord',
    new_password_again: 'Bekräfta nytt lösenord',
    new_password: 'Ditt nya lösenord',
    not_authenticated: 'Logga in för att byta lösenord',
    old_password: 'Befintligt lösenord',
    password_change_failed: 'Ändring av lösenord misslyckades. Lösenordet måste innehålla minst 8 tecken.',
    password_changed: 'Ändring av lösenord lyckades',
    password_expired: 'Ditt lösenord har gått ut. Sätt nytt lösenord',
    passwords_dont_match: 'Lösenorden stämmer inte',
    submit: 'Skicka',
    title: 'Byt lösenord',
  },
  inspection: {
    activeWorksTitle: 'Aktiva arbeten',
    addNewAdditionalWork: 'Lägg till extra arbete',
    addNewWork: 'Lägg till nytt arbete',
    createInspectionReportForActivityPark: 'Utför besiktning',
    extraWorksTitle: 'Extra arbete',
    finish: 'Spara och slutför besiktning',
    followUpWorkDescription: 'Långsiktig planering',
    followUpWorkDescriptionText: 'Övergripande bedömning av lekplatsen och dess tillstånd....',
    followUpWorkTitle: 'Långsiktig planering',
    maintenanceTitle: 'Rutinmässigt underhåll,',
    maintenanceTitleText: 'Utför rutinmässigt underhåll',
    noMaintenanceTasks: 'Inget rutinunderhåll har slutförts för lekplatsen',
    noWorkSelectedMessage: 'Vänligen välj arbete för att starta besiktning',
    productInstancesTitle: 'Redskap',
    reportingInspectionWork: 'Slutför besiktning',
    summaryDescription: 'Ytterligare information',
    summaryDescriptionText: 'Ytterligare information gällande besiktningen (max. 60 ord)',
    summaryTimeSpentText: 'Rapportera avsatt tid för besiktning, om nödvändigt',
    summaryTimeSpentTitle: 'Avsatt tid för besiktning',
    taskErrorDescription: 'Rutinmässigt underhåll',
    taskWorkDescriptionPrefix: 'Rutinmässigt underhåll slutfört',
    tasks: {
      task1: 'Utjämning av säkerhetsyta',
      task2: 'Borstning av våta häll- eller gummiplattor',
      task3: 'Borstning av sandlådekanter',
      task4: 'Borstning av andra ytor',
      task5: 'Åtdragning av fästelement',
      task6: 'Smörjning av gungställnings lager',
      task7: 'Justering av sitshöjd',
      task8: 'Borttagning av överbliven kedja till',
      task9: 'Rengöring av fallutrymme',
    },
  },
  ratings: {
    conditionRating: 'Generella skicket',
    productRating: 'Lekvärde',
    riskRating: 'Riskklassificering',
  },
  organizations: {
    chooseOne: 'Välj bara en organisation',
    country: 'Land',
    createdAt: 'Skapad',
    language: 'Språk',
    listEmpty: 'Inga organisationar hittad',
    loading: 'Loading organizations..',
    name: 'Namn',
    title: 'Organisationer',
  },
  activityParks: {
    activity_park_areas: 'Ytor',
    activity_park_type: 'Aktivitetspark typ',
    activityPark: 'Objekt',
    activityParkInfo: 'Aktivitetspark info',
    activityParkPlayAreas: 'Aktivitetspark lekplats',
    activityParkType: 'Objekttyp',
    addNewEquipment: 'Lägg till utrustning',
    addNewWork: 'Nytt felmeddelande',
    changeInfoTitle: 'Ändra objektets information',
    commentSaved: 'Feedback skickat!',
    daysAgo: 'dgr sedan',
    deleteButton: 'Radera',
    deleteReason: 'Anledning till radering',
    deleteTitle: 'Radera objekt',
    giveFeedback: 'Ge feedback',
    hide: 'Dölj',
    hideSuccess: 'Objektet döljt!',
    inspectedInLastYear: 'Objektet har besiktats inom ett år',
    inspection: 'Besiktad',
    inspectionReport: 'Besiktningsprotokoll',
    long_term_plans: 'Långsiktig plan',
    orderedWorks: 'Beställda arbeten',
    organizationPerson: 'Kontaktperson',
    play_areas: 'Lekplatser',
    search: 'Sök med namn',
    selectContactPerson: 'Aktivitetspark kontaktperson',
    title: 'Objekt',
    work_priority: 'Arbetsprioritet',
    deleteConfirm: 'Är du säker att du vill radera denna aktivitetspark?',
  },
  playAreas: {
    area: 'Område',
    description: 'Beskrivning',
    number: 'Nummer',
    onlyOne: 'Endast en lekplats',
  },
  productInstances: {
    addNewEquipment: 'Lägg till nytt utrustning',
    addNewEquipmentFor: 'Lägg till nytt utrustning för objektet',
    changeInfoTitle: 'Ändra utrustningens information',
    deleteConfirm: 'Vill du verkligen radera utrustning?',
    deleteTitle: 'Radera',
    equipment: 'Utrustning',
    installationDate: 'Monteringsdag',
    manufacturer: 'Tillverkare',
    playValue: 'Lekvärde',
    product_instance_category: 'Utrustningskategori',
    product_instance_type: 'Utrustningstyp',
    productCode: 'Produktkod',
    productNumber: 'Produktnummer',
    safe_zone_size: 'Islagsyta, m2',
    safety_platform_type: 'Typ av islagsyta',
    searchEquipmentInfoTitle: 'Utrustningens information',
    searchOrganizationInfoTitle: 'Organisationens information',
    standard: 'Standard',
    title: 'Utrustning',
    type: 'Utrustningstyp',
    foundation: {
      deep: 'Markfundament',
      surface: 'Ytfundamnet',
      title: 'Typ av fundament',
      wood: 'Träfundament',
      free_standing: 'Fristående',
    },
    material: {
      metal: 'Metall',
      plastic: 'Plast',
      title: 'Material',
      wood: 'Trä',
      rubber_granulate: 'Gummigranulat',
      hpl_laminate: 'HPL-laminat'
    },
    product_code: {
      lappset: 'LAPPSET',
      product: 'PRODUKT',
    },
  },
  workOrders: {
    cost: 'Kostnad',
    listEmpty: 'Inga arbetsorder hittades',
    loading: 'Laddar arbetsorder..',
    mainDateShort: 'Datum',
    orderer: 'Beställare',
    orderNumber: 'Ordernummer',
    title: 'Beställningar',
  },
  works: {
    activityPark: 'Objekt',
    addNewWork: 'Lägg till felrapport',
    addNewWorkFor: 'Ny felrapport för',
    addPictureInfoText: 'Ladda upp bild på felet',
    chooseExpense: 'Välj kostnadstyp',
    chooseSparePart: 'Välj reservdel från lager',
    cost_type: 'Kostnadstyp',
    cost: 'Pris',
    deleteConfirm: 'Är du säker att du vill radera denna felrapport?',
    deleteTitle: 'Radera felrapport',
    description: 'Beskrivning',
    editOldWork: 'Edit work',
    equipmentText: 'Välj redskap',
    errorDescription: 'Felbeskrivning',
    errorDescriptionText: 'Eller skriv felbeskrivning',
    expense_items: 'Kostnader',
    faultDescriptionText: 'Välj felbeskrivning',
    inspection_description: 'Utförda åtgärder och ytterligare information',
    inspectionDescriptionText: 'Skriv utförda åtgärder och ytterligare information om det behövs. Max. 60 ord.',
    listEmpty: 'Inga arbeten hittades',
    loading: 'Laddar arbeten..',
    mainDateShort: 'Datum',
    organization: 'Organisation',
    priority: 'Klass',
    productInstanceType: 'Utrustningstyp',
    pts: 'LP',
    searchActivityParkInfo: 'Objektets information',
    searchModalText: 'Fyll i ett eller fler sökkriterier',
    searchModalWorkInfo: 'Felrapportens information',
    searchOrganizationInfoTitle: 'Organisationsinfo',
    selectOrganizationPerson: 'Kontaktperson',
    selectWorkType: 'Välj arbetstyp',
    spare_part_items: 'Reservdelar',
    standard: 'Standard',
    standardText: 'Välj standard',
    takeWorkConfirmation: 'Är du säker att du vill reservera arbetet till denna aktivitetspark?',
    title: 'Felrapporter',
    toggleWorkInReport_false: 'Dold från rapport',
    toggleWorkInReport_true: 'Visas på rapport',
    tooMany: 'För många felrapporter valda (över 1000)',
    work_cost_items: 'Kostnader',
    workDescription: 'Närmare beskrivning',
    workDescriptionText: 'Skriv utförda åtgärder',
    workDescriptionTextForExtra: 'Skriv åtgärder krävs',
    workNumber: 'Felnummer',
    workPriority: 'Klass',
    workState: 'Status',
    workType: 'Arbetstyp',
    zoneSelectsTitle: 'Begränsa efter region',
    removeReason: {
      button: 'Radera felrapport',
      title: 'Ange orsaken till radering',
      work_dismantled: 'Utrustningen är demonterat',
      work_fixed: 'Felet är åtgärdat',
      work_removed: 'Felet har försvunnit',
      work_unnecessary: 'Felrapporten är onödig',
      work_updated: 'Felrapporten är uppdaterad',
    },
    cost_types: {
      external: 'Extern',
      fixed_price: 'Fast pris',
      material: 'Material',
      personnel: 'Personal',
    },
  },
  workCostItems: {
    cost_type: 'Kostnadstyp',
    catalog_no: 'Produktnummer',
    description: 'Beskrivning',
    quantity: 'St',
    unit_sales_price: 'Enhetspris',
    total_amount: 'Totalt pris',
  },
  shoppingCart: {
    cost: 'Kostnad',
    empty: 'Kundvagnen är tom',
    extraInfo: 'Ytterligare information',
    offerExtraInfo: 'Ytterligare information om offertförfrågan',
    offerIsSent: 'Er offertförfrågan är skickad',
    offerThankYouMessage: 'Vi kontaktar Er snart!',
    orderExtraInfo: 'Additional order info',
    orderIsSent: 'Er beställning är skickad',
    orderThankYouMessage: 'Tack för Er beställning!',
    sendOffer: 'Skicka offertförfrågan',
    sendOrder: 'Skicka beställning',
    title: 'Varukorg',
  },
  privacy_policy_modal: {
    title: 'Acceptera sekretess- och cookiepolicyn',
    button: 'Acceptera',
    privacy_policy_text: 'Läs integritetspolicy härifrån:',
    privacy_policy_link: 'Privacy policy',
    cookie_policy_text: 'Läs cookie policy härifrån:',
    cookie_policy_link: 'Cookie policy',
  },
  contact: {
    address: 'Litslena Sneby 2, 745 96 Enköping',
    email: 'sverige@lappset.com',
    phone: '+46 (0)171 270 80',
    title: 'Kontaktuppgifter',
    menu1: {
      title: 'Tjänster',
      link1: {
        name: 'Montering',
        url: 'https://www.playcare.se/Tjanster/Montering',
      },
      link2: {
        name: 'Besiktning',
        url: 'https://www.playcare.se/Tjanster/Besiktning',
      },
      link3: {
        name: 'Underhåll och reservdelar',
        url: 'https://www.playcare.se/Tjanster/Underhall-och-reservdelar',
      },
      link4: {
        name: 'Nytt liv',
        url: ' https://www.playcare.se/Tjanster/Nytt-Liv',
      },
      link5: {
        name: 'Alustojen puhdistus',
        url: 'https://www.playcare.fi/palvelut/Turva-alustan-puhdistus-ja-huolto',
      },
    },
    menu2: {
      title: 'Andra tjänster',
      link1: {
        name: 'Varaosahaku',
        url: 'https://www.playcare.fi/Varaosahaku',
      },
      link2: {
        name: 'Nedladdningar',
        url: 'https://www.playcare.fi/UI/toolsmenu/Ladattavat-tiedostot',
      },
    },
  },
}
