import workItemQueries from '@/components/Works/WorkItems/workItemQueries'

const shared = [
  'main_object_state summary,initial_state,final_state,text_color,background_color',
  'main_object_state name_fi,name_se,name_en',
  'work_number',
  'work_description',
  'inspection_description',
  'error_description',
  'fault_description summary',
  'work_type summary',
  'product_instance_type summary',
  'standard summary',
  'priority',
  'organization summary',
  'activity_park name,summary',
  'cost',
  'ifs2001',
  'ifs_identifier',
  'assigned_time',
  'shopping_cart_person id',
  'product_instance summary,product_instance_type',
  'product_instance product_instance_type summary',
  'images filename,content_type,attachable_association,order',
  'shown_on_report',
  'shown_on_report',
  'work_type summary @association_style',
  'organization @association_style',
  'activity_park @association_style',
  'cost_currency identifier',
];

['work_cost_items', 'expense_items', 'spare_part_items'].forEach(workItemField => {
  workItemQueries.get(workItemField).forEach(query => {
    shared.push(workItemField + ' ' + query)
  })
})

export default {
  forList: [...shared],
  forEdit: [...shared],
  forShow: [...shared],
}
