<template>
  <v-app>
    <AppMessages />
    <template v-if="userInfo && userInfo.id && supportedDataLoaded">
      <CookieDialog />
      <AppBar />
      <Toolbar />
      <v-main class="main-container">
        <router-view />
      </v-main>
      <Footer />
    </template>
    <DeveloperLogin v-else-if="isDeveloper && !authenticated" />
    <div
      v-else
      class="full-height-content-center"
    >
      {{ $i18n.t('general.loading') }}
    </div>
    <UserInfoForm v-if="showUserInfoEditForm" />
    <!-- Activity Park related Dialogs are in App because these components
    are used in multiple other components with different triggers and callbacks -->

    <ActivityParkCommentForm
      v-model="activityParkFeedbackModalProps.dialog"
      v-if="activityParkFeedbackModalProps.dialog"
    />
    <ActivityParkEdit
      v-model="activityParkEditModalProps.dialog"
      v-if="activityParkEditModalProps.dialog"
    />
    <WorkEdit
      v-model="workEditModalProps.dialog"
      v-if="workEditModalProps.dialog"
    />
    <ProductInstanceEdit
      v-if="productInstanceEditModalProps.dialog"
      v-model="productInstanceEditModalProps.dialog"
    />
    <ItemDeleteModalWithReasons
      v-if="workDeleteConfirmForWorkId"
      model="works"
    />
    <ChangePassword v-if="showUserPasswordForm" />
  </v-app>
</template>

<script>
import AppBar from '@/components/App/AppBar'
import Toolbar from '@/components/App/Toolbar'
import Footer from '@/components/App/Footer'
import state from '@/store/state'
import DeveloperLogin from '@/components/App/DeveloperLogin'
import UserInfoForm from '@/components/App/UserInfoForm'
import WorkEdit from '@/components/Works/WorkEdit'
import ActivityParkEdit from '@/components/ActivityParks/ActivityParkEdit'
import ActivityParkCommentForm from '@/components/ActivityParks/ActivityParkCommentForm'
import ProductInstanceEdit from '@/components/ProductInstances/ProductInstanceEdit'
import ItemDeleteModalWithReasons from '@/components/Shared/ItemDeleteModalWithReasons'
import { createHelpers } from 'vuex-map-fields'
import AppMessages from '@/components/App/AppMessages'
import ChangePassword from '@/components/App/ChangePassword'
import CookieDialog from '@/components/App/CookieDialog'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const supportedLanguages = ['en', 'fi', 'se']

const setupListener = (store) => {
  window.addEventListener('popstate', (event) => {
    if (window.location.href.includes('#/parks')) {
      store.dispatch('clearActivityParkFilters')
    }
  })
}

export default {
  name: 'App',

  components: {
    CookieDialog,
    ChangePassword,
    AppMessages,
    ItemDeleteModalWithReasons,
    ProductInstanceEdit,
    ActivityParkCommentForm,
    ActivityParkEdit,
    WorkEdit,
    UserInfoForm,
    DeveloperLogin,
    Footer,
    Toolbar,
    AppBar,
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  created() {
    this.initializeUserSessionOrGoToLoginApp()
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener(
      'serviceWorkerUpdated',
      this.showAppNewVersionMessage,
      { once: true }
    )
    setupListener(this.$store)
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    showAppNewVersionMessage() {
      this.$store.dispatch('showMessage', {
        message: 'New version is available. Reloading portal now ...',
        type: 'success',
      })
      setTimeout(() => {
        location.reload()
      }, 2000)
    },

    initializeUserSessionOrGoToLoginApp() {
      this.$store
        .dispatch('trySessionRestoreAndSetUserInfo')
        .then((response) => {
          if (!response || !response.status || response.status === 'fatal') {
            // Unexpected error, don't cause login app loop
            this.$store.dispatch('addAppMessage', {
              message: 'Unexpected login service error',
              type: 'error',
            })
          } else if (response && !response.item) {
            // No user found, go to login app
            this.goToLoginApp()
          } else {
            this.setLanguageAndLoadSupportingData(response)
          }
        })
    },

    setLanguageAndLoadSupportingData(response) {
      const language = response.item.language
      if (language && language.identifier) {
        this.locale = supportedLanguages.includes(language.identifier)
          ? language.identifier
          : 'en'
        this.$i18n.locale = supportedLanguages.includes(language.identifier)
          ? language.identifier
          : 'en'
      }
      this.$store.dispatch('loadSupportingData')
    },

    goToLoginApp() {
      if (!this.isDeveloper) {
        this.$store.dispatch('login')
      }
    },

    resizeHandler() {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        this.dialogHeight = window.innerHeight - 360
        this.innerHeight = window.innerHeight
        this.innerWidth = window.innerWidth
      }, 300)
      if (!this.$vuetify.breakpoint.xsOnly) {
        this.showMenu = true
      }
    },
  },
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
.green {
  color: #8cc477 !important;
}
.green-bg {
  background: #8cc477 !important;
}
.body-container {
  background: #f2f2f2;
}
.main-container {
  background: white;
  color: #666;
  padding-top: 132px !important;
}
.bold {
  font-weight: 600;
}
.h-spacer-0 {
  border-bottom: 3px solid #eee;
}
.h-spacer {
  height: 1px;
  border-bottom: 2px solid #eee;
  padding: 0;
  padding-top: 10px !important;
  margin-bottom: 10px;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.full-height-content-center {
  clear: both;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.add-new-button {
  cursor: pointer;
  i {
    font-size: 74px !important;
    color: #ddd !important;
  }
  .add-title {
    font-size: 26px;
    font-weight: 600;
  }
}
.items-list {
  th {
    white-space: nowrap;
  }
  .v-data-table-header {
    background: #8cc477;
    th {
      font-size: 16px !important;
      font-weight: 600;
      color: white !important;
      i {
        color: white !important;
        margin-left: 5px;
      }
    }
  }
  .fa-chevron-down {
    font-size: 16px !important;
  }
  .v-data-table__expanded__row {
  }
  .odd-row,
  .odd-row-td {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .v-data-table__expanded__content {
    box-shadow: none !important;
    .cart-remove {
      .v-btn__content {
        i {
          color: white !important;
        }
      }
    }
    button {
      margin-left: 15px;
      .v-icon {
        color: #666;
      }
      .in-report {
        color: #8cc477 !important;
      }
      .not-in-report {
        color: #d9534f !important;
      }
      .delete-button {
        color: #d9534f !important;
      }
      min-width: auto !important;
      padding: 0 !important;
      width: 40px !important;
      height: 40px !important;
    }
  }
}
.items-list-modal {
  .v-data-table-header {
    background: transparent;
    th {
      color: #777 !important;
      i {
        color: #777 !important;
      }
    }
  }
}

.priority-box {
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  line-height: 20px;
  width: 32px;
  font-weight: 700;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  .priority-box-count {
    position: absolute;
    bottom: -7px;
    right: -10px;
    background: #eee;
    font-size: 14px;
    color: #666;
    line-height: 13px;
    border-radius: 25px;
    padding: 3px 3px;
    width: 21px;
    font-weight: 600;
  }
}
.link {
  cursor: pointer;
}
.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rounded-8 {
  border-radius: 8px;
}
.rounded-12 {
  border-radius: 12px;
}
</style>

<style lang="scss">
.search-form {
  overflow: auto;
  background: white;
  padding: 15px 20px !important;
}
.badge {
  text-align: center;
  color: white;
  padding: 4px;
  border-radius: 50px;
  font-weight: 600;
  margin-left: 10px;
  font-size: 14px;
  min-height: 26px;
  min-width: 26px;
}
.images {
  position: relative;
  .badge {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.text-subtitle {
  font-weight: 500;
  text-decoration: underline;
}
</style>
