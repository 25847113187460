<template>
  <v-toolbar
    height="90"
    color="#f2f2f2"
    class="elevation-0 pagination-top-toolbar"
  >
    <v-container
      style="max-width: 1600px"
      fluid
    >
      <v-row>
        <v-col cols="3"> &nbsp; </v-col>
        <v-col
          cols="6"
          class="text-center"
        >
          <v-btn
            icon
            small
            fab
            class="mr-3"
            :disabled="loading || page === 1"
            @click.stop="previous"
          >
            <v-icon small> fa-chevron-left </v-icon>
          </v-btn>
          <span class="pagination-text no-select">
            {{ $i18n.t('general.page').toUpperCase() }} {{ page }} /
            {{ totalPages }}
          </span>
          <v-btn
            icon
            small
            fab
            class="ml-3"
            :disabled="loading || page >= totalPages"
            @click.stop="next"
          >
            <v-icon small> fa-chevron-right </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          class="text-right"
        >
          <v-btn
            v-if="['works', 'workOrders'].includes(model)"
            class="elevation-0"
            text
            @click="expandAll()"
          >
            {{ $i18n.t('general.expandAll') }}
            <v-icon class="ml-3"> fa-chevron-down </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
export default {
  name: 'ListTopPagination',

  props: {
    value: {
      type: Number,
      default: 1,
    },
    model: {
      type: String,
      default: '',
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    expandAll: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    page: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    loading() {
      return this.$store.state[this.model + 'Loading']
    },
  },

  methods: {
    next() {
      if (this.page >= this.totalPages) {
        return
      }
      this.page++
    },

    previous() {
      if (this.page === 1) {
        return
      }
      this.page--
    },
  },
}
</script>

<style>
.pagination-text {
  color: #666;
  font-size: 16px;
  font-weight: 600;
}
</style>
