<template>
  <v-container class="elevation-3 white px-5">
    <v-row>
      <v-col
        cols="12"
        class="h1 px-5 pt-5 pb-3"
      >
        <v-icon
          class="pr-3"
          style="margin-top: -4px"
          >fa-tasks</v-icon
        >
        {{ $i18n.t('home.inspectionWorks').toUpperCase() }}
      </v-col>
      <v-col cols="12">
        <div class="h-spacer-0" />
      </v-col>
      <v-col :cols="userInfo && userInfo.allow_show_on_map ? 4 : 6">
        <v-btn
          block
          class=""
          :color="view === 'aligned' ? '#8cc477' : 'white'"
          :dark="view === 'aligned'"
          @click="view = 'aligned'"
        >
          {{ $i18n.t('home.allAligned').toUpperCase() }}
          <v-btn
            v-if="loading"
            text
            x-small
            loading
            class="ml-5"
          />
          <template v-else>({{ alignedWorks.length }})</template>
        </v-btn>
      </v-col>
      <v-col :cols="userInfo && userInfo.allow_show_on_map ? 4 : 6">
        <v-btn
          block
          class=""
          :color="view === 'unaligned' ? '#8cc477' : 'white'"
          :dark="view === 'unaligned'"
          @click="view = 'unaligned'"
        >
          {{ $i18n.t('home.allUnAligned').toUpperCase() }}
          <v-btn
            v-if="loading"
            text
            x-small
            loading
            class="ml-5"
          />
          <template v-else>({{ unAlignedWorks.length }})</template>
        </v-btn>
      </v-col>
      <v-col
        v-if="userInfo && userInfo.allow_show_on_map"
        cols="4"
        class="text-right"
      >
        <v-btn
          tile
          :disabled="loading"
          @click="showOnMap()"
        >
          <v-icon> fa-map-marked-alt </v-icon>
        </v-btn>
      </v-col>
      <v-col :class="$vuetify.breakpoint.lgAndUp ? 'home-work-orders' : ''">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col
              v-for="(work, index) in selectedWorks"
              :key="index"
              cols="12"
            >
              <v-container class="pa-0">
                <v-row
                  style="background: #eee"
                  dense
                  class="px-3 rounded-borders"
                >
                  <v-col cols="5"> # {{ work.work_number }} </v-col>
                  <v-col
                    cols="7"
                    class="text-right"
                  >
                    {{
                      $i18n.t(
                        `home.${view === 'aligned' ? 'aligned' : 'unaligned'}`
                      )
                    }}
                    <b>{{
                      formatDate(
                        view === 'aligned' ? work.assigned_time : work.main_date
                      )
                    }}</b>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col cols="8">
                    <span class="h2">
                      {{ work.activity_park && work.activity_park.name }} </span
                    ><br />
                    {{
                      work.activity_park && work.activity_park.name_extension
                    }}
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-right"
                  >
                    <v-btn
                      v-if="view !== 'unaligned'"
                      color="#8cc477"
                      dark
                      small
                      @click="startInspection(work)"
                    >
                      {{ $i18n.t('home.startInspection').toUpperCase() }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="#fff"
                      small
                      :disabled="
                        !userInfo.person.ifs_person_identifier || loading
                      "
                      @click="takeWork(work)"
                    >
                      {{ $i18n.t('home.reserveWork').toUpperCase() }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="
                      work.work_type && work.work_type[`name_${$i18n.locale}`]
                    "
                    cols="12"
                    class="py-0"
                  >
                    {{ work.work_type[`name_${$i18n.locale}`] }}
                  </v-col>
                  <v-col cols="12">{{ work.work_description || '-' }}</v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import worksQueries from '@/components/Works/worksQueries'
import helper from '@/helper'
import api from '@/store/api'
import moment from 'moment'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'HomeInspectionWorks',

  data() {
    return {
      loading: false,
      view: 'aligned',
      inspectionWorks: [],
    }
  },

  created() {
    this.loadAllWorks()
  },

  computed: {
    ...mapFields(Object.keys(state)),

    organizationFilterIds() {
      return this.$store.state.globalFilters.organization
        .map((i) => i.id)
        .join(' ')
    },

    selectedWorks() {
      return this.view === 'aligned' ? this.alignedWorks : this.unAlignedWorks
    },

    alignedWorks() {
      return this.inspectionWorks.filter(
        (work) =>
          !!work.reporter_ifs_identifier &&
          work.reporter_ifs_identifier.length > 0 &&
          work.reporter_ifs_identifier ===
            this.userInfo.person.ifs_person_identifier
      )
    },

    unAlignedWorks() {
      return this.inspectionWorks.filter(
        (work) =>
          !work.reporter_ifs_identifier ||
          work.reporter_ifs_identifier.length === 0 ||
          work.reporter_ifs_identifier !==
            this.userInfo.person.ifs_person_identifier
      )
    },
  },

  watch: {
    organizationFilterIds() {
      this.loadAllWorks()
    },
  },

  methods: {
    showOnMap() {
      this.activityParksDefaultFilters = JSON.parse(
        JSON.stringify(this.activityParksListDefaultOptions)
      )
      this.$set(
        this.activityParksFilters,
        'ids',
        this.selectedWorks
          .filter((work) => work.activity_park)
          .map((work) => work.activity_park.id)
      )
      this.$set(this.activityParksFilters, 'limit', 100)
      this.mapView = true
      this.$router.push({ path: '/parks' })
    },

    startInspection(work) {
      this.inspection = JSON.parse(JSON.stringify(this.inspectionDefaultProps))
      this.$set(this.inspection, 'activityPark', work.activity_park)
      this.$set(this.inspection, 'work', work)
      this.$router.push({
        path: '/inspection',
      })
      window.scrollTo(0, 0)
    },

    takeWork(work) {
      const activityParkName =
        (work.activity_park && work.activity_park.name) || '-'
      if (
        window.confirm(
          this.$i18n.t('works.takeWorkConfirmation') +
            ' ' +
            activityParkName +
            '?'
        )
      ) {
        this.loading = true
        const item = {
          '@class': 'Work',
          id: work.id,
          reporter_ifs_identifier: this.userInfo.person.ifs_person_identifier,
          assigned_time: moment().format('YYYY-MM-DD HH:mm'),
        }
        api.saveItem('works', item).then((response) => {
          this.loading = false
          this.$store.dispatch('globalErrorDisplay', response)
          if (response.data.item && response.data.item.id) {
            this.loadAllWorks()
          }
        })
      }
    },

    formatDate(date) {
      return helper.formatDate(date)
    },

    loadAllWorks() {
      this.loading = true
      const filters = {
        _reporter_ifs_identifier:
          this.userInfo.person.ifs_person_identifier || '',
        _main_object_state_id: this.workStateIds.assigned,
        order: 'created_at desc',
        offset: 0,
        limit: 1000,
      }
      api.getWorksForHome(filters, worksQueries.forList).then((response) => {
        this.loading = false
        if (response && response.items) {
          this.inspectionWorks = response.items
        }
      })
    },
  },
}
</script>

<style>
.home-work-orders {
  min-height: 1058px;
  max-height: 1058px;
  overflow: auto;
}
</style>
