import worksState from '@/store/state/worksState'
import workOrdersState from '@/store/state/workOrdersState'
import organizationsState from '@/store/state/organizationsState'
import productInstancesState from '@/store/state/productInstancesState'
import activityParksState from '@/store/state/activityParksState'

const inspectionDefaultProps = {
  work: {},
  activityPark: null,
  description: '',
  hours: null,
  minutes: null,
  noTasksDone: false,
  announcements: [],
  followUpWork: {
    priority: null,
    description: '',
  },
  tasks: {
    task1: false,
    task2: false,
    task3: false,
    task4: false,
    task5: false,
    task6: false,
    task7: false,
    task8: false,
    task9: false,
  },
}

const ratingsListDefaultOptions = {
  page: 1,
  itemsPerPage: 30,
  sortBy: ['name'],
  sortDesc: [true],
}

export default {
  ...activityParksState,
  ...productInstancesState,
  ...organizationsState,
  ...worksState,
  ...workOrdersState,

  ratingsItems: [],
  ratingsLoading: false,
  ratingsListDefaultFilters: {},
  ratingsListFilters: {},
  ratingsListDefaultOptions,
  ratingsItemsTotal: 0,
  ratingsAllIds: [],
  ratingsListOptions: JSON.parse(JSON.stringify(ratingsListDefaultOptions)),
  shoppingCartItems: [],
  locale: 'en',
  userInfo: {
    person: {},
  },
  greenColor: '#8cc477',
  amByModel: {}, // Attribute Metadata by Model
  supportedDataLoaded: false,
  authenticated: false,
  isDeveloper: window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.'),
  settings: [],
  systemConfig: {},
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  dialogHeight: window.innerHeight - 360,
  appMessages: [],
  globalFilters: {
    organization: [],
    activity_park: [],
    searchTerm: '',
  },
  showUserInfoEditForm: false,
  showUserPasswordForm: false,

  textFieldFormatAttributes: {
    outlined: true,
    color: '#666',
    rounded: true,
    hideDetails: true,
    dense: false,
    clearable: true,
  },
  textFieldFormatAttributesForInputWithDetails: {
    outlined: true,
    color: '#666',
    rounded: true,
    dense: false,
    clearable: true,
  },
  popupButtonFormatAttributes: {
    height: 50,
    rounded: true,
    block: true,
  },
  inspectionDefaultProps: JSON.parse(JSON.stringify(inspectionDefaultProps)),
  inspection: JSON.parse(JSON.stringify(inspectionDefaultProps)),
  productInstanceTypes: [],
}
