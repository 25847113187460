import api from '@/store/api'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'

export default {
  getActivityParksListItems () {
    return new Promise(resolve => {
      this.activityParksLoading = true
      window.scrollTo(0, 0)
      this.$store.dispatch('getListItems', {
        model: 'activityParks',
        filters: this.getActivityParksListFilters(),
        queries: activityParksQueries.forList,
      }).then(response => {
        resolve(response)
        this.$nextTick(() => {
          this.activityParksLoading = false
        })
      })
    })
  },

  getActivityParksListFilters () {
    const filters = this.getListGeneralFilters('activityParks')
    const activityParksFilters = this.activityParksFilters;

    ['activity_park_type', 'work_type'].forEach(filterName => {
      if (activityParksFilters[filterName] && activityParksFilters[filterName].length > 0) {
        filters['_' + filterName + '_id'] = '(' + activityParksFilters[filterName].map(o => o.id).join(' ') + ')'
      }
    });

    ['work_priority'].forEach(filterName => {
      if (activityParksFilters[filterName] && activityParksFilters[filterName].length > 0) {
        filters['_' + filterName] = '(' + activityParksFilters[filterName].join(' ') + ')'
      }
    })
    filters._in_operation = 'true'
    if (activityParksFilters.ids) {
      filters._unique_ids = activityParksFilters.ids.join(',')
    }
    if (activityParksFilters.inspectedInLastYear) {
      filters._inspected_in_last_year = true
    }
    if (activityParksFilters.responsiblePeople && activityParksFilters.responsiblePeople.length > 0) {
      filters._responsible_people = activityParksFilters.responsiblePeople.join(',')
    }
    if (activityParksFilters.limit) {
      filters.limit = activityParksFilters.limit
    }
    return filters
  },

  showActivityParkItemForEdit ({
    id,
    callback = () => {},
    imagesOnly = false,
  }) {
    if (!id) { return }
    this.$nextTick(() => {
      // Reset props
      this.activityParkEditModalProps = JSON.parse(JSON.stringify(this.activityParkEditModalDefaultProps))
      // On next tick open with new props
      this.$nextTick(() => {
        this.activityParkEditModalProps = {
          dialog: true,
          id,
          callback,
          imagesOnly,
        }
      })
    })
  },

  showActivityParkFeedbackModal ({ id, callback = () => {} }) {
    if (!id) { return }
    this.$nextTick(() => {
      // Reset props
      this.activityParkFeedbackModalProps = JSON.parse(JSON.stringify(this.activityParkFeedbackModalDefaultProps))
      // On next tick open with new props
      this.$nextTick(() => {
        this.activityParkFeedbackModalProps = {
          dialog: true,
          id,
          callback,
        }
      })
    })
  },

  deleteActivityPark (id) {
    if (window.confirm(this.$i18n.t('activityParks.deleteConfirm'))) {
      this.activityParksLoading = true
      this.$store.dispatch('setItemAsRemoved', {
        model: 'activity_parks',
        itemId: id,
        resource: 'activity_parks',
      }).then(response => {
        this.$store.dispatch('globalErrorDisplay', response)
        if (response && response.data && response.data.status === 'ok') {
          this.getListItems()
        }
      })
    }
  },

  sendActivityParkComment (id, comment) {
    return new Promise(resolve => {
      api.saveActivityParkComment(id, comment).then(response => {
        this.$store.dispatch('globalErrorDisplay', response)
        resolve(response && response.status === 200)
        if (response && response.data && response.data.status === 'ok') {
          this.$store.dispatch('addAppMessage', {
            type: 'success',
            message: this.$i18n.t('activityParks.commentSaved'),
          })
        }
      })
    })
  },

  hideActivityPark ({ id, callback = () => {} }) {
    return new Promise(resolve => {
      api.hideActivityPark(id).then(response => {
        this.$store.dispatch('globalErrorDisplay', response)
        resolve(response && response.status === 201)
        if (response && response.data && response.data.status === 'ok') {
          this.$store.dispatch('addAppMessage', {
            type: 'success',
            message: this.$i18n.t('activityParks.hideSuccess'),
          })
        }
        callback()
      })
    })
  },
}
