<template>
  <v-container fluid class="items-list">
    <ListTopPagination
      v-model="ratingsListOptions.page"
      :total-pages="pages"
      model="ratings"
    />
    <v-data-table
      :headers="headers"
      :items="ratingsItems"
      :expanded.sync="expanded"
      :hide-default-footer="true"
      :item-class="itemStyle"
      :options.sync="ratingsListOptions"
      :loading="ratingsLoading"
      :loading-text="$i18n.t('ratings.loading')"
      :server-items-length="ratingsItems.length"
      item-key="id"
      class="elevation-1"
      @click:row="rowClick"
    >
    </v-data-table>
    <ListBottomPagination
      v-model="ratingsListOptions.page"
      :total-pages="pages"
    />
  </v-container>
</template>

<script>
import helper from '@/helper'
import ListTopPagination from '@/components/Shared/ListTopPagination'
import ListBottomPagination from '@/components/Shared/ListBottomPagination'
import allMethods from '@/components/allMethods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'RatingsLIst',

  components: {
    ListBottomPagination,
    ListTopPagination,
  },

  data () {
    return {
      page: 6,
      expanded: [],
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    headers () {
      const headers = [
        { text: this.$i18n.t('activityParks.activityPark'), value: 'name' },
        { text: this.$i18n.t('ratings.productRating'), value: 'product_rating' },
        { text: this.$i18n.t('ratings.riskRating'), value: 'risk_rating' },
        { text: this.$i18n.t('ratings.conditionRating'), value: 'condition_rating' },
      ]
      return headers
    },

    pages () {
      return Math.ceil(this.ratingsItemsTotal / this.ratingsListOptions.itemsPerPage)
    },
  },

  methods: {
    ...allMethods,

    rowClick (work, extra) {
      // Toggle expanded
      extra.expand(!extra.isExpanded)
    },

    closeAllExpanded () {
      this.expanded = []
    },

    itemStyle (item) {
      if (item.index % 2) { return 'odd-row' }
    },

    formatDateTime (date) {
      return helper.formatDateTime(date)
    },
  },
}
</script>

<style>
.v-data-table__expanded {
  cursor: pointer;
}
</style>
