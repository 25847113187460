import helper from '@/helper'
import productInstancesMethods from '@/components/ProductInstances/productInstancesMethods'
import worksMethods from '@/components/Works/worksMethods'
import workOrdersMethods from '@/components/WorkOrders/workOrdersMethods'
import activityParksMethods from '@/components/ActivityParks/activityParksMethods'
import organizationsMethods from '@/components/Organizations/organizationsMethods'
import ratingsMethods from '@/components/Ratings/ratingsMethods'

const Color = require('color')

export default {
  ...productInstancesMethods,
  ...worksMethods,
  ...workOrdersMethods,
  ...activityParksMethods,
  ...organizationsMethods,
  ...ratingsMethods,

  priorityStyle (priority) {
    if (!priority || priority === '?') { return { background: 'grey' } }
    return {
      background: this.$store.state.workPriorityColors[priority],
    }
  },

  formatPrice (price) {
    return helper.formatPrice(price, 2)
  },

  objectStateStyle (state) {
    if (!state) { return {} }
    return {
      display: 'inline-block',
      textAlign: 'center',
      background: state.background_color || 'grey',
      borderRadius: '15px',
      padding: '3px 10px',
      fontWeight: 600,
      color: state.text_color || Color(state.background_color).luminosity() < 0.6 ? 'white' : 'black',
    }
  },

  getListItems (model = this.model) {
    switch (model) {
      case 'productInstances': {
        return this.getProductInstancesListItems()
      }
      case 'works': {
        return this.getWorksListItems()
      }
      case 'workOrders': {
        return this.getWorkOrdersListItems()
      }
      case 'organizations': {
        return this.getOrganizationsListItems()
      }
      case 'activityParks': {
        return this.getActivityParksListItems()
      }
      case 'ratings': {
        return this.getRatingsListItems()
      }
    }
  },

  getListGeneralFilters (model) {
    const filters = {}
    const options = this[model + 'ListOptions']
    filters.limit = options.itemsPerPage
    filters.offset = options.page * options.itemsPerPage - options.itemsPerPage
    if (options.sortBy[0]) {
      filters.order = options.sortBy[0].split('.')[0] + ' ' + (options.sortDesc[0] ? 'desc' : 'asc')
    }
    // Apply global filters
    if (this.globalFilters.activity_park) {
      filters._activity_park_id = '(' + this.globalFilters.activity_park.map(o => o.id).join(' ') + ')'
    }
    if (this.globalFilters.organization) {
      filters._organization_id = '(' + this.globalFilters.organization.map(o => o.id).join(' ') + ')'
    }
    if (model === 'works' && this.globalFilters.searchTerm) {
      filters._free_text = this.globalFilters.searchTerm
    }
    return filters
  },

  showSearchForm () {
    this['show' + helper.objectClassPluralName(this.model) + 'SearchForm'] = true
  },

  showAddNewForm () {
    switch (this.model) {
      case 'works': {
        return this.showWorkEditModal('new', {
          callback: this.getListItems,
        })
      }
      case 'productInstances': {
        return this.showProductInstanceEditModal('new', {
          callback: this.getListItems,
        })
      }
    }
  },

  goToFirstPage () {
    this.$set(this[this.model + 'ListOptions'], 'page', 1)
  },

  clearSearch () {
    const model = this.model === 'ratings' ? 'activityParks' : this.model
    this[model + 'Filters'] = JSON.parse(JSON.stringify(this[model + 'DefaultFilters']))
    this.globalFilters = {
      organization: [],
      activity_park: [],
    }
  },

  showImageDownloadMenu (e, imageLink) {
    this.imageLinkForDownload = imageLink
    e.preventDefault()
    this.showMenu = false
    this.x = e.clientX
    this.y = e.clientY
    this.$nextTick(() => {
      this.showMenu = true
    })
  },
}
