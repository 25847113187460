import api from '@/store/api'
import worksQueries from '@/components/Works/worksQueries'

export default {
  getWorksListItems() {
    this.worksLoading = true
    window.scrollTo(0, 0)
    this.$store
      .dispatch('getListItems', {
        model: 'works',
        filters: this.getWorksListFilters(),
        queries: worksQueries.forList,
      })
      .then(() => {
        this.worksLoading = false
      })
  },

  getWorksListFilters() {
    const filters = this.getListGeneralFilters('works')
    const worksFilters = this.worksFilters

    ;['activity_park', 'work_type'].forEach((filterName) => {
      if (worksFilters[filterName] && worksFilters[filterName].length > 0) {
        filters['_' + filterName + '_id'] =
          '(' + worksFilters[filterName].map((o) => o.id).join(' ') + ')'
      }
    })
    ;['priority', 'main_object_state_id'].forEach((filterName) => {
      if (worksFilters[filterName] && worksFilters[filterName].length > 0) {
        filters['_' + filterName] =
          '(' + worksFilters[filterName].join(' ') + ')'
      }
    })
    ;['pts'].forEach((filterName) => {
      if (this.worksFilters[filterName]) {
        filters[`_${filterName}`] = this.worksFilters[filterName]
      }
    })

    if (
      worksFilters.responsiblePeople &&
      worksFilters.responsiblePeople.length > 0
    ) {
      filters._responsible_people = worksFilters.responsiblePeople.join(',')
    }
    return filters
  },

  showWorkEditModal(
    id,
    { activityPark = null, callback = () => {}, template = '' }
  ) {
    // Reset props
    this.workEditModalProps = JSON.parse(
      JSON.stringify(this.workEditModalDefaultProps)
    )
    // On next tick open with new props
    this.$nextTick(() => {
      this.workEditModalProps = {
        dialog: true,
        id,
        activityPark,
        callback,
        template: template || this.workEditModalProps.template,
      }
    })
  },

  deleteWorkFromCart(work) {
    return new Promise((resolve) => {
      this.$store
        .dispatch('sendProcessEventAction', {
          itemId: work.id,
          objectClass: 'works',
          action: 'cancel_add_to_cart',
          queries: worksQueries.forList,
        })
        .then((updatedWork) => {
          // Clear from local cart
          const index = this.workIndexInShoppingCart(work)
          if (index !== -1) {
            this.$delete(this.shoppingCartItems, index)
          }
          // Update in list with new state
          this.updateWorkInListById(updatedWork)
          resolve(updatedWork)
        })
    })
  },

  setWorkAsOrdered(work) {
    return this.$store.dispatch('sendProcessEventAction', {
      itemId: work.id,
      objectClass: 'works',
      action: 'order',
      queries: [],
    })
  },

  workIndexInShoppingCart(work) {
    return this.shoppingCartItems.map((item) => item.id).indexOf(work.id)
  },

  addWorkToCart(work) {
    return new Promise((resolve) => {
      const workPayload = {
        '@class': 'Work',
        id: work.id,
        shopping_cart_person: {
          '@class': 'Person',
          id: this.userInfo.person.id,
        },
      }
      this.$store
        .dispatch('saveItem', {
          item: workPayload,
          model: 'works',
          queries: worksQueries.forList,
          eventAction: 'add_to_cart',
        })
        .then((updatedWork) => {
          // Add to local cart
          let index = this.workIndexInShoppingCart(work)
          if (index === -1) {
            // Add new item to shopping cart
            index = this.shoppingCartItems.length
            this.$set(
              this.shoppingCartItems,
              index,
              JSON.parse(JSON.stringify(updatedWork))
            )
            this.$set(this.shoppingCartItems[index], 'quantity', 1)
          }
          // Update in list with new state
          this.updateWorkInListById(updatedWork)
          this.$set(
            work,
            'shopping_cart_person',
            updatedWork.shopping_cart_person
          )
          resolve(updatedWork)
        })
    })
  },

  updateWorkInListById(work) {
    if (!work || !work.id) {
      return
    }
    const index = this.worksItems.map((item) => item.id).indexOf(work.id)
    if (index === -1) {
      return
    }
    this.$set(this.worksItems, index, JSON.parse(JSON.stringify(work)))
  },

  toggleWorkInReport(work) {
    return new Promise((resolve) => {
      api.toggleWorkInReport(work.id).then((response) => {
        this.$store.dispatch('globalErrorDisplay', response)
        resolve(response && response.status === 201)
        if (response && response.data && response.data.status === 'ok') {
          work.shown_on_report = response.data.shown_on_report
          this.$store.dispatch('addAppMessage', {
            type: 'success',
            message: this.$i18n.t(
              `works.toggleWorkInReport_${work.shown_on_report}`
            ),
          })
        }
      })
    })
  },

  deleteWork(work) {
    return new Promise((resolve) => {
      if (window.confirm(this.$i18n.t('works.deleteConfirm'))) {
        api.deleteItem('works', work.id).then((response) => {
          this.$store.dispatch('globalErrorDisplay', response)
          if (response.status === 'ok') {
            // Reload list
            this.getWorksListItems()
            resolve(true)
          }
          resolve(false)
        })
      } else {
        resolve(false)
      }
    })
  },
}
