v <template>
  <v-container fluid class="product-instances list-item elevation-3 fill-height py-0">
    <v-row
      class="ei-header"
    >
      <v-col
        cols="10" class="h1 link no-wrap py-0 px-0"
        @click="showProductInstanceItemForShow(item.id)"
      >
        {{ item.activity_park && item.activity_park.name }}
      </v-col>
      <v-col
        v-if="!readOnly && userInfo && (userInfo.allow_edit_product_instances || userInfo.allow_remove_product_instance)"
        cols="2" class="ei-menu-btn pb-0"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>fa-bars</v-icon>
            </v-btn>
          </template>
          <v-list class="ei-menu">
            <v-list-item>
              <v-list-item-title>
               <b>
                 {{ $i18n.t('general.actions') }}
               </b>
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>fa-times</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="userInfo && userInfo.allow_edit_product_instances"
              @click="edit"
            >
              <v-list-item-avatar>
                <v-icon small>fa-edit</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $i18n.t('productInstances.changeInfoTitle') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="userInfo && userInfo.allow_remove_product_instance"
              @click="deleteItem(item.id)"
            >
              <v-list-item-avatar>
                <v-icon small>fa-trash-alt</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $i18n.t('productInstances.deleteTitle') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="10" class="ei-address no-wrap px-0 py-0">
        {{ item.activity_park && item.activity_park.name_extension }}
      </v-col>
    </v-row>
    <v-row
      @click="showProductInstanceItemForShow(item.id)"
      class="link"
    >
      <v-col cols="12" class="ei-name2 no-wrap">
        {{ item.name }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="ei-image pt-0 px-0 images"
        @click="showProductInstanceItemForShow(item.id)"
      >
        <v-img
          v-if="item.images &&  item.images[0]"
          aspect-ratio="1.6"
          min-width="100%"
          max-width="100%"
          :lazy-src="'/content/attachments/' + item.images[0].id + '/playcare_preview.png'"
          :src="'/content/attachments/' + item.images[0].id + '/' + item.images[0].filename"
        />
        <div
          v-if="item.images.length > 1"
          class="badge green-bg"
        >
          {{ item.images.length }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7" class="product-id no-wrap">
        {{ item.ifs_identifier }}
      </v-col>
      <v-col cols="5" class="text-right product-code no-wrap">
        {{ item.product_number }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import productInstancesMethods from '@/components/ProductInstances/productInstancesMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ProductInstancesListItem',

  data () {
    return {
      model: 'productInstances',
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    getListItems: {
      type: Function,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isInspection: {
      type: Boolean,
      default: false,
    },
    loadInspectionProductInstances: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...productInstancesMethods,

    edit () {
      this.showProductInstanceEditModal(this.item.id, {
        callback: this.changeCallbackFunction,
      })
    },

    // Callback function depending on whether list is shown in the main module or inside inspection flow
    changeCallbackFunction () {
      return this.isInspection ? this.loadInspectionProductInstances() : this.getListItems()
    },

    deleteItem (id) {
      if (window.confirm(this.$i18n.t('productInstances.deleteConfirm'))) {
        this.productInstancesLoading = true
        this.$store.dispatch('setItemAsRemoved', {
          model: 'product_instances',
          itemId: id,
          resource: 'product_instances',
        }).then(response => {
          this.productInstancesLoading = false
          if (response && response.data && response.data.status === 'ok') {
            this.changeCallbackFunction()
            // Alter watch trigger for Inspection Product Instance list
            this.productInstanceDeleteTrigger++
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.list-item {
  border-radius: 10px;
  background: white;
  overflow: hidden;
  font-size: 18px;
  .ei-address {
    margin-top: -7px;
  }
  .ei-name {
    font-weight: 600;
    font-size: 20px;
  }
  .ei-name2 {
    font-weight: 600;
    font-size: 18px;
  }
  .ei-image {
    min-height: 200px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    overflow: hidden;
  }
  .ei-menu-btn {
    position: relative;
    text-align: right;
    padding: 0 5px;
    right: -7px  ;
  }
  .ei-header {
    background: #eeeeee;
    margin: -12px -12px 0 -12px;
    padding: 22px 12px 5px 12px;
  }
  .product-id {
    font-weight: 700;
  }
}
.ei-menu {
  border-radius: 12px !important;
}
</style>
