<template>
  <v-container fluid>
    <table class="work-order-works">
      <thead>
        <tr>
          <th style="width: 10%;">{{ $i18n.t('works.priority') }}</th>
          <th style="width: 10%;">{{ $i18n.t('works.workNumber') }}</th>
          <th style="width: 20%;">{{ $i18n.t('works.activityPark') }}</th>
          <th style="width: 30%;">{{ $i18n.t('works.description') }}</th>
          <th style="width: 10%;">{{ $i18n.t('works.cost') }}</th>
          <th style="width: 10%;" class="text-center">{{ $i18n.t('general.objectState') }}</th>
        </tr>
      </thead>
      <tr
        v-for="(work, index) in works"
        :key="index"
      >
        <td>
          <div
            v-if="work.priority"
            class="priority-box"
            :style="priorityStyle(work.priority)"
          >
            {{ work.priority || '?' }}
          </div>
        </td>
        <td>{{ work.work_number }}</td>
        <td>{{ work.activity_park && work.activity_park.name}}</td>
        <td>{{ work.work_description }}</td>
        <td class="text-right" nowrap>{{ formatPrice(work.cost, 2) }}</td>
        <td class="text-center">
          <div
            :style="objectStateStyle(work.main_object_state)"
          >
            {{ work.main_object_state[`name_${$i18n.locale}`] }}
          </div>
        </td>
      </tr>
    </table>
  </v-container>
</template>

<script>
import allMethods from '@/components/allMethods'
import helper from '@/helper'

export default {
  name: 'WorkOrderWorks',

  props: {
    works: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
    ...allMethods,
  },
}
</script>

<style lang="scss">
.work-order-works {
  tr:nth-child(odd) {
    /* background: rgba(155, 155, 155, 0.06) !important; */
  }
}
.work-order-works {
  border-spacing: 0;
  width: 100%;
  tr:hover {
    background: rgba(155, 155, 155, 0.06) !important;
  }
  tr {
    background: transparent !important;
  }
  th {
    text-align: left;
    padding: 5px 10px;
    font-weight: 600;
  }
  td {
    padding: 5px 10px;
  }
}
</style>
