<template>
  <v-container fluid class="items-list">
    <ListTopPagination
      v-model="activityParksListOptions.page"
      :total-pages="pages"
      model="activityParks"
    />
    <v-progress-linear
      v-if="activityParksLoading"
    />
    <v-container fluid class="items-container">
      <v-row>
        <v-col
          v-for="(item, i) in activityParksItems"
          :key="i"
          cols="12"
          class="px-5"
        >
          <ActivityParksListItem
            :item="item"
            :callback-on-item-change="getListItems"
            :callback-on-item-hide="getListItems"
          />
        </v-col>
      </v-row>
    </v-container>
    <ListBottomPagination
      v-model="activityParksListOptions.page"
      :total-pages="pages"
    />
  </v-container>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import ActivityParksListItem from '@/components/ActivityParks/ActivityParksListItem'
import ListTopPagination from '@/components/Shared/ListTopPagination'
import ListBottomPagination from '@/components/Shared/ListBottomPagination'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParksList',

  data () {
    return {
      model: 'activityParks',
    }
  },

  components: {
    ListBottomPagination,
    ListTopPagination,
    ActivityParksListItem,
  },

  computed: {
    ...mapFields(Object.keys(state)),

    pages () {
      return Math.ceil(this.activityParksItemsTotal / this.activityParksListOptions.itemsPerPage)
    },
  },

  methods: {
    ...allMethods,
  },
}
</script>
