<template>
  <v-container class="white elevation-3 mt-5">
    <ActivityParkItemModal
      v-model="showActivityParkModalForId"
      @reload="getRecentInspections"
    />
    <v-row no-gutters>
      <v-col cols="12" class="h1 px-5 pt-5 pb-3">
        {{ $i18n.t('home.events').toUpperCase() }}
      </v-col>
      <v-col cols="12" class="pb-5 px-5">
        {{ $i18n.t('home.latestInspections') }}
      </v-col>
      <template
        v-for="(item, index) in inspections"
      >
        <v-col
          :key="index + 's'"
          class="px-3 py-0"
          cols="12"
        >
          <div class="h-spacer-0" />
        </v-col>
        <v-col
          :key="index"
          class="12"
        >
          <v-container class="pa-1">
            <v-row>
              <v-col cols="4" class="align-self-center">
                <v-img
                  v-if="item.images &&  item.images[0]"
                  aspect-ratio="1.6"
                  class="rounded-12 link"
                  max-width="100%"
                  max-height="130"
                  :src="'/content/attachments/' + item.images[0].id + '/' + item.images[0].filename"
                  @click="open(item.id)"
                />
              </v-col>
              <v-col cols="8" class="py-0">
                <v-container class="px-1">
                  <v-row>
                    <v-col cols="12" class="text-right pt-0 h4">
                      {{ $i18n.t('home.inspected') }} {{formatDate(item.last_inspection_date) }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="h2 py-0 link no-wrap"
                      @click="open(item.id)"
                    >
                      {{ item.name }}
                    </v-col>
                    <v-col cols="12" class="py-0 no-wrap">
                      {{ item.name_extension }}
                    </v-col>
                    <v-col cols="12">
                      {{ lastActionPlanText(item) }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/store/api/'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'
import helper from '@/helper'
import ActivityParkItemModal from '@/components/ActivityParks/ActivityParkItemModal'

export default {
  name: 'HomeRecentInspections',

  components: { ActivityParkItemModal },

  data () {
    return {
      inspections: [],
      showActivityParkModalForId: null,
    }
  },

  created () {
    this.getRecentInspections()
  },

  computed: {
    organizationFilterIds () {
      return this.$store.state.globalFilters.organization.map(i => i.id).join(' ')
    },
  },

  watch: {
    organizationFilterIds () {
      this.getRecentInspections()
    },
  },

  methods: {
    formatDate (date) {
      return helper.formatDate(date)
    },

    open (id) {
      this.showActivityParkModalForId = id
    },

    getRecentInspections () {
      const filters = {
        order: 'last_inspection_date desc',
        offset: 0,
        limit: 3,
      }
      if (this.organizationFilterIds) {
        filters._organization_id = '(' + this.organizationFilterIds + ')'
      }
      this.$store.dispatch('getListItems', {
        model: 'activity_parks',
        filters,
        queries: activityParksQueries.forList,
      })
        .then(response => {
          if (response && response.items) {
            this.inspections = response.items
          }
        })
    },

    lastActionPlanWork (item) {
      const actionPlanWorks = item.works.filter(work => work.work_type && work.work_type.id === this.$store.state.workTypeIds.actionPlan)
      const lastActionPlanWork = actionPlanWorks[actionPlanWorks.length - 1]
      return lastActionPlanWork
    },

    lastActionPlanText (item) {
      const lastActionPlanWork = this.lastActionPlanWork(item)
      if (!lastActionPlanWork || !lastActionPlanWork.work_description) { return '-' }
      return lastActionPlanWork.work_description
    },
  },

}
</script>
