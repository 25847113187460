<template>
  <v-container
    fluid
    class="items-list"
  >
    <ListTopPagination
      v-model="workOrdersListOptions.page"
      :total-pages="pages"
      :expand-all="expandAll"
      model="workOrders"
    />
    <v-data-table
      :headers="headers.filter((header) => header.show)"
      :items="workOrdersItems"
      :expanded.sync="expanded"
      :hide-default-footer="true"
      :item-class="itemStyle"
      :options.sync="workOrdersListOptions"
      :loading="workOrdersLoading"
      :loading-text="$i18n.t('workOrders.loading')"
      :server-items-length="workOrdersItems.length"
      item-key="id"
      show-expand
      class="elevation-1"
      @click:row="rowClick"
    >
      <template slot="no-data">
        {{ $i18n.t('workOrders.listEmpty') }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          :class="item.index % 2 ? 'odd-row-td' : ''"
        >
          <WorkOrderWorks :works="item.works" />
        </td>
      </template>
      <template v-slot:item.order_date="{ item }">
        {{ formatDate(item.order_date) }}
      </template>
      <template v-slot:item.total_cost="{ item }">
        {{ formatPrice(item.total_cost, 2) }}
      </template>
      <template v-slot:item.main_object_state="{ item }">
        <div
          v-if="item.main_object_state"
          :style="objectStateStyle(item.main_object_state)"
        >
          {{ item.main_object_state[`name_${$i18n.locale}`] }}
        </div>
      </template>
    </v-data-table>
    <ListBottomPagination
      v-model="workOrdersListOptions.page"
      :total-pages="pages"
    />
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ListTopPagination from '@/components/Shared/ListTopPagination'
import ListBottomPagination from '@/components/Shared/ListBottomPagination'
import WorkOrderWorks from '@/components/WorkOrders/WorkOrderWorks'
import helper from '@/helper'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'WorkOrdersList',

  components: { WorkOrderWorks, ListBottomPagination, ListTopPagination },

  data() {
    return {
      page: 6,
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: this.$i18n.t('workOrders.mainDateShort'),
          align: 'start',
          sortable: true,
          value: 'order_date',
          show: !this.modal,
        },
        {
          text: this.$i18n.t('workOrders.orderNumber'),
          value: 'order_number',
          show: !this.modal,
        },
        {
          text: this.$i18n.t('workOrders.orderer'),
          value: 'person.summary',
          show: !this.modal,
        },
        {
          text: this.$i18n.t('workOrders.cost'),
          value: 'total_cost',
          show: !this.modal && this.userInfo && this.userInfo.allow_show_prices,
          align: 'right',
        },
        {
          text: this.$i18n.t('general.objectState'),
          value: 'main_object_state',
          show: !this.modal,
          align: 'right',
        },
        {
          text: '',
          value: 'data-table-expand',
          show: !this.modal,
        },
      ],
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    pages() {
      return Math.ceil(
        this.workOrdersItemsTotal / this.workOrdersListOptions.itemsPerPage
      )
    },
  },

  methods: {
    ...allMethods,

    rowClick(work, extra) {
      // Toggle expanded
      extra.expand(!extra.isExpanded)
    },

    closeAllExpanded() {
      this.expanded = []
    },

    expandAll() {
      if (this.expanded && this.expanded.length > 10) {
        this.closeAllExpanded()
      } else {
        this.expanded = this.workOrdersItems
      }
    },

    itemStyle(item) {
      if (item.index % 2) {
        return 'odd-row'
      }
    },

    formatDate(date) {
      return helper.formatDate(date)
    },
  },
}
</script>
