const shared = [
  'created_at',
  'name',
  'identity_code',
  'sector',
  'language identifier,name',
  'country identifier,name',
  'files filename,content_type',
]

export default {
  forList: [...shared],
  forEdit: [...shared],
  forShow: [...shared],
}
