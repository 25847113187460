<template>
  <v-dialog
    v-model="showUserInfoEditForm"
    overlay-opacity="1"
    overlay-color="white"
    max-width="800"
  >
    <v-toolbar color="grey elevation-0" dark>
      <v-icon class="mx-5">fa-user</v-icon>
      <v-toolbar-title>{{ $i18n.t('user.myInfo') }}</v-toolbar-title>
    </v-toolbar>
    <v-container class="white" ma-0 pa-5 fluid>
      <v-row v-if="saving">
        <v-col>
          <v-progress-linear
            indeterminate
            color="grey"
            class="my-5"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="6">
          <v-text-field
            v-model="formData.first_name"
            :label="$i18n.t('user.firstName')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.last_name"
            :label="$i18n.t('user.lastName')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.phone"
            :label="$i18n.t('user.phone')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.email"
            :label="$i18n.t('user.email')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.street_address.street_1"
            :disabled="!formData.street_address.id"
            :label="$i18n.t('user.address')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.street_address.postal_code_string"
            :disabled="!formData.street_address.id"
            :label="$i18n.t('user.index')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.street_address.postal_office_string"
            :disabled="!formData.street_address.id"
            :label="$i18n.t('user.state')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="formData.organization.name"
            :disabled="!formData.organization.id"
            :label="$i18n.t('user.organization')"
            v-bind="textFieldFormatAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-btn
            color="#8cc477"
            dark
            v-bind="popupButtonFormatAttributes"
            @click="save"
          >
            {{ $i18n.t('general.save') }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="#666"
            block
            outlined
            v-bind="popupButtonFormatAttributes"
            @click="showUserInfoEditForm = false"
          >
            {{ $i18n.t('general.close') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
import api from '@/store/api'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const userFields = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone',
]

export default {
  name: 'UserInfoForm',

  data () {
    return {
      saving: false,
      formData: {
        organization: {},
        street_address: {},
      },
    }
  },

  created () {
    // Make a copy from user data for the form
    this.formData = JSON.parse(JSON.stringify(this.userInfo.person))
    if (!this.formData.street_address) {
      this.formData.street_address = {}
    }
    if (!this.formData.organization) {
      this.formData.organization = {}
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...allMethods,

    save () {
      // Update user info
      this.saving = true
      const item = {}
      userFields.forEach(fieldName => {
        item[fieldName] = this.formData[fieldName]
      })
      api.saveItem('people', item, [], {}).then(response => {
        this.$store.dispatch('globalErrorDisplay', response)
        const id = response && response.data && response.data.item && response.data.item.id
        if (id) {
          this.saveUserAddress().then(() => {
            this.saveUserCompany().then(() => {
              // Update also user info in store
              this.refreshUserInfo()
            })
          })
        } else {
          this.closeForm()
        }
      })
    },

    saveUserAddress () {
      if (!this.formData.street_address || !this.formData.street_address.id) {
        return new Promise(resolve => { resolve() })
      }
      const addressFields = 'id,street_1,postal_code_string,postal_office_string'
      const item = {}
      addressFields.split(',').forEach(addressFieldName => {
        item[addressFieldName] = this.formData.street_address[addressFieldName]
      })
      return api.saveItem('addresses', item, [], {})
    },

    saveUserCompany () {
      if (!this.formData.organization || !this.formData.organization.id) {
        return new Promise(resolve => { resolve() })
      }
      const organizationFields = 'id,name'
      const item = {}
      organizationFields.split(',').forEach(fieldName => {
        item[fieldName] = this.formData.organization[fieldName]
      })
      return api.saveItem('organizations', item, [], {})
    },

    refreshUserInfo () {
      this.$store.dispatch('trySessionRestoreAndSetUserInfo')
      this.closeForm()
    },

    closeForm () {
      this.saving = false
      this.showUserInfoEditForm = false
    },
  },
}
</script>
