<template>
  <v-container
    fluid
    class="elevation-2 activity-park pa-0"
  >
    <ImagesCarouselModal
      v-model="showImagesModal"
      v-if="showImagesModal"
      :images="item.images || []"
    />
    <v-row>
      <v-col cols="12" class="py-0">
        <v-container fluid class="py-0">
          <v-row class="names px-3">
            <v-col cols="11" lg="4" order="1" order-lg="1" class="ap-name">{{ item.name }}</v-col>
            <v-col
              style="min-width: 100px; max-width: 100%;"
              cols="12" lg="7" order="3" order-lg="2" class="ap-name2 text-right flex-grow-1 flex-shrink-0"
            >
              {{ item.name_extension }}
            </v-col>
            <v-col
              style="min-width: 50px; max-width: 50px;"
              :cols="1" lg="1" order="2" order-lg="3" class="text-right flex-grow-0 flex-shrink-1"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="white"
                  >
                    <v-icon>
                      fa-bars
                    </v-icon>
                  </v-btn>
                </template>
                <ActivityParksListItemMenu
                  :item="item"
                  :callback-on-item-change="callbackOnItemChange"
                  :callback-on-item-hide="callbackOnItemHide"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="px-3 py-2">
            <v-col
              :cols="tabletView ? 5 : 3"
              class="images link"
              @click="showImagesModal = true"
            >
              <v-img
                v-if="item.images &&  item.images[0]"
                class="ap-image"
                max-height="280px"
                min-height="280px"
                width="100%"
                :lazy-src="'/content/attachments/' + item.images[0].id + '/playcare_preview.png'"
                :src="'/content/attachments/' + item.images[0].id + '/' + item.images[0].filename"
              />
              <div
                v-if="item.images.length > 1"
                class="badge green-bg"
              >
                {{ item.images.length }}
              </div>
            </v-col>
            <v-col
              :cols="tabletView ? 7 : 4"
            >
              <v-container fluid class="light-elevation py-0">
                <v-row>
                  <v-col cols="12" class="content-row">
                    <b>{{ (item.organization && item.organization.name) || '-'}}</b><br>
                    {{ item.contact_person_name || '-'}}
                  </v-col>
                  <v-col cols="12" class="grey-bg content-row">
                    <b>{{ $i18n.t('activityParks.activityParkType') }}:</b> {{ (item.activity_park_type || {})[`name_${$i18n.locale}`] || '-' }}<br>
                    <b>{{ $i18n.t('activityParks.activity_park_areas') }}:</b> {{ item.play_areas.length === 0 ? 1 : item.play_areas.length }}
                  </v-col>
                  <v-col cols="12" class="content-double-row">
                    <div
                      class="priority-box ap-priority-box"
                      :style="priorityStyle(lastActionPlanPriority(item))"
                    >
                      {{ lastActionPlanPriority(item) }}
                    </div>
                    <b>{{ $i18n.t('activityParks.long_term_plans') }}:</b><br>
                    <span class="last-action-plan-text">{{ lastActionPlanText(item) }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              :cols="tabletView ? 12 : 5"
            >
              <v-container fluid class="light-elevation py-0">
                <v-row>

                  <!-- INSPECTION REPORT -->

                  <v-col
                    v-if="userInfo && userInfo.allow_pdf_export"
                    cols="6"
                    class="content-row grey-bg"
                  >
                    <v-icon class="ap-icon">
                      fa-file-pdf
                    </v-icon>
                    <a class="ap-title2 link"
                      :href="`/content/documents/${item.id}/playcare_report`"
                      target="_blank"
                    >{{ $i18n.t('activityParks.inspectionReport') }}
                    </a>
                  </v-col>
                  <v-col
                    :cols="(userInfo && userInfo.allow_pdf_export) ? '6' : '12'"
                    class="content-row grey-bg text-right"
                  >
                    <template v-if="daysFromInspection(item) >= 0">
                      {{ $i18n.t('activityParks.inspection') }}
                      <span
                        class="days last-inspection-class"
                        :style="lastInspectionStyle(daysFromInspection(item))"
                      >
                      {{ daysFromInspection(item) === 999 ? '?' : daysFromInspection(item) }}
                    </span>
                      {{ $i18n.t('activityParks.daysAgo') }}<br>
                    </template>
                    {{ lastInspectionPerson(item) }}
                  </v-col>

                  <!-- WORKS -->

                  <v-col
                    cols="7"
                    class="content-row"
                  >
                    <v-icon class="ap-icon">
                      fa-exclamation-triangle
                    </v-icon>
                    <span
                      class="ap-title2 link"
                      @click="showWorks({})"
                    >{{ $i18n.t('works.title') }}</span>
                    <br v-if="Object.keys(worksCountByPriority).length > 2">
                    <span
                      v-for="(workPriority, wc_index) in Object.keys(worksCountByPriority)"
                      :key="wc_index"
                      class="priority-box mr-4 link"
                      :style="priorityStyle(workPriority || '?')"
                      @click="showWorks({ priority: workPriority, objectStateIds: $store.state.workActiveStates })"
                    >
                      {{ workPriority || '?' }}
                      <div
                        class="priority-box-count"
                        v-if="worksCountByPriority[workPriority] > 0"
                      >
                        {{ worksCountByPriority[workPriority] }}
                      </div>
                    </span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="content-row text-right"
                  >
                    <span v-if="$vuetify.breakpoint.mdAndUp" class="add-new-title">{{ $i18n.t('activityParks.addNewWork') }}</span>
                    <v-btn
                      class="square-btn"
                      @click="showNewActivityParkWorkForm(item)"
                    >
                      <v-icon>
                        fa-plus
                      </v-icon>
                    </v-btn>
                  </v-col>

                  <!-- PRODUCT INSTANCES -->

                  <v-col cols="6" class="grey-bg content-row">
                    <v-icon class="ap-icon">fa-child</v-icon>
                    <span
                      class="ap-title2 link"
                      @click="showEquipments()"
                    >{{ $i18n.t('productInstances.title') }}</span>
                    <span
                      v-if="productInstanceInOperation(item)"
                    >
                      <b>{{ productInstanceInOperation(item).length }} {{ $i18n.t('general.quantity') }}</b>
                    </span>
                  </v-col>
                  <v-col cols="6" class="grey-bg content-row text-right">
                    <span v-if="$vuetify.breakpoint.mdAndUp" class="add-new-title">{{ $i18n.t('activityParks.addNewEquipment') }}</span>
                    <v-btn
                      class="square-btn"
                      @click="showNewActivityParkEquipmentForm(item)"
                    >
                      <v-icon>
                        fa-plus
                      </v-icon>
                    </v-btn>
                  </v-col>

                  <!-- ORDERED WORKS -->

                  <v-col cols="12" class="content-row">
                    <v-icon class="ap-icon">fa-tasks</v-icon>
                    <span
                      c class="ap-title2 link"
                      @click="showWorks({ objectStateIds: [$store.state.workStateIds.ordered] })"
                    >{{ $i18n.t('activityParks.orderedWorks') }}</span>
                    <span
                      v-if="item.works && item.works[0]"
                    >
                      <b>{{ item.works.filter(work => work.main_object_state && work.main_object_state.id === $store.state.workStateIds.ordered).length }} {{ $i18n.t('general.quantity') }}</b>
                    </span>
                  </v-col>

                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import allMethods from '@/components/allMethods'
import ActivityParksListItemMenu from '@/components/ActivityParks/ActivityParksListItemMenu'
import ImagesCarouselModal from '@/components/Shared/ImagesCarouselModal'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParksListItem',

  data () {
    return {
      showImagesModal: false,
    }
  },

  components: { ImagesCarouselModal, ActivityParksListItemMenu },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    callbackOnItemChange: {
      type: Function,
      default: () => {},
    },
    callbackOnItemHide: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    tabletView () {
      return this.innerWidth < 1560
    },

    worksCountByPriority () {
      const countsByPriority = {}
      this.item.works.filter(
        work => !!work.priority &&
        this.$store.state.workActiveStates.includes(work.main_object_state_id) &&
        this.$store.state.workPriorities.includes(work.priority.toLowerCase())
      ).forEach(work => {
        if (!countsByPriority[work.priority]) { countsByPriority[work.priority] = 0 }
        countsByPriority[work.priority]++
      })
      return countsByPriority
    },
  },

  methods: {
    ...allMethods,

    startInspection () {
      this.inspection = JSON.parse(JSON.stringify(this.inspectionDefaultProps))
      this.$set(this.inspection, 'activityPark', this.item)
      this.$router.push({
        path: '/inspection',
      })
    },

    setActivityParkAsGlobalFilter () {
      // Set global filters
      this.globalFilters = {
        organization: this.globalFilters.organization,
        activity_park: [{
          id: this.item.id,
          summary: this.item.name,
        }],
      }
    },

    showWorks ({ priority = null, objectStateIds = null }) {
      this.activityParksOldState = {
        activityParksItems: { ...this.activityParksItems },
        activityParksListOptions: { ...this.activityParksListOptions },
        globalFilters: { ...this.globalFilters }
      }
      this.setActivityParkAsGlobalFilter()
      // Set works filters
      this.worksFilters = JSON.parse(JSON.stringify(this.worksDefaultFilters))
      if (priority) {
        this.$set(this.worksFilters, 'priority', [priority])
      }
      this.$set(this.worksFilters, 'main_object_state_id', objectStateIds)
      this.$nextTick(() => {
        this.$router.push({ path: '/works' })
      })
    },

    productInstanceInOperation (item) {
      return item.product_instances.filter(pi => pi.in_operation || pi.in_operation === 1)
    },

    showEquipments () {
      this.activityParksOldState = {
        activityParksItems: { ...this.activityParksItems },
        activityParksListOptions: { ...this.activityParksListOptions },
        globalFilters: { ...this.globalFilters }
      }
      this.setActivityParkAsGlobalFilter()
      this.$router.push({ path: '/equipments' })
    },

    showNewActivityParkEquipmentForm (activityPark) {
      this.showProductInstanceEditModal('new', {
        activityPark: JSON.parse(JSON.stringify(activityPark)),
        callback: this.callbackOnItemChange,
      })
    },

    showNewActivityParkWorkForm (activityPark) {
      this.showWorkEditModal('new', {
        activityPark: JSON.parse(JSON.stringify(activityPark)),
        callback: this.callbackOnItemChange,
      })
    },

    lastInspectionStyle (daysFromLastInspection) {
      const greenLastInspectionDaysLimit = 30
      return {
        background: daysFromLastInspection < greenLastInspectionDaysLimit ? this.greenColor : '#f7c051',
      }
    },

    daysFromInspection (item) {
      const lastInspectionWork = this.lastInspectionWork(item)
      if (!lastInspectionWork || !lastInspectionWork.completion_date) { return 999 }
      return moment().diff(moment(lastInspectionWork.completion_date), 'days')
    },

    lastInspectionPerson (item) {
      const lastInspectionWork = this.lastInspectionWork(item)
      if (!lastInspectionWork) { return '' }
      return (lastInspectionWork.reporter_name ? lastInspectionWork.reporter_name : '??') +
        ', ' +
        (lastInspectionWork.reporter_certificate ? lastInspectionWork.reporter_certificate : '??')
    },

    lastInspectionWork (item) {
      const inspectionWorks = item.works.filter(work => (work.inspection_work || work.inspection_work === 1) && !!work.completion_date)
      const sortedByCompletionDate = inspectionWorks.sort((a, b) => moment(a.completion_date).diff(b.completion_date))
      const lastInspectionWork = sortedByCompletionDate[sortedByCompletionDate.length - 1]
      return lastInspectionWork
    },

    lastActionPlanWork (item) {
      const actionPlanWorks = item.works.filter(work => work.work_type && work.work_type.id === this.workTypeIds.actionPlan)
      const lastActionPlanWork = actionPlanWorks[actionPlanWorks.length - 1]
      return lastActionPlanWork
    },

    lastActionPlanText (item) {
      const lastActionPlanWork = this.lastActionPlanWork(item)
      if (!lastActionPlanWork || !lastActionPlanWork.work_description) { return '-' }
      return lastActionPlanWork.work_description
    },

    lastActionPlanPriority (item) {
      const lastActionPlanWork = this.lastActionPlanWork(item)
      if (!lastActionPlanWork || !lastActionPlanWork.priority) { return '?' }
      return lastActionPlanWork.priority
    },
  },
}
</script>

<style lang="scss">
.activity-park {
  overflow: hidden;
  border-radius: 20px;
  background: white;
  color: #777;
  .names {
    color: white;
    background: #8cc477;
    line-height: 36px;
    .ap-name {
      font-size: 21px;
      font-weight: 600;
    }
    .ap-name2 {
      font-size: 20px;
      font-weight: 400;
    }
  }
  .last-inspection-class {
    text-align: center;
    padding: 3px 7px 3px 10px;
    color: white;
    margin: 0 4px;
    border-radius: 25px;
  }
  .ap-image {
    border-radius: 15px;
  }
  .grey-bg {
    background: #f9f9f9;
  }
  .content-row {
    min-height: 70px;
    .ap-title2 {
      line-height: 50px;
      font-weight: 700;
      padding-right: 20px;
      text-decoration: underline;
    }
    .ap-icon {
      width: 50px;
      padding: 0 15px 0 5px;
      margin-top: -5px;
      color: #999 !important;
    }
    .add-new-title {
      line-height: 44px;
      font-size: 15px;
      font-weight: 700;
      padding-right: 15px;
    }
    .square-btn {
      background: white !important;
      color: #999 !important;
      i {
        font-size: 15px !important;
      }
    }
  }
  .content-double-row {
    min-height: 140px;
    padding-right: 50px;
    position: relative;
    .ap-priority-box {
      position: absolute;
      top: 10px;
      right: 10px,
    }
  }
  .light-elevation {
    box-shadow: 0 3px 5px -1px rgba(175,175,175,.2),0 5px 8px 0 rgba(175,175,175,.14),0 1px 14px 0 rgba(175,175,175,.12) !important;
  }
  .last-action-plan-text {
    white-space: pre-wrap;
  }
}
</style>
