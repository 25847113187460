<template>
  <v-container
    fluid
    style="max-width: 1600px;"
    class="body-container pa-0 elevation-3"
  >
    <ListSearchToolbar model="works" />
    <ListTitleToolbar model="works" />
    <WorksList ref="worksList" />
    <v-dialog
      v-model="showWorksSearchForm"
      width="1000"
    >
      <WorksSearchForm />
    </v-dialog>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import WorksList from '@/components/Works/WorksList'
import ListSearchToolbar from '@/components/Shared/ListSearchToolbar'
import ListTitleToolbar from '@/components/Shared/ListTitleToolbar'
import WorksSearchForm from '@/components/Works/WorksSearchForm'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'Works',

  data () {
    return {
      model: 'works',
    }
  },

  components: {
    WorksSearchForm,
    ListTitleToolbar,
    ListSearchToolbar,
    WorksList,
  },

  watch: {
    workDeleteConfirmForWorkId (value) {
      if (value) { return }
      this.getListItems()
    },

    worksListOptionsTrigger () {
      this.getListItems()
    },

    'worksListOptions.page' (now, before) {
      // When page has changed, close all expanded in Works List
      // otherwise expand-all button does not work first time
      if (now !== before) {
        this.$refs.worksList.closeAllExpanded()
      }
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    worksListOptionsTrigger () {
      return JSON.stringify(this.worksListOptions)
    },
  },

  created () {
    this.worksListOptions.page = 1
    this.getListItems()
  },

  methods: {
    ...allMethods,
  },
}
</script>
