<template>
  <v-app-bar
    app
    color="#ffffff"
    dark
    class="elevation-4 app-bar"
    height="80"
  >
    <v-container
      fluid
      style="max-width: 1560px"
      class="pa-0"
    >
      <v-row no-gutters>
        <v-col class="align-self-center">
          <div class="d-flex align-center">
            <v-img
              alt="PlayCare"
              class="shrink mt-1"
              contain
              min-width="150"
              :src="require('../../assets/lappset-2023.png')"
              width="100"
            />
          </div>
        </v-col>
        <v-col
          cols="8"
          class="text-right"
        >
          <v-btn
            class="mr-2"
            text
            :href="`/api/people/user_manual?locale=${$i18n.locale}`"
            download
          >
            <v-icon
              small
	      color="#8cc477"
              >fa-paperclip</v-icon
            >
            <span
              v-if="$vuetify.breakpoint.lgAndUp"
              class="ml-2"
            >
              {{ $i18n.t('general.userManual') }}
            </span>
          </v-btn>
          <v-btn
            color="#8cc477"
            class="mr-2"
            text
            to="/"
          >
            <v-icon
              small
              color="#8cc477"
              >fa-home</v-icon
            >
            <span
              v-if="$vuetify.breakpoint.lgAndUp"
              class="ml-2"
            >
              {{ $i18n.t('general.home') }}
            </span>
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#8cc477"
                text
                class="mr-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  small
                  color="#8cc477"
                  >fa-globe-africa</v-icon
                >
                <span class="ml-2 mr-2">
                  {{ locale.toUpperCase() }}
                </span>
                <v-icon
                  small
                  color="#8cc477"
                  >fa-angle-down</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeLang('en')">
                <v-list-item-title>EN</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLang('fi')">
                <v-list-item-title>FI</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLang('se')">
                <v-list-item-title>SE</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            color="#8cc477"
            class="mr-5"
            text
            tile
            to="/cart"
          >
            <v-icon
              small
              color="#8cc477"
              >fa-shopping-cart</v-icon
            >
            <div
              v-if="shoppingCartItemsTotal"
              class="badge green-bg"
            >
              {{ shoppingCartItemsTotal }}
            </div>
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color_="#8cc477"
                color="#8cc477"
                v-bind="attrs"
                class="elevation-0 mr-2 user-btn"
                v-on="on"
              >
                <v-icon small>fa-user</v-icon>
                <span
                  v-if="$vuetify.breakpoint.lgAndUp"
                  class="ml-2 mr-2"
                >
                  {{
                    (userInfo.person && userInfo.person.name) || userInfo.name
                  }}
                </span>
                <v-icon small>fa-angle-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showUserInfoEditForm = true">
                <v-list-item-avatar>
                  <v-icon color="#8cc477"> fa-user </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $i18n.t('user.myInfo') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showUserPasswordForm = true">
                <v-list-item-avatar>
                  <v-icon color="#8cc477"> fa-key </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $i18n.t('user.changePassword') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$store.dispatch('logout')">
                <v-list-item-avatar>
                  <v-icon color="#8cc477"> fa-sign-out-alt </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $i18n.t('general.logout') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'AppBar',

  computed: {
    ...mapFields(Object.keys(state)),

    shoppingCartItemsTotal() {
      if (!this.shoppingCartItems || this.shoppingCartItems.length === 0) {
        return
      }
      const total = this.shoppingCartItems
        .map((i) => i.quantity)
        .reduce((a, b) => a + b)
      return total > 0 ? total : ''
    },
  },

  methods: {
    changeLang(lang) {
      this.locale = lang
      this.$i18n.locale = lang
    },
  },
}
</script>

<style lang="scss">
.app-bar {
  @media (min-width: 1264px) {
    .container {
      max-width: 1785px;
    }
  }
  .v-btn--text {
    color: #777 !important;
    font-weight: 400;
    font-size: 18px !important;
    i {
      font-size: 22px !important;
    }
  }
  .v-btn {
    height: 80px !important;
  }
  .user-btn {
    height: 40px !important;
  }
}
</style>
