<template>
  <v-container class="elevation-3 green-bg white--text px-5" style="height: 147px;">
    <v-row>
      <v-col
        cols="12"
        :class="$vuetify.breakpoint.xlOnly ? 'h1' : 'h2'"
      >
        {{ $i18n.t('home.welcome').toUpperCase() }} {{ ((userInfo.person && userInfo.person.name)  || userInfo.name).toUpperCase() }}
      </v-col>
      <v-col cols="12" class="h3 py-0">
        {{ $i18n.t('home.lastLoginDate') }} {{ lastLoginDate(userInfo.last_login) }}
      </v-col>
      <v-col class="pt-0">
        {{ $i18n.t('general.clock') }} {{ lastLoginClock(userInfo.last_login) }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import moment from 'moment'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HomeWelcome',

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    lastLoginDate (date) {
      if (!date) { return null }
      return moment(date).format('DD.MM.YYYY')
    },

    lastLoginClock (date) {
      if (!date) { return null }
      return moment(date).format('HH.mm')
    },
  },
}
</script>

<style>
.welcome-box {
}
</style>
