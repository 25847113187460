<template>
  <v-container>
    <v-row>
      <v-expansion-panels
        v-model="panel"
        accordion flat
      >
        <v-expansion-panel
          active-class="elevation-3"
        >
          <v-expansion-panel-header
            class="h2 header-green"
          >
            {{ $i18n.t('inspection.followUpWorkTitle').toUpperCase() }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-container
              fluid class="pa-0"
            >
              <v-row class="pt-5 mt-3">
                <v-col cols="12" class="text-subtitle">{{ $i18n.t('works.priority') }}</v-col>
                <v-col
                  cols="6"
                >
                  <PrioritySelect
                    v-model="inspection.followUpWork.priority"
                  />
                </v-col>
                <v-col cols="12" class="text-subtitle">
                  {{ $i18n.t('inspection.followUpWorkDescription') }}
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="inspection.followUpWork.description"
                    v-bind="textFieldFormatAttributes"
                    :label="$i18n.t('inspection.followUpWorkDescriptionText')"
                    rows="4"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import PrioritySelect from '@/components/Shared/PrioritySelect'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'InspectionFollowUpWork',

  components: {
    PrioritySelect,
  },

  data () {
    return {
      panel: 0,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },
}
</script>
