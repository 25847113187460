import api from '@/store/api'
import helper from '@/helper'
import i18n from '@/locale/i18n'
import workOrderSaveFields from '@/components/WorkOrders/workOrderSaveFields'
import productInstanceSaveFields from '@/components/ProductInstances/productInstanceSaveFields'
import workSaveFields from '@/components/Works/workSaveFields'
import activityParkSaveFields from '@/components/ActivityParks/activityParkSaveFields'
import moment from 'moment'
import Vue from 'vue'
import workItemSaveFields from '@/components/Works/WorkItems/workItemSaveFields'
import playAreaSaveFields from '@/components/ActivityParks/playAreaSaveFields'
import workOfferSaveFields from '@/components/WorkOffers/workOfferSaveFields'
import workOfferItemSaveFields from '@/components/WorkOffers/workOfferItemSaveFields'

const saveFieldsByModelName = {
  ProductInstance: productInstanceSaveFields,
  Work: workSaveFields,
  ActivityPark: activityParkSaveFields,
  WorkOrder: workOrderSaveFields,
  WorkOffer: workOfferSaveFields,
  WorkOfferItem: workOfferItemSaveFields,
  WorkCostItem: workItemSaveFields.get('work_cost_items'),
  ExpenseItem: workItemSaveFields.get('expense_items'),
  SparePartItem: workItemSaveFields.get('spare_part_items'),
  PlayArea: playAreaSaveFields,
}

// Use model object item as it is and prepare it to post over Resource API
const filterModelSaveFields = (data) => {
  const saveFields = saveFieldsByModelName[data['@class']]
  if (!saveFields || Object.keys(saveFields).length < 1) {
    alert('Configure save fields for ' + data['@class'])
  }
  const updatedItem = {}
  Object.keys(saveFields).forEach(saveField => {
    if (!(saveField in data)) { return }
    const type = saveFields[saveField]
    if (type === 'has-many') {
      updatedItem[saveField] = data[saveField]
        .map(child => filterModelSaveFields(child))
    } else if (type === 'reference') {
      updatedItem[saveField] = data[saveField]
        ? {
          '@class': data[saveField]['@class'],
          id: data[saveField].id
        }
        : null
    } else if (type === 'array') {
      updatedItem[saveField] = data[saveField]
        ? data[saveField].map(item => {
          return {
            '@class': item['@class'],
            id: item.id,
          }
        })
        : null
    } else {
      updatedItem[saveField] = data[saveField]
    }
  })
  return updatedItem
}

export default {
  saveItem: ({ state, dispatch }, { item, model, parentItem, parentField, queries, showSaveMessage = false, eventAction }) => {
    return new Promise(resolve => {
      const filters = {}
      if (eventAction) {
        filters['~execute_event'] = eventAction
      }
      const updatedItem = filterModelSaveFields(item)
      const modelName = helper.modelSingularCamelCase(model)
      state['loading' + modelName] = true
      api.saveItem(model, updatedItem, queries, filters)
        .then(response => {
          dispatch('globalErrorDisplay', response)
          if (response && response.data && response.data.item) {
            if (showSaveMessage) {
              dispatch('addAppMessage', {
                type: 'success',
                message: i18n.t('general.saved'),
                hideCloseButton: true,
              })
            }
          }
          state['loading' + modelName] = false
          // In case saved object has 'images' array, updated images order
          api.saveItemImagesOrder(item).then(() => {
            resolve((response && response.data && response.data.item) || null)
          })
        })
    })
  },

  setItemAsRemoved: ({ state, dispatch }, { model, itemId, reason = null, queries = [] }) => {
    const saveItem = {
      id: itemId,
      removed_at: moment().format('YYYY-MM-DD HH:mm'),
      remover: {
        id: state.userInfo.person.id,
        '@class': 'Person',
      },
    }
    if (reason) {
      saveItem.remove_reason = reason
    }
    return new Promise(resolve => {
      api.saveItem(model, saveItem, queries).then(response => {
        dispatch('globalErrorDisplay', response)
        resolve(response)
      })
    })
  },

  getListItems: ({ dispatch, state }, { model, filters, queries }) => {
    return new Promise(resolve => {
      api.getListItems(helper.objectClassUnderscoredName(model), filters, queries, {}).then(result => {
        dispatch('globalErrorDisplay', result)
        if (result && result.items) {
          state[model + 'Items'] = result.items.map((item, index) => {
            // Complete list items with index property for v-data-table striped rows support with expanded slot
            // no support for that out of the box, and can't get index in item-class function
            item.index = index
            return item
          })
          state[model + 'ItemsTotal'] = result.total
          state[model + 'AllIds'] = result.ids || []
        }
        resolve(result)
      })
    })
  },

  addNewChildFor: ({ state, dispatch }, { forItem, childModel, queries = [] }) => {
    // Get default item with token
    return api.fetchTokenForItem(forItem, childModel, queries)
      .then(response => {
        let defaultItem = {}
        dispatch('globalErrorDisplay', response).then()
        if (response && response.data && response.data.item) {
          defaultItem = response.data.item
        }
        Vue.set(forItem[childModel], forItem[childModel].length, defaultItem)
      })
  },

  getFormItemOnDefaultsForChange: ({ dispatch, state }, {
    resource, targetResource, targetId, targetField, item, changedFieldName, queries,
  }) => {
    const updatedItem = filterModelSaveFields(item)
    return new Promise(resolve => {
      let url = '/api/' + resource + '/' + updatedItem.token + '/' + changedFieldName
      if (targetResource && targetId && targetField) {
        url = url + '/for/' + targetResource + '/' + targetId + '/' + targetField
      }
      api.sendPutWithPayloadRequest(url, queries, updatedItem).then(response => {
        dispatch('globalErrorDisplay', response)
        resolve(response)
      })
    })
  },
}
