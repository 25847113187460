export default {
  '@class': 'string',
  id: 'number',
  token: 'string',
  product_code: 'string',
  product_number: 'string',
  ifs_identifier: 'string',
  in_operation: 'string',
  name: 'string',
  installation_date: 'date',
  safe_zone_size: 'string',
  organization: 'reference',
  activity_park: 'reference',
  manufacturer: 'reference',
  product_instance_category: 'reference',
  product_instance_type: 'reference',
  standard: 'reference',
  safety_platform_type: 'reference',
  material: 'string',
  foundation: 'string',
  images: 'array',
  condition_score: 'number'
}
