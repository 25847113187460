var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"active-class":"elevation-3"}},[_c('v-expansion-panel-header',{staticClass:"h2"},[_vm._v(_vm._s(_vm.$i18n.t('activityParks.activityParkInfo').toUpperCase()))]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12","lg":"4"},on:{"click":function($event){return _vm.showActivityParkItemForEdit({
                    id: _vm.activityPark.id,
                    callback: _vm.getActivityParkInfo,
                  })}}},[(_vm.activityPark.images && _vm.activityPark.images[0])?_c('v-img',{staticClass:"ap-image",attrs:{"max-height":"280px","min-height":"280px","width":"100%","src":'/content/attachments/' +
                    _vm.activityPark.images[0].id +
                    '/' +
                    _vm.activityPark.images[0].filename}}):_vm._e(),(!_vm.readOnly)?_c('v-btn',{staticStyle:{"bottom":"30px","right":"30px","position":"absolute"},attrs:{"fab":"","x-large":""}},[_c('v-icon',[_vm._v(" fa-edit ")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('b',[_vm._v(_vm._s((_vm.activityPark.organization || {}).summary))]),_c('br'),_vm._v(" "+_vm._s(_vm.activityPark.contact_person_name)),_c('br'),_vm._v(" "+_vm._s(_vm.activityPark.contact_person_phone)),_c('br'),_vm._v(" "+_vm._s(_vm.activityPark.contact_person_email)),_c('br'),_c('br'),_c('b',[_vm._v(_vm._s(_vm.activityPark.name))]),_c('br'),_vm._v(" "+_vm._s(_vm.activityPark.name_extension)+" ")]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$i18n.t('activityParks.activityParkPlayAreas')))]),_c('br'),(
                    _vm.activityPark.play_areas &&
                    _vm.activityPark.play_areas.length > 1
                  )?_c('div',_vm._l((_vm.activityPark.play_areas),function(playArea){return _c('span',{key:playArea.description},[_c('b',[_vm._v(_vm._s(_vm.$i18n.t('playAreas.area'))+" "+_vm._s(playArea.number))]),_vm._v(": "+_vm._s(playArea.description)),_c('br')])}),0):_c('p',[_vm._v(_vm._s(_vm.$i18n.t('playAreas.onlyOne')))])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }