<template>
  <div class="list-item list-item-modal">
    <v-container fluid>
      <v-progress-linear
        v-if="productInstanceItemLoading"
        indeterminate
        color="grey"
        class="my-5"
      />
      <template v-else>
        <v-row>
          <v-col cols="10" class="ei-name pb-0">
            {{ item.activity_park && item.activity_park.name }}
          </v-col>
          <v-col cols=2 class="text-right pb-0">
            <v-icon @click="showProductInstancesShowModal = false">
              fa-times
            </v-icon>
          </v-col>
          <v-col class="pt-0 text-subtitle-1" cols="12">
            {{ item.activity_park && item.activity_park.address && item.activity_park.address.summary }}
          </v-col>
          <v-col cols="7" class="ei-menu">
            {{ item.name }}
          </v-col>
          <v-col cols="5">
            <v-btn
              height="30"
              block
              @click="edit"
            >
              <v-icon small class="mr-2">
                fa-edit
              </v-icon>
              {{ $i18n.t('productInstances.changeInfoTitle') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
            >
              <v-list>
                <v-list-item
                  :href="imageLinkForDownload"
                  download
                >
                  <v-list-item-title>{{ $i18n.t('general.downloadImage') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-carousel
              :hide-delimiters="item.images.length < 2"
              :show-arrows="item.images.length > 1"
              height="600"
            >
              <v-carousel-item
                v-for="(item, i) in item.images"
                :key="i"
                :src="'/content/attachments/' + item.id + '/' + item.filename"
                :href="'/content/attachments/' + item.id + '/' + item.filename"
                target="_blank"
                contain
                @contextmenu="showImageDownloadMenu($event, '/content/attachments/' + item.id + '/' + item.filename)"
              />
            </v-carousel>
          </v-col>
          <v-col cols="6">
            <v-container>
              <v-row>
                <v-col cols="12" class="mt-3" style="background: #f8f8f8;">
                  <b>{{ $i18n.t('productInstances.equipment') }}:</b> {{ item.activity_park && item.activity_park.summary }}<br>
                  <b>{{ $i18n.t('productInstances.installationDate') }}:</b> {{ formatDate(item.installation_date) }}<br>
                  <b>{{ $i18n.t('productInstances.playValue') }}:</b> {{ item.play_value !== null ? Math.round(item.play_value * 10) / 10 : '-' }}<br>
                  <b>{{ $i18n.t('productInstances.conditionScore') }}:</b>&nbsp;
                  <span v-if="item.condition_score !== null">
                  <v-icon v-for="rating in [1, 2, 3, 4, 5]" :key="'rating-' + rating" small style="padding-bottom: 2px">
                    {{ item.condition_score >= rating ? 'fas fa-star' : 'far fa-star' }}
                  </v-icon>
                  </span>
                  <span v-else>{{ $i18n.t('productInstances.conditionScoreNotSet') }}</span>
                </v-col>
                <v-col cols="12">
                  <b>{{ $i18n.t('productInstances.product_instance_category') }}:</b> {{ item.product_instance_category && item.product_instance_category.summary }}<br>
                  <b>{{ $i18n.t('productInstances.product_instance_type') }}:</b> {{ item.product_instance_type && item.product_instance_type.summary }}<br>
                  <b>{{ $i18n.t('productInstances.standard') }}:</b> {{ item.standard && item.standard.summary }}
                </v-col>
                <v-col cols="12" style="background: #f8f8f8;">
                  <b>{{ $i18n.t('productInstances.manufacturer') }}:</b> {{ item.manufacturer && item.manufacturer.summary }}<br>
                  <b>{{ $i18n.t('productInstances.productCode') }}:</b> {{ item.product_code }}<br>
                  <b>{{ $i18n.t('productInstances.productNumber') }}:</b> {{ item.product_number }}
                </v-col>
                <v-col cols="12">
                  <b>{{ $i18n.t('productInstances.material.title') }}:</b> {{ item.material ? $i18n.t('productInstances.material.' + item.material) : '' }}<br>
                  <b>{{ $i18n.t('productInstances.foundation.title') }}:</b> {{ item.foundation ? $i18n.t('productInstances.foundation.' + item.foundation) : '' }}<br>
                  <b>{{ $i18n.t('productInstances.safety_platform_type') }}:</b> {{ item.safety_platform_type && item.safety_platform_type.summary }}<br>
                  <b>{{ $i18n.t('productInstances.safe_zone_size') }}:</b> {{ item.safe_zone_size || '-' }} m&#xb2;<br>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
import helper from '@/helper'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ProductInstanceInfo',

  data () {
    return {
      showMenu: false,
      imageLinkForDownload: null,
      x: 0,
      y: 0,
    }
  },

  props: {
    isInspection: {
      type: Boolean,
      default: false,
    },
    loadInspectionProductInstances: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    item () {
      return this.productInstanceItemForShow
    },
  },

  methods: {
    ...allMethods,

    edit () {
      this.showProductInstanceEditModal(this.item.id, {
        callback: this.changeCallbackFunction(),
      })
    },

    // Callback function depending on whether list is shown in the main module or inside inspection flow
    changeCallbackFunction () {
      return this.isInspection ? this.loadInspectionProductInstances : this.getListItems
    },

    formatDate (date) {
      return helper.formatDate(date)
    },
  },
}
</script>

<style lang="scss">
.list-item-modal {
  color: #666;
  border-radius: 0;
  padding: 20px;
}
</style>
