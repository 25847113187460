<template>
  <v-toolbar
    height="40"
    color="#f7f7f7"
    class="elevation-0 pagination-bottom-toolbar"
  >
    <v-spacer />
    <v-pagination
      v-model="page"
      class="my-4"
      circle
      color="#8cc477"
      :length="totalPages"
      :total-visible="10"
      light
    />
    <v-spacer />
  </v-toolbar>
</template>

<script>
export default {
  name: 'ListBottomPagination',

  props: {
    value: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    page: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.pagination-bottom-toolbar {
  margin: 30px 0;
  background: transparent !important;
  .v-pagination__navigation {
    box-shadow: none;
    background: transparent !important;
    i {
      font-size: 16px;
    }
  }
  .v-pagination__item {
    box-shadow: none;
    font-weight: 500;
  }
  .v-pagination__item:not(.v-pagination__item--active) {
    background: transparent !important;
  }
}
</style>
