import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './i18nMessagesEn'
import fi from './i18nMessagesFi'
import se from './i18nMessagesSe'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  messages: {
    en, fi, se
  },
})
