<template>
  <v-container
    style="max-width: 1600px;"
    class="body-container pa-0 elevation-3"
    fluid
  >
    <ListSearchToolbar model="productInstances" />
    <ListTitleToolbar model="productInstances" />
    <ProductInstancesList />
    <v-dialog
      max-width="1200"
      v-model="showProductInstancesShowModal"
    >
      <ProductInstanceInfo />
    </v-dialog>
    <v-dialog
      width="1200"
      v-model="showProductInstancesSearchForm"
    >
      <ProductInstancesSearchForm />
    </v-dialog>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import ListSearchToolbar from '@/components/Shared/ListSearchToolbar'
import ListTitleToolbar from '@/components/Shared/ListTitleToolbar'
import ProductInstancesList from '@/components/ProductInstances/ProductInstancesList'
import ProductInstanceInfo from '@/components/ProductInstances/ProductInstanceInfo'
import ProductInstancesSearchForm from '@/components/ProductInstances/ProductInstancesSearchForm'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ProductInstances',

  data () {
    return {
      model: 'productInstances',
    }
  },

  components: {
    ProductInstancesSearchForm,
    ProductInstanceInfo,
    ProductInstancesList,
    ListTitleToolbar,
    ListSearchToolbar,
  },

  watch: {
    productInstancesListOptionsTrigger () {
      this.getListItems()
    },
  },

  created () {
    this.productInstancesListOptions.page = 1
    this.getListItems()
  },

  computed: {
    ...mapFields(Object.keys(state)),

    productInstancesListOptionsTrigger () {
      return JSON.stringify(this.productInstancesListOptions)
    },
  },

  methods: {
    ...allMethods,
  },
}
</script>
