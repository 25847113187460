<template>
  <v-container class="mt-3 pa-0">
    <span class="small grey-text pl-5">
      <router-link to="/">
        {{ $i18n.t('general.home') }} / {{ $i18n.t('home.works') }}
      </router-link> / <b>{{ $i18n.t('inspection.reportingInspectionWork') }}</b>
    </span>
    <v-container v-if="!$store.state.inspection.work || !$store.state.inspection.work.id" class="white">
      <v-row>
        <v-col>
          <h2>{{ $i18n.t('inspection.noWorkSelectedMessage') }}</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else class="white">
      <v-row>
        <v-col cols="12" xl="4" class="h1 align-self-center">
          {{ $i18n.t('inspection.createInspectionReportForActivityPark').toUpperCase() }}
        </v-col>
        <v-col
          cols="12" xl="4" class="text-center h3 align-self-center pa-0"
        >
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="text-right">
                <v-btn
                  v-if="step > 1"
                  class="mr-5"
                  text
                  fab
                  @click="prevStep()"
                >
                  <v-icon>fa-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col class="text-center align-self-center">
                <span class="px-4">{{ step }} / 4</span>
              </v-col>
              <v-col class="text-left">
                <v-btn
                  v-if="step < 4"
                  :disabled="nextStepDisabled"
                  class="ml-5"
                  text
                  fab
                  @click="nextStep()"
                >
                  <v-icon>fa-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" class="px-0">
          <InspectionStep1
            v-if="step === 1"
          />
          <InspectionStep2
            v-else-if="step === 2"
          />
          <InspectionStep3
            v-else-if="step === 3"
          />
          <InspectionStep4
            v-else-if="step === 4"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn
            v-if="step > 1"
            rounded
            dark
            color="#aaa"
            class="mr-5 elevation-0"
            @click="prevStep()"
          >
            <v-icon class="mr-2">fa-chevron-left</v-icon>
            {{ $i18n.t('general.previous') }}
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="2">
          <span class="mx-5">{{ step }} / 4</span>
        </v-col>
        <v-col class="text-left">
          <v-btn
            v-if="step < 4"
            :disabled="nextStepDisabled"
            rounded
            :dark="!nextStepDisabled"
            color="#aaa"
            class="ml-5 elevation-0"
            @click="nextStep()"
          >
            {{ $i18n.t('general.next') }}
            <v-icon class="ml-2">fa-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import InspectionStep1 from '@/components/Inspection/Steps/InspectionStep1'
import InspectionStep2 from '@/components/Inspection/Steps/InspectionStep2'
import InspectionStep3 from '@/components/Inspection/Steps/InspectionStep3'
import InspectionStep4 from '@/components/Inspection/Steps/InspectionStep4'

export default {
  name: 'Inspection',

  data () {
    return {
      step: 1,
    }
  },

  components: {
    InspectionStep1,
    InspectionStep2,
    InspectionStep3,
    InspectionStep4,
  },

  computed: {
    nextStepDisabled () {
      const selectedTasks = Object.keys(this.$store.state.inspection.tasks).filter(taskKey => this.$store.state.inspection.tasks[taskKey])
      return this.step === 2 && selectedTasks.length === 0 && !this.$store.state.inspection.noTasksDone
    }
  },

  methods: {
    prevStep () {
      this.step--
      if (this.step < 1) { this.step = 1 }
      window.scrollTo(0, 0)
    },
    nextStep () {
      this.step++
      if (this.step > 4) { this.step = 4 }
      window.scrollTo(0, 0)
    },
  },
}
</script>
