import axios from 'axios'
import store from '@/store'
import Raven from 'raven-js'
import qs from 'qs'
import helper from '@/helper'
import i18n from '@/locale/i18n'

const getFiltersQuery = (filters) => {
  return !Array.isArray(filters)
    ? Object.keys(filters).map(key => key + '=' + filters[key])
      .join('&')
    : filters.map(filter => filter.key + '=' + filter.value
    ).join('&')
}

export default {
  sendPostRequest (apiUrl, parameters) {
    return new Promise((resolve) => {
      const filtersQuery = getFiltersQuery(parameters)
      const payloadParameters = new URLSearchParams()
      if (Array.isArray(parameters)) {
        parameters.forEach((parameter) => {
          payloadParameters.append(parameter.key, parameter.value)
        })
      } else {
        Object.keys(parameters).forEach(key => {
          payloadParameters.append(key, parameters[key])
        })
      }
      axios.post(apiUrl,
        payloadParameters, this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response.data)
        }
        resolve({})
      }).catch((error) => {
        resolve(error.response)
      })
    })
  },

  sendPostRequestForMultiEdit (apiUrl, data) {
    return new Promise((resolve) => {
      const formData = {}
      data.forEach(parameter => {
        formData[parameter.key] = parameter.value
      })
      axios.post(apiUrl,
        formData, this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response.data)
        }
        resolve({})
      }).catch((error) => {
        resolve(error.response)
      })
    })
  },

  sendPostRequestFormData (apiUrl, data) {
    return new Promise((resolve) => {
      // const formData = new URLSearchParams()
      const formData = qs.stringify(data)
      // data.forEach(parameter => {
      // formData.append(parameter.key, parameter.value)
      // })
      axios.post(apiUrl,
        formData, this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response.data)
        }
        resolve({})
      }).catch((error) => {
        resolve(error.response)
      })
    })
  },

  // Sort images in API response.
  // For images there is a field 'order', which front-end can use to sort images
  // Actual sorting is not done by the API, so we do it here globally for all API calls
  sendRequestAndSortImages (apiUrl, filters, queries) {
    return new Promise(resolve => {
      this.sendRequest(apiUrl, filters, queries)
        .then(response => {
          if (response && response.item && 'images' in response.item) {
            helper.sortItemImages(response.item)
          } else if (response && response.items && response.items[0] && 'images' in response.items[0]) {
            response.items.forEach(item => {
              helper.sortItemImages(item)
            })
          }
          resolve(response)
        })
    })
  },

  sendRequest (apiUrl, filters, queries) {
    return new Promise(resolve => {
      const filtersQuery = getFiltersQuery(filters)
      // A bit cacky but /new-endpoint doesn't allow locale for some
      if (apiUrl.includes('/new')) {
        apiUrl += '?'
      } else {
        apiUrl += `?locale=${i18n.locale}&`
      }
      console.log(apiUrl)
      console.log('--------------------------')
      const url = apiUrl +
        filtersQuery + '&' +
        queries.map(query => 'q[]=' + query).join('&')

      axios.get(url, this.requestOptions()).then(response => {
        if (response && response.data) {
          resolve(response.data)
          return
        }
        Raven.captureMessage('API no response data', {
          level: 'error',
          extra: {
            apiUrl,
            filters,
            queries,
            response,
          }
        })
        resolve(response)
      }).catch(error => {
        Raven.captureMessage('API error captured', {
          level: 'error',
          extra: {
            apiUrl,
            filters,
            queries,
            error,
          }
        })
        // 30.01.21 removed && error.response.data
        // otherwise global error response does not get detailed error info
        resolve(error && error.response)
      })
    })
  },

  requestOptions () {
    const acceptLanguage = store.state.locale
    return {
      headers: {
        'Auth-Required': true,
        'Accept-Language': acceptLanguage,
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  },

  sendSimplePostRequest (apiUrl, parameters) {
    return new Promise((resolve) => {
      axios.post(apiUrl,
        parameters, this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response.data)
        }
        resolve({})
      }).catch((error) => {
        resolve(error.response)
      })
    })
  },

  sendSimplePutRequest (apiUrl, parameters) {
    return new Promise((resolve) => {
      axios.put(apiUrl, parameters, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve({})
        }).catch((error) => {
          resolve(error.response)
        })
    })
  },

  sendPutWithPayloadRequest (apiUrl, queries, parameters) {
    return new Promise((resolve) => {
      let payloadParameters = {}
      if (Array.isArray(parameters)) {
        parameters.forEach((parameter) => {
          payloadParameters[parameter.key] = parameter.value
        })
      } else {
        payloadParameters = parameters
      }
      const url = apiUrl + '?' + queries
        .map(query => typeof query === 'string'
          ? 'q[]=' + query
          : query.key + '=' + query.value
        )
        .join('&')
      axios.put(url, payloadParameters, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve({})
        }).catch((error) => {
          resolve(error.response)
        })
    })
  },

  sendPutRequest (apiUrl, queries, parameters) {
    return new Promise((resolve) => {
      const payloadParameters = new URLSearchParams()
      parameters.forEach((parameter) => {
        payloadParameters.append(parameter.key, parameter.value)
      })
      const url = apiUrl + '?' + queries.map(query => query.key + '=' + query.value).join('&')
      axios.put(url, payloadParameters, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve({})
        }).catch((error) => {
          resolve(error.response)
        })
    })
  },

  sendDeleteRequest (apiUrl, parameters) {
    return new Promise((resolve) => {
      const url = apiUrl + '?' + parameters.map(parameter => parameter.key + '=' + parameter.value).join('&')
      axios.delete(url)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve({})
        }).catch((error) => {
          resolve(error.response)
        })
    })
  },
}
