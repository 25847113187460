const shared = [
  '@editable_members',
  '@observed_members',
  '@member_styles',
  'quantity',
  'quantity_unit name',
  'token',
]

const byModelName = {
  work_cost_items: [
    'cost_type',
    'catalog_no',
    'description',
    'quantity',
    'quantity_unit name',
    'unit_sales_price',
    'unit_sales_price_currency identifier',
    'total_amount',
    'total_amount_currency identifier',
  ],
  spare_part_items: [
    'spare_part summary',
  ],
  expense_items: [
    'expense summary',
  ],
}

export default {
  get: (modelName) => {
    return [...shared, ...byModelName[modelName]]
  },
}
