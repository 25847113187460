import Vue from 'vue'
import VueRouter from 'vue-router'
import ProductInstances from '@/components/ProductInstances/ProductInstances'
import Works from '@/components/Works/Works'
import WorkOrders from '@/components/WorkOrders/WorkOrders'
import Home from '@/components/Home/Home'
import ShoppingCart from '@/components/ShoppingCart/ShoppingCart'
import ActivityParks from '@/components/ActivityParks/ActivityParks'
import Organizations from '@/components/Organizations/Organizations'
import Inspection from '@/components/Inspection/Inspection'
import Ratings from '@/components/Ratings/Ratings'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: ShoppingCart,
  },
  {
    path: '/inspection',
    name: 'Inspection',
    component: Inspection,
  },
  {
    path: '/work_orders',
    name: 'WorkOrders',
    component: WorkOrders,
  },
  {
    path: '/equipments',
    name: 'ProductInstances',
    component: ProductInstances,
  },
  {
    path: '/works',
    name: 'Works',
    component: Works,
  },
  {
    path: '/parks',
    name: 'ActivityParks',
    component: ActivityParks,
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
  },
  {
    path: '/ratings',
    name: 'Ratings',
    component: Ratings,
  },
]

const router = new VueRouter({
  routes
})

export default router
