<template>
  <v-dialog
    v-model="showDialog"
    width="1000"
  >
    <v-container class="dialog-container">
      <v-row class="header-row">
        <v-col
          v-if="!activityParkEditModalProps.imagesOnly"
          cols="10" class="h1"
        >
          {{ $i18n.t('activityParks.changeInfoTitle').toUpperCase() }}
        </v-col>

        <v-col
          :cols="activityParkEditModalProps.imagesOnly ? 12 : 2"
          class="text-right ma-0 pa-0"
        >
          <v-btn small fab text @click="showDialog = false">
            <v-icon >
              fa-times
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="loading"
        class="content-row mx-0"
      >
        <v-col>
          <v-progress-linear
            indeterminate
            color="grey"
            class="my-5"
          />
        </v-col>
      </v-row>
      <template v-else-if="item">
        <v-row class="content-row">
          <v-col>
            <v-container
              fluid class="search-form"
              :style="{ height: dialogHeight + 'px' }"
            >
              <v-row>
                <template v-if="!activityParkEditModalProps.imagesOnly">
                  <v-col cols="12">
                    <b>{{ $i18n.t('activityParks.activityPark') }}:</b>
                    {{ item.name_extension }}
                  </v-col>
                  <v-col cols="12">
                    <b>{{ $i18n.t('general.address') }}:</b>
                    {{ item.address && item.address.summary }}
                  </v-col>
                </template>

                <v-col cols="12">
                  <ImagesEdit
                    v-model="item.images"
                    for-field-name="images"
                    :parent="item"
                    :images-ready="imagesReady"
                    v-on:ready="imagesReady = arguments[0]"
                  />
                </v-col>

                <template v-if="!activityParkEditModalProps.imagesOnly">
                  <v-col cols="12">
                    <b>{{ $i18n.t('general.organization') }}:</b>
                    {{ item.organization && item.organization.summary }}
                  </v-col>
                  <v-col cols="12">
                    <b>{{ $i18n.t('activityParks.organizationPerson') }}:</b>
                    {{ item.contact_person_name }}
                  </v-col>

                  <v-col cols="12"><div class="h-spacer"/></v-col>

                  <v-col cols="12">
                    <FormSelectField
                      :item="item"
                      :label="$i18n.t('activityParks.activity_park_type')"
                      search-class="activity_park_types"
                      model="activity_parks"
                      field-name="activity_park_type"
                      :style-attributes="textFieldFormatAttributes"
                    />
                  </v-col>
                  <v-col cols="12">
                   <PlayAreas
                    :activity-park="item"
                   />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="buttons-row">
          <v-col cols="6">
            <v-btn
              color="#8cc477"
              :dark="!disabled"
              :disabled="disabled"
              v-bind="popupButtonFormatAttributes"
              @click="save"
            >
              {{ $i18n.t('general.saveChanges') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="#666"
              block
              outlined
              v-bind="popupButtonFormatAttributes"
              @click="showDialog = false"
            >
              {{ $i18n.t('general.cancelAndCloseForm') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'
import ImagesEdit from '@/components/Shared/ImagesEdit'
import api from '@/store/api'
import helper from '@/helper'
import FormSelectField from '@/components/Shared/FormSelectField'
import PlayAreas from '@/components/ActivityParks/PlayAreas'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParkEdit',

  data () {
    return {
      item: {},
      loading: false,
      imagesReady: true,
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  components: { PlayAreas, FormSelectField, ImagesEdit },

  computed: {
    ...mapFields(Object.keys(state)),

    disabled () {
      return !this.imagesReady
    },

    id () {
      return this.activityParkEditModalProps.id
    },

    showDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  created () {
    this.loading = true
    const queries = activityParksQueries.forEdit
    api.getItemInfoForEdit('activity_parks', this.id, queries, {})
      .then(response => {
        this.$store.dispatch('globalErrorDisplay', response).then(noErrors => {
          if (!noErrors) {
            // Set Activity Park if form open from Activity Parks list
            if (response && response.item) {
              this.item = response.item
            }
          } else {
            this.showDialog = false
          }
          this.loading = false
        })
      })
  },

  methods: {
    save () {
      this.loading = true
      this.activityParkItemLoading = true
      this.$store.dispatch('saveItem', {
        model: 'activity_parks',
        item: this.item,
        queries: activityParksQueries.forList,
        showSaveMessage: true,
      }).then(savedItem => {
        this.loading = false
        if (savedItem && savedItem.id) {
          this.showDialog = false
          this.activityParkEditModalProps.callback(savedItem)
        }
      })
    },
  },
}
</script>
