export default {
  app: {
    attachment: {
      error: {
        media_type_mismatch: 'Wrong media type'
      },
    },
  },
  general: {
    actions: 'Toiminnot',
    add: 'Lisää',
    addNewFile: 'Lisää tiedosto',
    addPicture: 'Lisää kuva',
    address: 'Osoite',
    all: 'Kaikki',
    cancelAndCloseForm: 'Peruuta',
    city: 'Kaupunki',
    clearSearch: 'Tyhjennä haku',
    clock: 'Klo.',
    close: 'Sulje',
    county: 'Maakunta',
    detailedSearch: 'Rajaa hakua',
    downloadImage: 'Lataa kuva',
    expandAll: 'Avaa kaikki',
    feedback: 'Palaute',
    files: 'Tiedostot',
    home: 'Etusivu',
    hours: 'Tunnit',
    loading: 'Ladataan..',
    logout: 'Kirjaudu ulos',
    minutes: 'Minuutit',
    month_placeholder: 'kk',
    name: 'Nimi',
    next: 'Seuraava',
    no_items_found: 'Ei osumia',
    objectState: 'Tila',
    organization: 'Asiakas',
    page: 'Sivu',
    pleaseFillAllRequiredFields: 'Please fill all required fields',
    pressEnterForMultiple: 'Paina ENTER lisätäksesi useita',
    previous: 'Edellinen',
    quantity: 'Kpl',
    save: 'Tallenna',
    saveChanges: 'Tallenna muutokset',
    saved: 'Tallennettu',
    search: 'Hae',
    searchByName: 'Etsi nimellä',
    searchExplanation: 'Täytä yksi tai useampi hakuehto',
    searching: 'Etsitään...',
    searchResults: 'Hakutulokset',
    searchTerm: 'Hakusana',
    searchText: 'Etsi...',
    send: 'Lähetä',
    showSearchResults: 'Näytä hakutulokset',
    userManual: 'Käyttöohje',
    year_placeholder: 'vvvv',
  },
  home: {
    aligned: 'Kohdistettu',
    allAligned: 'Kohdistetut',
    allUnAligned: 'Kohdistamattomat',
    announcements: 'Tiedotteet',
    events: 'Tapahtumat',
    inspected: 'Tarkastettu',
    inspectionWorks: 'Työtilaukset',
    lastLoginDate: 'Käytit palvelua viimeksi',
    latestInspections: 'Viimeisimmät tarkastukset',
    reserveWork: 'Varaa työ',
    startInspection: 'Aloita tarkastus',
    unaligned: 'Kohdistamaton',
    welcome: 'Tervetuloa',
    works: 'Viimeisimmät työt',
  },
  user: {
    address: 'Katuosoite',
    changePassword: 'Vaihda salasana',
    email: 'Sähköposti',
    firstName: 'Etunimi',
    index: 'Postinumero',
    lastName: 'Sukunimi',
    myInfo: 'Omat tiedot',
    newPassword: 'Uusi salasana',
    newPassword2: 'Vahvista uusi salasana',
    phone: 'Puhelinnumero',
    state: 'Postitoimipaikka',
    organization: 'Organisaatiot',
  },
  change_password: {
    change_is_mandatory: 'Uusi salasana ei saa olla sama uin vanha salasana',
    fields_are_mandatory: 'Kaikki kentät tulee täyttää',
    invalid_password: 'Väärä salasana',
    new_password_again: 'Vahvista uusi salasanasi',
    new_password: 'Uusi salasanasi',
    not_authenticated: 'Vaihtaaksesi salasanasi kirjaudu ensin sisään',
    old_password: 'Nykyinen salasanasi',
    password_change_failed: 'Salasanan vaihto epäonnistui. Salasanan tulee olla vähintään 8 merkkiä pitkä',
    password_changed: 'Salasanan vaihto onnistui',
    password_expired: 'Salasanasi on vanhentunut. Aseta uusi salasana',
    passwords_dont_match: 'Salasana ja salasanan vahvistus eivät ole samoja',
    submit: 'Lähetä',
    title: 'Vaihda salasana',
  },
  inspection: {
    activeWorksTitle: 'Kohteen aktiiviset vikailmoitukset',
    addNewAdditionalWork: 'Lisää uusi lisätyö',
    addNewWork: 'Lisää uusi vikailmoitus',
    createInspectionReportForActivityPark: 'Suorita tarkastustyö kohteelle',
    extraWorksTitle: 'Lisätyöt',
    finish: 'Tallenna ja lopeta tarkastus',
    followUpWorkDescription: 'Pitkän tähtäimen suunnitelma',
    followUpWorkDescriptionText: 'Yleisarvio kohteesta ja kohteen kunnosta...',
    followUpWorkTitle: 'Pitkän tähtäimen suunnitelma',
    maintenanceTitle: 'Rutiinihuolto',
    maintenanceTitleText: 'Suorita rutiinihuolto kohteelle',
    noMaintenanceTasks: 'Kohteelle ei suoriteta rutiinihuoltoa',
    noWorkSelectedMessage: 'Valitse ensin työ etusivulta',
    productInstancesTitle: 'Välineet',
    reportingInspectionWork: 'Tarkastustyön suorittaminen',
    summaryDescription: 'Kirjaa tarkastuksen lisätiedot',
    summaryDescriptionText: 'Tarkastuksen lisätiedot lyhyesti (max. 60 merkkiä)',
    summaryTimeSpentText: 'Raportoi tarvittaessa työhön käytetty aika',
    summaryTimeSpentTitle: 'Työhön käytetty aika',
    taskErrorDescription: 'Rutiinihuolto',
    taskWorkDescriptionPrefix: 'Rutiinihuolto suoritettu:',
    tasks: {
      task1: 'Turva-alustojen tasaaminen',
      task2: 'Valettujen turva-alustojen ja turvalaattojen harjaus',
      task3: 'Hiekkalaatikon reunojen harjaaminen',
      task4: 'Muiden tasojen harjaaminen',
      task5: 'Kiinnikkeiden kiristäminen',
      task6: 'Keinutelineiden laakereiden rasvaaminen',
      task7: 'Keinuistuimen korkeudensäätö',
      task8: 'Ylimääräisten ketjujen poistaminen keinuistuimista',
      task9: 'Putoamisalueiden siistiminen',
    },
  },
  ratings: {
    title: 'Pisteet',
    conditionRating: 'Yleiskunto',
    productRating: 'Leikkiarvo',
    riskRating: 'Riskiluokitus',
  },
  organizations: {
    chooseOne: 'Valitse yksi organisaatio',
    country: 'Maa',
    createdAt: 'Luotu',
    language: 'Kieli',
    listEmpty: 'Organisaatioita ei löytynyt',
    loading: 'Haetaan organisaatioita..',
    name: 'Nimi',
    title: 'Organisaatiot',
  },
  activityParks: {
    activity_park_areas: 'Alueet',
    activity_park_type: 'Kohdetyyppi',
    activityPark: 'Kohde',
    activityParkInfo: 'Kohteen tiedot',
    activityParkPlayAreas: 'Kohteen alueet',
    activityParkType: 'Kohdetyyppi',
    addNewEquipment: 'Lisää väline',
    addNewWork: 'Lisää vikailmoitus',
    changeInfoTitle: 'Muuta kohteen tietoja',
    commentSaved: 'Palaute lähetetty!',
    daysAgo: 'pv sitten',
    deleteButton: 'Poista kohde',
    deleteReason: 'Poiston syy',
    deleteTitle: ' Poista kohde',
    giveFeedback: 'Anna palautetta',
    hide: 'Piilota',
    hideSuccess: 'Kohde piilotettu!',
    inspectedInLastYear: 'Kohde on tarkastettu viimeisen vuoden aikana',
    inspection: 'Tarkastettu',
    inspectionReport: 'Tarkastusraportti',
    long_term_plans: 'Pitkän tähtäimen suunnitelma',
    orderedWorks: 'Tilatut työt',
    organizationPerson: 'Yhteyshenkilö',
    play_areas: 'Leikkialueet',
    search: 'Etsi nimellä',
    selectContactPerson: 'Kohteen yhteyshenkilö',
    title: 'Kohteet',
    work_priority: 'Prioriteetti',
    deleteConfirm: 'Haluatko varmasti poistaa kohteet?',
  },
  playAreas: {
    area: 'Alue',
    description: 'Teksti',
    number: 'Numero',
    onlyOne: 'Kohteella on yksi alue',
  },
  productInstances: {
    addNewEquipment: 'Lisää uusi väline',
    addNewEquipmentFor: 'Lisää uusi väline kohteelle',
    changeInfoTitle: 'Muokkaa välineen tietoja',
    conditionScore: 'Kuntopisteet',
    conditionScoreNotSet: 'Ei määritetty',
    deleteConfirm: 'Haluatko varmasti poistaa välineen?',
    deleteTitle: ' Poista väline',
    equipment: 'Väline',
    installationDate: 'Asennuspäivä',
    manufacturer: 'Välinevalmistaja',
    playValue: 'Leikkiarvo',
    product_instance_category: 'Välinekategoria',
    product_instance_type: 'Välinetyyppi',
    product_instance_type_rating: 'Välinetyypin leikkiarvo',
    productCode: 'Tuotekoodi',
    productNumber: 'Tuotenumero',
    safe_zone_size: 'Turva-alustan määrä',
    safety_platform_type: 'Turva-alustan tyyppi',
    searchEquipmentInfoTitle: 'Välineen tiedot',
    searchOrganizationInfoTitle: 'Organisaation tiedot',
    standard: 'Standardi',
    title: 'Välineet',
    type: 'Välinetyyppi',
    foundation: {
      deep: 'Syväperustus',
      surface: 'Pintaperustus',
      title: 'Perustustapa',
      wood: 'Puuperustus',
      free_standing: 'Irtonainen',
    },
    material: {
      metal: 'Metalli',
      plastic: 'Muovi',
      title: 'Materiaali',
      wood: 'Puu',
      rubber_granulate: 'Kumirouhe',
      hpl_laminate: 'HPL-laminaatti',
    },
    product_code: {
      lappset: 'LAPPSET',
      product: 'TUOTE',
    },
  },
  workOrders: {
    cost: 'Hinta',
    listEmpty: 'Tilauksia ei löytynyt',
    loading: 'Haetaan tilauksia..',
    mainDateShort: 'Pvm',
    orderer: 'Tilaaja',
    orderNumber: 'Tilausnro',
    title: 'Tilaukset',
  },
  works: {
    activityPark: 'Kohde',
    addNewWork: 'Lisää uusi työ',
    addNewWorkFor: 'Lisää uusi työ kohteelle',
    addPictureInfoText: 'Lisää kuva vioittuneesta välineestä',
    chooseExpense: 'Valitse kulutyyppi',
    chooseSparePart: 'Valitse varaosa varastosta',
    cost_type: 'Kustannustyyppi',
    cost: 'Hinta',
    deleteConfirm: 'Haluatko varmasti poistaa työn?',
    deleteTitle: 'Poista Työ',
    description: 'Kuvaus',
    equipmentText: 'Valitse Väline',
    editOldWork: 'Muokkaa työtä',
    errorDescription: 'Vikakuvaus',
    errorDescriptionText: 'Tai kirjoita vikakuvaus..',
    expense_items: 'Kulut',
    faultDescriptionText: 'Valitse vikakuvaus',
    inspection_description: 'Tehty työ ja lisätiedot',
    inspectionDescriptionText: 'Kirjaa suoritetut toimenpiteet ja lisätiedot. Max. 60 merkkiä.',
    listEmpty: 'Töitä ei löytynyt',
    loading: 'Haetaan töitä..',
    mainDateShort: 'Pvm',
    organization: 'Organisaatio',
    priority: 'Luokka',
    productInstanceType: 'Välinetyyppi',
    pts: 'PTS',
    searchActivityParkInfo: 'Kohteen tiedot',
    searchModalText: 'Täytä yksi tai useampi hakuehto',
    searchModalWorkInfo: 'Vikailmoituksen tiedot',
    searchOrganizationInfoTitle: 'Organisaation tiedot',
    selectOrganizationPerson: 'Organisaation vastuuhenkilö',
    selectWorkType: 'Valitse työtyyppi',
    spare_part_items: 'Varaosat',
    standard: 'Viittaus standardiin',
    standardText: 'Valitse standardi',
    takeWorkConfirmation: 'Oletko varma että haluat varata työn kohtelle',
    title: 'Vikailmoitukset',
    toggleWorkInReport_false: 'Piilotettu vikaraportilta',
    toggleWorkInReport_true: 'Näytetään vikaraportilla',
    tooMany: 'Liian monta vikailmoitusta valittuna (yli 1000)',
    work_cost_items: 'Kustannusrivit',
    workDescription: 'Toimenpiteet',
    workDescriptionText: 'Kirjaa suositellut toimenpiteet',
    workDescriptionTextForExtra: 'Kirjaa vaaditut toimenpiteet',
    workNumber: 'Työnro',
    workPriority: 'Vikaluokka',
    workState: 'Työn tila',
    workType: 'Työtyyppi',
    zoneSelectsTitle: 'Rajaa alueen mukaan',
    removeReason: {
      button: 'Poista työ',
      title: 'Poiston syy',
      work_dismantled: 'Väline on purettu',
      work_fixed: 'Vika on korjattu',
      work_removed: 'Vika on poistunut',
      work_unnecessary: 'Vikailmoitus on tarpeeton',
      work_updated: 'Vikailmoitus on päivitetty',
    },
    cost_types: {
      external: 'Ulkoinen',
      fixed_price: 'Kiinteä hinta',
      material: 'Materiaali',
      personnel: 'Henkilöstö',
    },
  },
  workCostItems: {
    cost_type: 'Kustannustyyppi',
    catalog_no: 'Tuotenumero',
    description: 'Kuvaus',
    quantity: 'Määrä',
    unit_sales_price: 'Yksikköhinta',
    total_amount: 'Kokonaishinta',
  },
  shoppingCart: {
    cost: 'Kustannus',
    empty: 'Ostoskori on tyhjä',
    extraInfo: 'Lisätietoja',
    offerExtraInfo: 'Lisätietoja tarjouspyynnöstä',
    offerIsSent: 'Tarjouspyyntösi on lähetty',
    offerThankYouMessage: 'Olemme teihin pian yhteydessä!',
    orderExtraInfo: 'Lisätietoja tilauksesta',
    orderIsSent: 'Tilauksesi on lähetetty',
    orderThankYouMessage: 'Kiitos tilauksesta!',
    sendOffer: 'Lähetä tarjouspyyntö',
    sendOrder: 'Lähetä tilaus',
    title: 'Ostoskori',
  },
  privacy_policy_modal: {
    title: 'Hyväksy tietosuojaseloste ja evästekäytäntö',
    button: 'Hyväksy',
    privacy_policy_text: 'Lue tietosuojaseloste täältä:',
    privacy_policy_link: 'Tietosuojaseloste',
    cookie_policy_text: 'Lue evästekäytäntö täältä:',
    cookie_policy_link: 'Evästekäytäntö',
  },
  contact: {
    address: 'Torpparinmäentie 4, 00690 Helsinki',
    email: 'playcare@lappset.com',
    phone: '+358 207 750 150',
    title: 'Yhteystiedot',
    menu1: {
      title: 'Palvelut',
      link1: {
        name: 'Asennus',
        url: 'https://www.playcare.fi/palvelut/Leikki--ja-liikuntapaikan-asennus',
      },
      link2: {
        name: 'Tarkastus',
        url: 'https://www.playcare.fi/palvelut/Leikkipaikan-tarkastus',
      },
      link3: {
        name: 'Huolto  ja varaosat',
        url: 'https://www.playcare.fi/palvelut/Leikkipaikan-ja-liikunta-alueen-huolto-ja-varaosat',
      },
      link4: {
        name: 'Uusi Elämä',
        url: 'https://www.playcare.fi/palvelut/Leikkipuiston-korjaus-Uusi-Elama',
      },
      link5: {
        name: 'Alustojen puhdistus',
        url: 'https://www.playcare.fi/palvelut/Turva-alustan-puhdistus-ja-huolto',
      },
    },
    menu2: {
      title: 'Muut palvelut',
      link1: {
        name: 'Varaosahaku',
        url: 'https://www.playcare.fi/Varaosahaku',
      },
      link2: {
        name: 'Ladattavat tiedostot',
        url: 'https://www.playcare.fi/UI/toolsmenu/Ladattavat-tiedostot',
      },
    },
  },
}
