var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"items-list",attrs:{"fluid":""}},[_c('ListTopPagination',{attrs:{"total-pages":_vm.pages,"expand-all":_vm.expandAll,"model":"workOrders"},model:{value:(_vm.workOrdersListOptions.page),callback:function ($$v) {_vm.$set(_vm.workOrdersListOptions, "page", $$v)},expression:"workOrdersListOptions.page"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers.filter(function (header) { return header.show; }),"items":_vm.workOrdersItems,"expanded":_vm.expanded,"hide-default-footer":true,"item-class":_vm.itemStyle,"options":_vm.workOrdersListOptions,"loading":_vm.workOrdersLoading,"loading-text":_vm.$i18n.t('workOrders.loading'),"server-items-length":_vm.workOrdersItems.length,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.workOrdersListOptions=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{class:item.index % 2 ? 'odd-row-td' : '',attrs:{"colspan":headers.length}},[_c('WorkOrderWorks',{attrs:{"works":item.works}})],1)]}},{key:"item.order_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.order_date))+" ")]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.total_cost, 2))+" ")]}},{key:"item.main_object_state",fn:function(ref){
var item = ref.item;
return [(item.main_object_state)?_c('div',{style:(_vm.objectStateStyle(item.main_object_state))},[_vm._v(" "+_vm._s(item.main_object_state[("name_" + (_vm.$i18n.locale))])+" ")]):_vm._e()]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$i18n.t('workOrders.listEmpty'))+" ")])],2),_c('ListBottomPagination',{attrs:{"total-pages":_vm.pages},model:{value:(_vm.workOrdersListOptions.page),callback:function ($$v) {_vm.$set(_vm.workOrdersListOptions, "page", $$v)},expression:"workOrdersListOptions.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }