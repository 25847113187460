<template>
  <div class="flash-messages">
    <div
      v-for="(message, index) in appMessages"
      :key="index"
      class="flash-message"
      @click="clickHandler(message)"
    >
      <v-alert
        prominent
        :type="message.type === 'flash' ? 'info' : message.type"
      >
        <v-row align="center">
          <v-col class="grow" v-html="message.message" />
          <v-col v-if="!message.hideCloseButton" class="shrink">
            <v-btn
              :icon="message.type !== 'flash'"
              :color="message.type === 'flash' ? '#aaa' : ''"
              class="elevation-0"
            >
              <template
                v-if="message.type === 'flash'"
              >
                OK
              </template>
              <v-icon v-else>fa-times</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </div>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'AppMessages',

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    clickHandler (message) {
      this.$store.dispatch('clearAppMessageByKey', message.key)
    },
  },
}
</script>

<style lang="scss">
.flash-messages {
  cursor: pointer;
  width: 400px;
  position: fixed;
  top: 170px;
  right: 20px;
  z-index: 1010;
  .v-alert {
    padding: 0 16px !important;
    border-radius: 8px !important;
  }
}
</style>
