<template>
  <v-container class="images-gallery">
    <v-row v-if="render">
      <v-col cols="12">
        <v-container
          v-if="userInfo && userInfo.allow_add_attachment"
          class="elevation-3 add-new-button text-center"
          @click="selectFile()"
        >
          <v-row>
            <v-col>
              <v-icon>fa-plus</v-icon>
              <br><br>
              <span class="add-title">
                {{ $i18n.t('general.addPicture').toUpperCase() }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-container class="pa-0">
          <v-row id="images-gallery">

            <!-- Existing or uploaded images -->

            <v-col
              v-for="(image, i2) in images"
              :key="i2"
              cols="12" sm="6" md="4" lg="3"
            >
              <v-img
                aspect-ratio="1.6"
                min-width="100%"
                max-width="100%"
                :lazy-src="'/content/attachments/' + image.id + '/playcare_preview.png'"
                :src="'/content/attachments/' + image.id + '/' + image.filename"
              >
                <template v-slot:default>
                  <v-btn
                    class="delete-btn elevation-0"
                    fab
                    dark
                    color="grey"
                    v-if="userInfo && userInfo.allow_remove_attachment"
                    :disabled="uploadingImages.length > 0"
                    :loading="deletingImageIds.includes(image.id)"
                    x-small
                    @mousedown.stop="deleteImage(image.id)"
                    @click.stop=""
                  >
                    <v-icon>fa-times</v-icon>
                  </v-btn>
                </template>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <!-- Images being uploaded -->

            <v-col
              v-for="(uploadingImage, i1) in uploadingImages"
              :key="i1 + '_1'"
              cols="12" sm="6" md="4" lg="3"
            >
              <v-img
                aspect-ratio="1.6"
                min-width="100%"
                max-width="100%"
                :src="uploadingImage"
              >
                <template v-slot:default>
                  <v-row
                    class="fill-height ma-0 uploading-image-bg"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>

          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <input
      type="file"
      id="upload_image"
      style="position: fixed; top: -200px"
      multiple
      @change="readImages($event);"
    />
  </v-container>
</template>

<script>
import state from '@/store/state'
import Sortable from 'sortablejs'
import helper from '@/helper'
import api from '@/store/api'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ImagesEdit',

  data () {
    return {
      render: true,
      uploadingImages: [],
      deletingImageIds: [],
      sortable: null,
    }
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    imagesReady: {
      type: Boolean,
      default: true,
    },
    forFieldName: {
      type: String,
      default: '',
    },
    parent: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    deletingImageIds () {
      this.ready = this.deletingImageIds.length === 0
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    images: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    ready: {
      get () {
        return this.imagesReady
      },
      set (value) {
        this.$emit('ready', value)
      }
    },
  },

  created () {
    this.$nextTick(() => {
      this.createSortables()
    })
  },

  methods: {
    createSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('images-gallery')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.updateImagesOrderLocal,
          chosenClass: 'container-chosen',
          dragClass: 'container-drag',
          delay: 150,
          // handle: '.fa-bars',
        })
      })
    },

    updateImagesOrderLocal (move) {
      const temp = this.images.splice(move.oldIndex, 1)[0]
      this.images.splice(move.newIndex, 0, temp)
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.createSortables()
      })
    },

    selectFile () {
      if (this.uploadingImages.length > 0) { return }
      const el = document.getElementById('upload_image')
      if (!el) { return }
      el.click()
    },

    deleteImage (id) {
      this.$set(this.deletingImageIds, this.deletingImageIds.length, id)
      this.$store.dispatch('deleteItem', {
        id: id,
        resource: 'attachments',
      }).then(success => {
        // Delete from deletingImageIds always, even if error
        const idIndex = this.deletingImageIds.indexOf(id)
        this.$delete(this.deletingImageIds, idIndex)
        // Delete from images array only if positive response
        if (success) {
          const imageIndex = this.images.map(image => image.id).indexOf(id)
          this.$delete(this.images, imageIndex)
        }
      })
    },

    getDataURLs (e) {
      return Promise.all([...e.target.files].map(fileToDataURL => fileToDataURL))
    },

    // Upload all images and get tokens/ids
    // this must be done synchronous in order for api to return valid tokens
    // with async way tokens are returned, but can't be used on item save or when selecting country
    readImages (e) {
      this.ready = false
      this.getDataURLs(e).then(selectedImages => {
        this.setTempUploadingImages(selectedImages)
        if (!selectedImages || !selectedImages[0]) { return }
        this.setTempUploadingImages(selectedImages)
        selectedImages
          .reduce((previousPromise, image) => {
            return previousPromise.then(() => {
              return this.readImage(image, e)
            })
          }, Promise.resolve()).then(() => {
            this.ready = true
          })
      })
    },

    setTempUploadingImages (dataURLs) {
      this.uploadingImages = dataURLs.map(dataURL => {
        return URL.createObjectURL(dataURL)
      })
    },

    readImage (image, e) {
      return new Promise(resolve => {
        if (!image) {
          resolve()
          return
        }
        // this.uploadImage = URL.createObjectURL(image)
        const reader = new FileReader()
        reader.onload = e => {
          // this.previewImage = e.target.result
          this.$store.dispatch('addNewFile', {
            file: image,
            files: this.images,
            forObjectClass: helper.objectClassUnderscoredName(this.parent['@class']),
            forFieldName: this.forFieldName,
            forObjectId: this.parent.token, // || this.parent.id
            attachmentType: 'image',
          }).then(response => {
            this.uploadingImages.shift()
            resolve(response)
          })
        }
        reader.readAsText(image)
      })
    },
  }
}
</script>

<style lang="scss">
.uploading-image-bg {
  background: rgba(255, 255, 255, 0.75);
}
.images-gallery {
  .v-image {
    border-radius: 8px;
    .delete-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.7;
      background: rgba(0, 0, 0, 0.4) !important;
    }
  }
  .v-image:hover {
    .delete-btn:hover {
      opacity: 1;
      color: red;
      background: white !important;
    }
  }
}
</style>
