const shared = [
  'main_object_state summary,initial_state,final_state,text_color,background_color',
  'organization summary',
  'person summary',
  'order_date',
  'order_number',
  'total_cost',
  'works priority,work_number,work_description,activity_park,cost,main_object_state,assigned_time',
  'works main_object_state summary,initial_state,final_state,text_color,background_color',
  'works activity_park name,name_extension'
]

export default {
  forList: [...shared],
  forEdit: [...shared],
  forShow: [...shared],
}
