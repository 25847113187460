var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:'items-list ' + (_vm.modal ? 'items-list-modal' : ''),attrs:{"fluid":""}},[(!_vm.modal)?_c('ListTopPagination',{attrs:{"total-pages":_vm.pages,"expand-all":_vm.expandAll,"model":"works"},model:{value:(_vm.worksListOptions.page),callback:function ($$v) {_vm.$set(_vm.worksListOptions, "page", $$v)},expression:"worksListOptions.page"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersFiltered,"items":_vm.worksItemsComputed,"expanded":_vm.expanded,"hide-default-footer":true,"item-class":_vm.itemStyle,"options":_vm.worksListOptions,"loading":_vm.modal ? false : _vm.worksLoading,"loading-text":_vm.$i18n.t('works.loading'),"server-items-length":_vm.worksItems.length,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.worksListOptions=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(headers)?_c('WorksListItemExpanded',{attrs:{"work":item,"headers":headers,"delete-work":_vm.deleteWork,"reload-list":_vm.onUpdate || _vm.getWorksListItems,"work-form-template":_vm.workFormTemplate,"modal":_vm.modal,"read-only":_vm.readOnly,"force-editable":true}}):_vm._e()]}},{key:"item.main_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.main_date))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.cost, 2))+" ")]}},{key:"item.main_object_state_id",fn:function(ref){
var item = ref.item;
return [(item.main_object_state)?_c('div',{style:(_vm.objectStateStyle(item.main_object_state))},[_vm._v(" "+_vm._s(item.main_object_state[("name_" + (_vm.$i18n.locale))])+" ")]):_vm._e()]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority)?_c('div',{staticClass:"priority-box",style:(_vm.priorityStyle(item.priority))},[_vm._v(" "+_vm._s(item.priority.toUpperCase())+" ")]):_vm._e()]}},{key:"item.error_description",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.fault_description ? item.fault_description[("name_" + (_vm.$i18n.locale))] : item.error_description)+" ")])]}},{key:"item.work_type",fn:function(ref){
var item = ref.item;
return [(item.work_type)?_c('div',[_vm._v(" "+_vm._s(item.work_type[("name_" + (_vm.$i18n.locale))])+" ")]):_vm._e()]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$i18n.t('works.listEmpty'))+" ")])],2),(!_vm.modal)?_c('ListBottomPagination',{attrs:{"total-pages":_vm.pages},model:{value:(_vm.worksListOptions.page),callback:function ($$v) {_vm.$set(_vm.worksListOptions, "page", $$v)},expression:"worksListOptions.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }