<template>
  <v-dialog
    v-model="showDialog"
    width="1000"
  >
    <v-container class="dialog-container">
      <v-row
        v-if="loading"
        class="mx-0"
      >
        <v-col>
          <v-progress-linear
            indeterminate
            color="grey"
          />
        </v-col>
      </v-row>
      <template v-else>
        <v-row class="header-row">
          <v-col cols="10" class="h1">
            <template v-if="activityPark && activityPark.id">
              {{ $i18n.t('productInstances.addNewEquipmentFor') }}:
              {{ activityPark.name }}
            </template>
            <template v-else-if="item.id">
              {{ $i18n.t('productInstances.changeInfoTitle').toUpperCase() }}
            </template>
            <template v-else>
              {{ $i18n.t('productInstances.addNewEquipment').toUpperCase() }}
            </template>
          </v-col>

          <v-col cols=2 class="text-right">
            <v-icon @click="showDialog = false">
              fa-times
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="content-row">
          <v-col>
            <v-container
              fluid class="search-form"
              :style="{ height: dialogHeight + 'px' }"
            >
              <v-row>

                <v-col cols="12" class="ei-name">
                  <v-text-field
                    v-model="item.name"
                    :label="$i18n.t('general.name') + ' *'"
                    maxlength="100"
                    counter="100"
                    :disabled="!!item.id"
                    v-bind="textFieldFormatAttributesForInputWithDetails"
                  />
                </v-col>

                <v-col
                  v-if="item.id"
                  cols="12"
                >
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('general.organization')"
                    :style-attributes="textFieldFormatAttributes"
                    :disabled="true"
                    search-class="organizations"
                    model="product_instances"
                    field-name="organization"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                 <FormSelectField
                    :item="item"
                    :label="$i18n.t('activityParks.activityPark') + ' *'"
                    :extra-filters="activityParksExtraFilters"
                    :style-attributes="textFieldFormatAttributes"
                    :disabled="!!(this.activityPark || parseInt(this.id))"
                    search-class="activity_parks"
                    model="product_instances"
                    field-name="activity_park"
                  />
                </v-col>

                <v-col cols="12">
                  <ImagesEdit
                    v-model="item.images"
                    :parent="item"
                    for-field-name="images"
                    :images-ready="imagesReady"
                    v-on:ready="imagesReady = arguments[0]"
                  />
                </v-col>

                <v-col cols="4" class="form-field align-self-center pl-9">
                  {{ $i18n.t('productInstances.conditionScore') }}:
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="item.condition_score"
                    :items="Array.from({ length: 6 }, (_, i) => i)"
                    :label="$i18n.t('productInstances.conditionScoreNotSet')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col cols="4" class="form-field align-self-center pl-9">
                  {{ $i18n.t('productInstances.installationDate') }}:
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="item.installation_date_month"
                    :items="Array.from({ length: 12 }, (_, i) => i + 1)"
                    :label="$i18n.t('general.month_placeholder')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="item.installation_date_year"
                    :items="$store.getters.yearOptions"
                    :label="$i18n.t('general.year_placeholder')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="item.product_code"
                    :items="$store.getters.product_codes"
                    :label="$i18n.t('productInstances.productCode') + ' *'"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.product_number"
                    :label="$i18n.t('productInstances.productNumber')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col cols="12">
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('productInstances.manufacturer')"
                    search-class="manufacturers"
                    model="product_instances"
                    field-name="manufacturer"
                    :style-attributes="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col cols="12"><div class="h-spacer"/></v-col>

                <v-col cols="12">
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('productInstances.product_instance_category')"
                    search-class="product_instance_categories"
                    model="product_instances"
                    field-name="product_instance_category"
                    :style-attributes="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="item.product_instance_type"
                    :label="$i18n.t('productInstances.product_instance_type')"
                    :disabled="!item.product_instance_category"
                    :items="productInstanceTypesForProductCategory"
                    :clearable="true"
                    item-text="summary"
                    item-value="id"
                    return-object
                    clear-icon_="fa-times"
                    hide-details
                    autocomplete="off"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="12">
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('productInstances.standard')"
                    search-class="standards"
                    model="product_instances"
                    field-name="standard"
                    :style-attributes="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col cols="12"><div class="h-spacer"/></v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="item.material"
                    :items="$store.getters.materials"
                    :label="$i18n.t('productInstances.material.title')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="item.foundation"
                    :items="$store.getters.foundations"
                    :label="$i18n.t('productInstances.foundation.title')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="12">
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('productInstances.safety_platform_type')"
                    search-class="safety_platform_types"
                    model="product_instances"
                    field-name="safety_platform_type"
                    :style-attributes="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.safe_zone_size"
                    :label="$i18n.t('productInstances.safe_zone_size')"
                    suffix="m2"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="buttons-row">
          <v-col cols="6">
            <v-btn
              color="#8cc477"
              :dark="!disabled"
              :disabled="disabled"
              v-bind="popupButtonFormatAttributes"
              @click="save"
            >
              {{ $i18n.t('general.saveChanges') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="#666"
              block
              outlined
              v-bind="popupButtonFormatAttributes"
              @click="showDialog = false"
            >
              {{ $i18n.t('general.cancelAndCloseForm') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import FormSelectField from '@/components/Shared/FormSelectField'
import api from '@/store/api'
import productInstancesQueries from '@/components/ProductInstances/productInstancesQueries'
import allMethods from '@/components/allMethods'
import moment from 'moment'
import worksQueries from '@/components/Works/worksQueries'
import ImagesEdit from '@/components/Shared/ImagesEdit'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ProductInstanceEdit',

  data () {
    return {
      loading: false,
      item: {},
      imagesReady: true,
    }
  },

  components: {
    ImagesEdit,
    FormSelectField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    disabled () {
      return !(this.imagesReady && this.requiredFieldsFilled)
    },

    requiredFieldsFilled () {
      return !!this.item.name && !!this.item.activity_park && !!this.item.product_code
    },

    id () {
      return this.productInstanceEditModalProps.id
    },

    activityPark () {
      return this.productInstanceEditModalProps.activityPark
    },

    showDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    activityParksExtraFilters () {
      const filters = {}
      if (this.item.organization && this.item.organization.id) {
        filters._organization_id = this.item.organization.id
      }
      return filters
    },

    productInstanceTypesForProductCategory () {
      if (!this.item.product_instance_category) { return [] }
      return this.productInstanceTypes.filter(type => type.product_instance_category && type.product_instance_category.id === this.item.product_instance_category.id)
    },
  },

  created () {
    this.loading = true
    api.getItemInfoForEdit('product_instances', this.id, productInstancesQueries.forEdit, {})
      .then(response => {
        this.$store.dispatch('globalErrorDisplay', response).then(noErrors => {
          if (!noErrors) {
            this.item = response.item
            if (response.item.installation_date) {
              var installation_date = moment(response.item.installation_date)
              this.item.installation_date_year = installation_date.year()
              this.item.installation_date_month = installation_date.month() + 1
            }
            // Set Activity Park if form open from Activity Parks list
            if (this.activityPark && this.activityPark.id) {
              this.item.activity_park = {
                id: this.activityPark.id,
                summary: this.activityPark.summary,
              }
            }
          } else {
            this.showDialog = false
          }
          this.loading = false
        })
      })
  },

  methods: {
    ...allMethods,

    save () {
      this.loading = true
      this.item.installation_date = this.item.installation_date_year && this.item.installation_date_month
        ? moment().set({
          year: this.item.installation_date_year,
          month: this.item.installation_date_month - 1,
          date: 1
        }).format('YYYY-MM-DD')
        : null
      this.$store.dispatch('saveItem', {
        model: 'product_instances',
        item: { ...this.item, ...(this.item.condition_score === undefined ? { condition_score: null } : {}) },
        queries: productInstancesQueries.forList,
        showSaveMessage: true,
      }).then(savedItem => {
        if (savedItem && savedItem.id) {
          this.showDialog = false
          this.productInstanceEditModalProps.callback(savedItem)
          this.getProductInstancesListItems()
        }
        this.loading = false
      })
    },
  },
}
</script>
