<template>
  <v-container class="my-0 py-0">
    <v-row class="my-0 py-0">
      <v-col cols="12" class="text-subtitle my-0 py-0">
        {{ $i18n.t('works.' + modelName) }}
      </v-col>
      <v-col cols="12" class="my-1 py-1">
        <table>
          <template
            v-for="(item, index) in work[modelName]"
          >
            <WorkItemEdit
              v-model="work[modelName][index]"
              :row-index="index"
              :key="index"
              :edit="true"
              :parent="work"
              :parent-field="modelName"
              :model-name="modelName"
            />
          </template>
        </table>
      </v-col>
      <v-col cols="12" class="my-0 py-0">
        <v-btn
          rounded
          dark
          color="#8cc477"
          class="elevation-0"
          @click="addNewWorkItem"
        >
          {{ $i18n.t('general.add') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import WorkItemEdit from '@/components/Works/WorkItems/WorkItemEdit'
import workItemQueries from '@/components/Works/WorkItems/workItemQueries'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'WorkItems',

  components: {
    WorkItemEdit
  },

  props: {
    work: {
      type: Object,
      default: () => {}
    },
    modelName: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    addNewWorkItem () {
      this.$store.dispatch('addNewChildFor', {
        forItem: this.work,
        childModel: this.modelName,
        queries: workItemQueries.get(this.modelName).filter(q => !q.includes('@')),
      })
    },
  },
}
</script>
