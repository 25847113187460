<template>
  <v-container
    fluid
    style="max-width: 1600px;"
    class="body-container pa-0 elevation-3"
  >
    <ListSearchToolbar model="workOrders" />
    <WorkOrdersList ref="workOrdersList" />
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ListSearchToolbar from '@/components/Shared/ListSearchToolbar'
import WorkOrdersList from '@/components/WorkOrders/WorkOrdersList'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'WorkOrders',

  data () {
    return {
      model: 'workOrders',
    }
  },

  components: {
    WorkOrdersList,
    ListSearchToolbar,
  },

  watch: {
    workOrdersListOptionsTrigger () {
      this.getListItems()
    },

    'workOrdersListOptions.page' (now, before) {
      // When page has changed, close all expanded in Works List
      // otherwise expand-all button does not work first time
      if (now !== before) {
        this.$refs.workOrdersList.closeAllExpanded()
      }
    },
  },

  created () {
    this.workOrdersListOptions.page = 1
    this.getListItems()
  },

  computed: {
    ...mapFields(Object.keys(state)),

    workOrdersListOptionsTrigger () {
      return JSON.stringify(this.workOrdersListOptions)
    },
  },

  methods: {
    ...allMethods,
  },
}
</script>
