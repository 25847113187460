const shared = [
  'id',
  'name,summary',
  'name_extension',
  'organization name,summary',
  'address summary,latitude,longitude',
  'contact_person_name',
  'contact_person_email',
  'contact_person_phone',
  'ifs_identifier',
  'images filename,content_type,attachable_association,order',
  'product_instances id,in_operation',
  'works id,priority,inspection_work,work_description,completion_date,reporter_name,reporter_certificate',
  'works work_type id',
  'works main_object_state id',
  'equipment_amount',
  'in_operation',
  'last_inspection_date',
  'activity_park_type summary,name_fi,name_en,name_se',
  'activity_park_comments @count',
  'play_areas description,number,token',
]

export default {
  forList: [...shared],
  forEdit: [...shared],
  forShow: [...shared],
}
