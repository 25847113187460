import axios from 'axios'

export default {
  deleteItem (objectClass, id) {
    return new Promise((resolve) => {
      axios.delete(
        '/api/' + objectClass + '/' + id, {},
        this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response)
        }
        resolve({})
      }).catch((err) => {
        resolve(err.response)
      })
    })
  },
}
