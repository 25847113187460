<template>
  <v-container class="elevation-0 shopping-cart-items">
    <v-row no-gutters>
      <v-col>
        <table>
          <thead>
            <tr>
              <td>{{ $i18n.t('activityParks.activityPark') }}</td>
              <td>{{ $i18n.t('works.workNumber') }}</td>
              <td>{{ $i18n.t('works.priority') }}</td>
              <td>{{ $i18n.t('works.errorDescription') }}</td>
              <td class="text-right">{{ $i18n.t('shoppingCart.cost') }}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="index"
            >
              <td>{{ item.activity_park && item.activity_park.name }}</td>
              <td>{{ item.work_number }}</td>
              <td>
                <div
                  class="priority-box"
                  :style="priorityStyle(item.priority)"
                >
                  {{ (item.priority && item.priority.toUpperCase()) || '?' }}
                </div>
              </td>
              <td>{{ item.error_description }}</td>
              <td class="text-right">{{ formatPrice(item.cost) }}</td>
              <td class="text-right">
                <v-btn
                  class="delete-icon"
                  :loading="deletingItemsIds.includes(item.id)"
                  :disabled="!!saving"
                  @click="deleteRow(item)"
                >
                  <v-icon>fa-trash-alt</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ShoppingCartItems',

  data () {
    return {
      deletingItemsIds: [],
    }
  },

  props: {
    items: {
      type: Array,
      default: () => {},
    },
    saving: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...allMethods,

    deleteRow (item) {
      this.$set(this.deletingItemsIds, this.deletingItemsIds.length, item.id)
      this.deleteWorkFromCart(item).then(() => {
        const index = this.deletingItemsIds.indexOf(item.id)
        this.$delete(this.deletingItemsIds, index)
      })
    },
  },
}
</script>

<style lang="scss">
.shopping-cart-items {
  padding: 15px 0px !important;
  margin: -20px !important;
  width: calc(100% + 40px) !important;
  table {
    border-spacing: 0;
    width: 100%;
    td {
      margin: 0;
      padding: 2px 10px;
    }
    td:first-child {
      padding-left: 30px;
    }
    td:last-child {
      padding-right: 30px;
    }
    thead {
      tr {
        background: #8cc477;
        color: white;
        td {
          font-weight: 600;
          margin: 0;
          padding: 7px 10px;
        }
      }
    }
    tbody {
      td {
        font-size: 14px;
        border-bottom: 1px solid #ddd;
      }
      tr:nth-child(odd) {
        background-color: #f2f2f2;
      }
    }
  }
  .delete-icon {
    min-width: auto !important;
    width: 25px !important;
    height: 25px !important;
    margin: 4px;
    background: white !important;
    padding: 0 !important;
    .fa {
      color: #888 !important;
      font-size: 16px;
    }
  }
}
</style>
