<template>
  <v-dialog
    v-model="showDialog"
    width="800"
  >
    <div class="pa-2 white">
      <v-container class="list-item list-item-modal">
        <v-row>
          <v-col cols="10">
            {{ $i18n.t('works.deleteTitle') }}
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn icon @click="close()">
              <v-icon>fa-times</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedReason"
              :items="deleteReasons"
              :label="$i18n.t('works.removeReason.title')"
              v-bind="textFieldFormatAttributes"
            />
          </v-col>

          <v-col cols="6">
            <v-btn
              :disabled="saving || !selectedReason"
              color="#d9534f"
              :dark="!!selectedReason"
              @click="deleteItem"
              v-bind="popupButtonFormatAttributes"
            >
              {{ $i18n.t('works.removeReason.button') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="saving"
              color="#666"
              block
              outlined
              v-bind="popupButtonFormatAttributes"
              @click="close()"
            >
              {{ $i18n.t('general.cancelAndCloseForm') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import allMethods from '@/components/allMethods'
import api from '@/store/api/'

import i18n from '@/locale/i18n'
import worksQueries from '@/components/Works/worksQueries'
import moment from 'moment'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemDeleteModalWithReasons',

  data () {
    return {
      saving: false,
      showDialog: true,
      selectedReason: null,
    }
  },

  props: {
    model: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    deleteReasons () {
      return Object.keys(this.deleteReasonsByModel[this.model]).map(key => {
        const text = this.deleteReasonsByModel[this.model][key]
        return {
          text: this.$i18n.t(text),
          value: key,
        }
      })
    },
  },

  methods: {
    ...allMethods,

    close () {
      this.showDialog = false
      this.workDeleteConfirmForWorkId = null
    },

    deleteItem () {
      this.saving = true
      const queriesByModel = {
        works: worksQueries.forList,
      }
      this.$store.dispatch('setItemAsRemoved', {
        model: this.model,
        itemId: this.workDeleteConfirmForWorkId,
        reason: this.selectedReason,
        queries: queriesByModel[this.model],
      }).then(response => {
        this.saving = false
        if (response && response.data && response.data.status === 'ok') {
          this.close()
        }
      })
    },
  },
}
</script>
