<template>
  <v-autocomplete
    v-model="item[fieldName]"
    :label="label"
    :disabled="disabled"
    :loading="loadingOptions"
    :items="options"
    :search-input.sync="searchTerm"
    :no-data-text="noDataText"
    :clearable="clearable"
    item-text="summary"
    item-value="id"
    return-object
    clear-icon_="fa-times"
    hide-details
    autocomplete="off"
    @focus="focus"
    :ref="fieldName"
    v-bind="styleAttributes"
    @change="change"
  />
</template>

<script>
import api from '@/store/api'

export default {
  name: 'FormSelectField',

  data () {
    return {
      options: [],
      searchTerm: null,
      loadingOptions: false,
      timer: null,
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: '',
    },
    styleAttributes: {
      type: Object,
      default: () => {},
    },
    searchClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loadOnFocus: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: Object,
      default: () => {}
    },
    parentField: {
      type: String,
      default: null
    },
    extraFilters: {
      type: Object,
      default: () => {}
    },
  },

  watch: {
    searchTerm (val, e) {
      // Skip when current value is included in options
      if (this.options.map(option => option.summary).includes(val)) {
        return
      }
      clearTimeout(this.timer)
      const selectedOption = this.options.filter(option => {
        return option.text === val
      })
      const loadOnClear = !val // this.loadOnFocus &&
      if (val) { this.loadingOptions = true }
      this.timer = setTimeout(() => {
        (val || loadOnClear) &&
        (
          (this.cloned && selectedOption[0] && val !== selectedOption[0].text) ||
          !selectedOption[0] ||
          !this.cloned
        ) &&
        this.searchOptionsByName(val)
      }, 300)
    }
  },

  computed: {
    noDataText () {
      return this.loadingOptions
        ? this.$i18n.t('general.searching')
        : this.searchTerm
          ? this.$i18n.t('general.no_items_found')
          : this.$i18n.t('general.searchText')
    }
  },

  created () {
    this.addCurrentValueAsOption()
  },

  methods: {
    change (val) {
      this.$emit('change', val)
    },

    addCurrentValueAsOption () {
      if (!this.item[this.fieldName]) { return }
      this.options = [this.item[this.fieldName]]
    },

    focus () {
      if (!this.loadOnFocus) { return }
      this.searchOptionsByName('')
    },

    searchOptionsByName (searchName) {
      this.loadingOptions = true
      api.searchOptionsByName({
        searchString: searchName,
        objectClass: this.searchClass,
        moduleClass: this.model,
        tokenOrId: this.item.token ? this.item.token : this.item.id,
        fieldName: this.fieldName,
        parent: this.parent,
        parentField: this.parentField,
        extraFilters: this.extraFilters,
      }).then(response => {
        // this.options = this.buildOptions(response.items, 'id', 'summary')
        this.options = response.items
        this.loadingOptions = false
      })
    },
  },
}
</script>
