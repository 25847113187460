<template>
  <v-toolbar
    class="list-title-toolbar"
    color="#e6e6e6"
  >
    <v-toolbar-title
      v-if="model !== 'organizations' && anyFilterApplied"
      class="mr-3"
    >
      {{ $i18n.t('general.searchResults').toUpperCase() }}
    </v-toolbar-title>
    <v-toolbar-title
      v-else
      class="mr-3"
    >
      {{ $i18n.t('general.all').toUpperCase() }} {{ $i18n.t(model + '.title').toUpperCase() }}
    </v-toolbar-title>
    <span class="count">( {{ itemsTotal }} {{ $i18n.t('general.quantity') }} )</span>
    <v-spacer />
    <v-btn
      v-if="model !== 'organizations' && anyFilterApplied"
      color="#666"
      class="elevation-0 mr-4"
      text
      @click="clear()"
    >
      <v-icon
        class="mr-3"
        color="red"
      >
        fa-times
      </v-icon>
      {{ $i18n.t('general.clearSearch').toUpperCase() }}
    </v-btn>
    <v-btn
      v-if="['activityParks', 'works', 'productInstances'].includes(model)"
      color="white"
      class="elevation-2"
      @click="showSearchForm()"
    >
      <v-icon
        class="mr-3"
        color="#999"
        small
      >
        fa-sliders-h
      </v-icon>
      {{ $i18n.t('general.detailedSearch').toUpperCase() }}
    </v-btn>
    <template
      v-if="['works'].includes(model) && userInfo && userInfo.allow_add_new_work"
    >
      <div class="vertical-line" />
      <v-btn
        color="white"
        class="elevation-2 export-button"
        @click="showAddNewForm()"
      >
        <v-icon
          color="#999"
        >
          fa-plus
        </v-icon>
      </v-btn>
    </template>
    <template
      v-if="['activityParks'].includes(model) && userInfo && userInfo.allow_show_on_map"
    >
      <div class="vertical-line" />
      <v-btn
        color="white"
        class="elevation-2 export-button"
        @click="mapView = !mapView"
      >
        <v-icon
          color="#999"
        >
          {{ !mapView ? 'fa-map-marked-alt' : 'fa-list' }}
        </v-icon>
      </v-btn>
    </template>
    <template
      v-if="showDownloadButton"
    >
      <div class="vertical-line" />
      <v-btn
        color="white"
        class="elevation-2 export-button"
        :loading="downloading"
        @click="downloadReport()"
      >
        <v-icon
          color="#999"
        >
          fa-file-excel
        </v-icon>
      </v-btn>
    </template>
  </v-toolbar>
</template>

<script>
import state from '@/store/state'
import api from '@/store/api'
import { createHelpers } from 'vuex-map-fields'
import allMethods from '@/components/allMethods'
import productInstancesMethods from '@/components/ProductInstances/productInstancesMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})
const i = require('i')(true)

export default {
  name: 'ListTitleToolbar',

  data () {
    return {
      downloading: false,
    }
  },

  props: {
    model: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showDownloadButton () {
      return ['activityParks', 'works', 'ratings'].includes(this.model) &&
        this.userInfo && this.userInfo.allow_excel_export
    },

    anyFilterApplied () {
      return this.filtersAreSet ||
        this.globalFilters.organization.length > 0 ||
        (this.globalFilters.activity_park.length > 0 && this.model !== 'activityParks')
    },

    filtersAreSet () {
      return JSON.stringify(this[this.model + 'Filters']) !== JSON.stringify(this[this.model + 'DefaultFilters'])
    },

    itemsTotal () {
      return this[this.model + 'ItemsTotal'].toLocaleString('fi')
    },

    modelNameTitleized () {
      return i.titleize(this.model).replaceAll(' ', '')
    },

    excelReportDownloadProps () {
      if (this.model === 'works') {
        return {
          model: this.model,
          ids: this.worksAllIds,
        }
      } else { // activityParks
        return {
          model: this.model,
          id: this.globalFilters.organization[0].id,
        }
      }
    },
  },

  methods: {
    ...allMethods,

    downloadReport () {
      if (this.model === 'works') {
        if (this.worksItemsTotal < 1001) {
          api.getExcelReport({ ...this.excelReportDownloadProps, locale: state.locale })
        } else {
          this.$store.dispatch('showMessage', {
            message: this.$i18n.t('works.tooMany'),
            type: 'info'
          })
        }
      } else if (this.model === 'ratings') {
        const ids = this.globalFilters.organization.map(o => o.id)
        const order = this.ratingsListOptions.sortBy[0].split('.')[0] + ' ' +
          (this.ratingsListOptions.sortDesc[0] ? 'desc' : 'asc')
        api.getRatingsExcel({ ids, order })
      } else { // activityParks
        if (this.globalFilters.organization.length === 1) {
          api.getExcelReport({ ...this.excelReportDownloadProps, locale: state.locale })
        } else {
          this.$store.dispatch('showMessage', {
            message: this.$i18n.t('organizations.chooseOne'),
            type: 'info'
          })
        }
      }
    },

    clear () {
      this.clearSearch()
      this.getListItems()
    },
  },
}
</script>

<style lang="scss">
.list-title-toolbar {
  margin-top: 25px !important;
  margin-bottom: 0px; // 5px
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.1),0 3px 4px 0 rgba(0,0,0,.14),0 1px 8px 0 rgba(0,0,0,.12)!important;
  z-index: 1;
  .vertical-line {
    height: calc(100% - 10px);
    width: 1px;
    margin: 5px 20px;
    border-left: 2px solid #ccc;
  }
  .export-button {
    min-width: auto !important;
    padding: 0 10px !important;
  }
  .v-toolbar__title {
    color: #666;
    font-weight: 600;
  }
  .count {
    color: #444;
    font-weight: 300;
  }
}
</style>
