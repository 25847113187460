<template>
  <v-dialog
    v-model="showDialog"
    width="1600"
    content-class="elevation-0"
  >
    <v-container fluid class="pa-5 ma-0 elevation-10">
      <v-row v-if="!loading">
        <v-col cols="12" class="text-right pb-5 pr-0">
          <v-btn
            fab
            x-small
            @click="showDialog = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-col>
        <v-progress-linear
            indeterminate
            color="grey"
            class="my-5"
          />
        </v-col>
      </v-row>
      <v-row v-else-if="item.id">
        <ActivityParksListItem
          :item="item"
          :callback-on-item-change="reloadModalAndParent"
          :callback-on-item-hide="reloadParentAndCloseModal"
        />
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import api from '@/store/api'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'
import ActivityParksListItem from '@/components/ActivityParks/ActivityParksListItem'
import helper from '@/helper'

export default {
  name: 'ActivityParkItemModal',
  components: { ActivityParksListItem },
  data () {
    return {
      loading: true,
      item: {},
    }
  },

  props: {
    value: {
      type: Number,
      default: null,
    },
    closeModalAndReload: {
      type: Function,
      default: () => {},
    },
  },

  created () {
    this.loadActivityPark()
  },

  watch: {
    value () {
      this.loadActivityPark()
    },
  },

  computed: {
    showDialog: {
      get () {
        return !!this.value
      },
      set () {
        // Only called on dialog close
        this.item = {}
        this.$emit('input', null)
      },
    }
  },

  methods: {
    reloadParentAndCloseModal () {
      this.$emit('reload')
      this.showDialog = false
    },

    reloadModalAndParent () {
      this.$emit('reload')
      this.loadActivityPark()
    },

    loadActivityPark () {
      if (!this.value) { return }
      this.loading = true
      api.getItemInfo('activity_parks', this.value, activityParksQueries.forShow)
        .then(response => {
          if (response && response.item) {
            this.item = response.item
          }
          this.loading = false
        })
    }
  },
}
</script>
