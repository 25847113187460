export default {
  '@class': 'string',
  id: 'number',
  token: 'string',
  additional_info: 'string',
  work_offer_items: 'array',
  offer_date: 'date',
  person: 'reference',
  organization: 'reference',
}
