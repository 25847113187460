<template>
  <div class="footer-container">
    <v-container
      style="max-width: 1560px"
      fluid
    >
      <v-row>
        <v-col>
          <v-img
            alt="PlayCare"
            class="shrink mt-1"
            contain
            min-width="150"
            :src="require('../../assets/lappset-2023-nega.png')"
            width="100"
          />
        </v-col>
        <v-col class="text-right">
          <b>{{ $i18n.t('contact.title') }}</b>
        </v-col>
        <v-col>
          {{ $i18n.t('contact.phone') }}<br />
          {{ $i18n.t('contact.email') }}<br />
          {{ $i18n.t('contact.address') }}
        </v-col>
        <v-col class="text-right">
          <b>{{ $i18n.t('contact.menu1.title') }}</b>
        </v-col>
        <v-col>
          <a :href="$i18n.t('contact.menu1.link1.url')">{{
            $i18n.t('contact.menu1.link1.name')
          }}</a
          ><br />
          <a :href="$i18n.t('contact.menu1.link2.url')">{{
            $i18n.t('contact.menu1.link2.name')
          }}</a
          ><br />
          <a :href="$i18n.t('contact.menu1.link3.url')">{{
            $i18n.t('contact.menu1.link3.name')
          }}</a
          ><br />
          <a :href="$i18n.t('contact.menu1.link4.url')">{{
            $i18n.t('contact.menu1.link4.name')
          }}</a
          ><br />
        </v-col>
        <v-col class="text-right">
          <b>{{ $i18n.t('contact.menu2.title') }}</b>
          <b></b>
        </v-col>
        <v-col>
          <a :href="$i18n.t('contact.menu2.link2.url')">{{
            $i18n.t('contact.menu2.link2.name')
          }}</a
          ><br />
        </v-col>
        <v-col class="text-right social-icons">
          <v-btn
            fab
            icon
            dark
            small
            href="https://www.facebook.com/PlayCareFinland/"
            target="_blank"
          >
            <i class="fab fa-facebook"></i>
          </v-btn>
          <v-btn
            fab
            icon
            dark
            small
            href="https://twitter.com/lappsetgroup"
            target="_blank"
          >
            <i class="fab fa-twitter"></i>
          </v-btn>
          <v-btn
            fab
            icon
            dark
            small
            href="https://www.youtube.com/channel/UC-pH5LoYFfp30Qc1VF3cQIg?view_as=subscriber"
            target="_blank"
          >
            <i class="fab fa-youtube"></i>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss">
.footer-container {
  padding: 30px;
  background: #5cc6f1;
  color: white;
  z-index: 1;
  a {
    color: white !important;
  }
}
.social-icons {
  .fab {
    font-size: 24px;
  }
}
</style>
