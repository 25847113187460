<template>
  <v-dialog
    v-model="showDialog"
    width="1000"
  >
    <v-container class="dialog-container">
      <v-row
        v-if="loading"
        class="mx-0"
      >
        <v-col>
          <v-progress-linear
            indeterminate
            color="grey"
          />
        </v-col>
      </v-row>
      <template v-else>
        <v-row class="header-row">
          <v-col
            cols="12"
            class="h1"
          >
            <template v-if="activityPark && activityPark.id">
              {{ $i18n.t('works.addNewWorkFor') }}:
              {{ activityPark.name }}
            </template>
            <template v-else-if="preExisting">
              {{ $i18n.t('works.editOldWork') }}
            </template>
            <template v-else>
              {{ $i18n.t('works.addNewWork') }}
            </template>
          </v-col>
        </v-row>
        <v-row class="content-row">
          <v-col>
            <v-container
              fluid
              class="search-form"
              :style="{ height: dialogHeight + 'px' }"
            >
              <v-row>
                <v-col
                  v-if="!activityPark || !activityPark.id"
                  cols="12"
                >
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('activityParks.activityPark') + ' *'"
                    search-class="activity_parks"
                    model="works"
                    field-name="activity_park"
                    :style-attributes="textFieldFormatAttributes"
                    :disabled="preExisting"
                  />
                </v-col>

                <v-col
                  cols="6"
                  class="text-subtitle"
                  v-if="!preExisting"
                  >{{ $i18n.t('works.productInstanceType') }}</v-col
                >
                <v-col
                  cols="6"
                  class="text-subtitle"
                  v-if="!preExisting"
                  >{{ $i18n.t('productInstances.equipment') }}</v-col
                >

                <v-col
                  cols="6"
                  v-if="!preExisting"
                >
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('works.productInstanceType')"
                    search-class="product_instance_types"
                    model="works"
                    field-name="product_instance_type"
                    :style-attributes="textFieldFormatAttributes"
                    :disabled="preExisting"
                  />
                </v-col>
                <v-col
                  cols="6"
                  v-if="!preExisting"
                >
                  <FormSelectField
                    :disabled="!item.activity_park || preExisting"
                    :item="item"
                    :label="$i18n.t('works.equipmentText')"
                    :extra-filters="productInstancesExtraFilters"
                    search-class="product_instances"
                    model="works"
                    field-name="product_instance"
                    :style-attributes="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-subtitle"
                  >{{ $i18n.t('works.errorDescription') + ' *' }}</v-col
                >
                <v-col cols="12">
                  <v-textarea
                    v-model="item.error_description"
                    :label="$i18n.t('works.errorDescriptionText')"
                    :disabled="preExisting"
                    rows="2"
                    rounded
                    outlined
                    counter="60"
                    maxlength="60"
                  />
                </v-col>

                <v-col
                  v-if="workEditModalProps.template === 'default'"
                  cols="6"
                  class="text-subtitle"
                  >{{ $i18n.t('works.standard') }}
                </v-col>
                <v-col
                  v-if="
                    userInfo &&
                    userInfo.allow_change_priority_on_new &&
                    !preExisting
                  "
                  :disabled="preExisting"
                  :cols="workEditModalProps.template === 'default' ? '6' : '12'"
                  class="text-subtitle"
                  >{{ $i18n.t('works.workPriority') }}
                </v-col>
                <v-col
                  v-if="
                    workEditModalProps.template === 'default' && !preExisting
                  "
                >
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('works.standardText')"
                    search-class="standards"
                    model="works"
                    field-name="standard"
                    :style-attributes="textFieldFormatAttributes"
                    :disabled="preExisting"
                  />
                </v-col>
                <v-col
                  v-if="
                    userInfo &&
                    userInfo.allow_change_priority_on_new &&
                    !preExisting
                  "
                  cols="6"
                >
                  <PrioritySelect v-model="item.priority" />
                </v-col>

                <v-col
                  v-if="workEditModalProps.template === 'default'"
                  cols="12"
                  class="text-subtitle"
                >
                  {{ $i18n.t('works.workType') }}
                </v-col>
                <v-col
                  v-if="workEditModalProps.template === 'default'"
                  cols="6"
                >
                  <FormSelectField
                    :item="item"
                    :label="$i18n.t('works.workType')"
                    :extra-filters="workTypeExtraFilters"
                    :disabled="
                      !this.userInfo.allow_change_priority_on_new || preExisting
                    "
                    search-class="work_types"
                    model="works"
                    field-name="work_type"
                    :style-attributes="textFieldFormatAttributes"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-subtitle"
                  v-if="!preExisting"
                  >{{ $i18n.t('works.workDescription') }}</v-col
                >
                <v-col
                  cols="12"
                  v-if="!preExisting"
                >
                  <v-textarea
                    v-model="item.work_description"
                    :label="
                      workEditModalProps.template === 'extra'
                        ? $i18n.t('works.workDescriptionTextForExtra')
                        : $i18n.t('works.workDescriptionText')
                    "
                    rows="4"
                    rounded
                    outlined
                    counter="2000"
                    maxlength="2000"
                    :disabled="preExisting"
                  />
                </v-col>

                <v-col
                  v-if="workEditModalProps.template === 'extra' && !preExisting"
                  cols="12"
                  class="text-subtitle"
                >
                  {{ $i18n.t('works.inspection_description') }}
                </v-col>
                <v-col
                  v-if="workEditModalProps.template === 'extra' && !preExisting"
                  cols="12"
                >
                  <v-textarea
                    v-model="item.inspection_description"
                    :label="$i18n.t('works.inspectionDescriptionText')"
                    rows="2"
                    maxlength="60"
                    counter="60"
                    outlined
                    rounded
                    :disabled="preExisting"
                  />
                </v-col>

                <v-col
                  v-if="workEditModalProps.template === 'extra'"
                  cols="12"
                >
                  <WorkItems
                    model-name="spare_part_items"
                    :work="item"
                  />
                </v-col>
                <v-col
                  v-if="workEditModalProps.template === 'extra'"
                  cols="12"
                >
                  <WorkItems
                    model-name="expense_items"
                    :work="item"
                  />
                </v-col>

                <v-col
                  v-if="userInfo && userInfo.allow_add_attachment"
                  cols="12"
                  class="text-subtitle"
                >
                  {{ $i18n.t('works.addPictureInfoText') }}
                </v-col>

                <v-col cols="12">
                  <ImagesEdit
                    v-model="item.images"
                    :parent="item"
                    for-field-name="images"
                    :images-ready="imagesReady"
                    v-on:ready="imagesReady = arguments[0]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="buttons-row">
          <v-col cols="6">
            <v-btn
              color="#8cc477"
              :dark="!disabled"
              :disabled="disabled"
              v-bind="popupButtonFormatAttributes"
              @click="save"
            >
              {{ $i18n.t('general.saveChanges') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="#666"
              block
              outlined
              v-bind="popupButtonFormatAttributes"
              @click="showDialog = false"
            >
              {{ $i18n.t('general.cancelAndCloseForm') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import FormSelectField from '@/components/Shared/FormSelectField'
import allMethods from '@/components/allMethods'
import PrioritySelect from '@/components/Shared/PrioritySelect'
import ImagesEdit from '@/components/Shared/ImagesEdit'
import api from '@/store/api'
import productInstancesQueries from '@/components/ProductInstances/productInstancesQueries'
import worksQueries from '@/components/Works/worksQueries'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'
import WorkItems from '@/components/Works/WorkItems/WorkItems'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'WorkEdit',

  data() {
    return {
      loading: false,
      item: {},
      imagesReady: true,
    }
  },

  components: {
    WorkItems,
    ImagesEdit,
    PrioritySelect,
    FormSelectField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    'item.product_instance': {
      handler() {
        this.setProductInstanceTypeFromProductInstance()
      },
      deep: true,
    },

    'item.activity_park': {
      handler() {
        this.item.product_instance = null
      },
      deep: true,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    preExisting() {
      // We should hide most fields in the editor when editing a previously saved work.
      const { id } = this.workEditModalProps
      return !!id && id !== 'new'
    },

    workItemsDataIsValid() {
      return (
        this.item.spare_part_items.filter(
          (item) =>
            !item.quantity || !parseFloat(item.quantity) || !item.spare_part
        ).length === 0 &&
        this.item.expense_items.filter(
          (item) =>
            !item.quantity || !parseFloat(item.quantity) || !item.expense
        ).length === 0 &&
        this.item.work_cost_items.filter(
          (item) =>
            !item.quantity || !parseFloat(item.quantity) || !item.cost_type
        ).length === 0
      )
    },

    requiredFieldsFilled() {
      return (
        (!!this.item.error_description || !!this.item.fault_description) &&
        !!this.item.activity_park
      )
    },

    activityPark() {
      return this.workEditModalProps.activityPark
    },

    disabled() {
      return !(this.imagesReady && this.requiredFieldsFilled)
    },

    id() {
      return this.workEditModalProps.id
    },

    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    productInstancesExtraFilters() {
      const filters = {
        _in_operation: true,
      }
      if (this.item.activity_park && this.item.activity_park.id) {
        filters._activity_park_id = this.item.activity_park.id
      }
      if (
        this.item.product_instance_type &&
        this.item.product_instance_type.id
      ) {
        filters._product_instance_type_id = this.item.product_instance_type.id
      }
      return filters
    },

    workTypeExtraFilters() {
      const filters = {
        _selectable_for_new: true,
      }
      if (!this.userInfo.allow_change_priority_on_new) {
        filters._identifier = 'CUSTOMER REPORT'
      }
      return filters
    },
  },

  created() {
    this.loading = true
    const queries = worksQueries.forEdit
    api.getItemInfoForEdit('works', this.id, queries, {}).then((response) => {
      this.$store
        .dispatch('globalErrorDisplay', response)
        .then((errorsFound) => {
          if (!errorsFound) {
            this.item = response.item
            if (!this.userInfo.allow_change_priority_on_new) {
              this.item.work_type = {
                id: this.workTypeCustomerReport.id,
                summary:
                  this.workTypeCustomerReport[`summary_${this.$i18n.locale}`],
              }
            }
            // Set Activity Park if form open from Activity Parks list
            if (this.activityPark && this.activityPark.id) {
              this.item.activity_park = {
                id: this.activityPark.id,
                summary: this.activityPark.summary,
              }
              // Set work type when Extra work
              if (this.workEditModalProps.template === 'extra') {
                this.item.work_type = {
                  id: this.workTypeIds.extra,
                }
              }
            }
          } else {
            this.showDialog = false
          }
          this.loading = false
        })
    })
  },

  methods: {
    ...allMethods,

    // Set Work product_instance_type from Work ProductInstance product_instance_type
    // If Work product_instance type is not set yet
    setProductInstanceTypeFromProductInstance() {
      if (!this.item.product_instance || !this.item.product_instance.id) {
        return
      }
      // Get ProductInstance product_instance_type
      api
        .getItemInfo('product_instances', this.item.product_instance.id, [
          'product_instance_type summary',
        ])
        .then((response) => {
          if (
            response &&
            response.item &&
            response.item.product_instance_type &&
            !this.item.product_instance_type
          ) {
            this.$set(this.item, 'product_instance_type', {
              id: response.item.product_instance_type.id,
              summary: response.item.product_instance_type.summary,
            })
            this.reRenderForm()
          }
        })
    },

    save() {
      if (!this.workItemsDataIsValid) {
        alert(this.$i18n.t('general.pleaseFillAllRequiredFields'))
        return
      }
      this.loading = true
      if (!this.item.id && !this.item.registration_date) {
        this.item.registration_date = moment().format('YYYY-MM-DD')
      }
      if (this.userInfo.person) {
        if (this.userInfo.person.ifs_person_identifier && !this.item.id) {
          this.item.reporter_ifs_identifier =
            this.userInfo.person.ifs_person_identifier
        }
        if (this.userInfo.person.name && !this.item.id) {
          this.item.reporter_name = this.userInfo.person.name
        }
      }
      this.item.api_param = this.workEditModalProps.template
      if (this.activityPark && this.activityPark.organization_id) {
        this.item.organization = {
          '@class': 'Organization',
          id: this.activityPark.organization_id,
        }
      }

      // As far as I can tell, we never edit work cost items via WorkEdit.  However,
      // their presence in payload can cause issues with the maintenance role, maybe
      // others as well.
      const saveable = JSON.parse(JSON.stringify(this.item))
      delete saveable.work_cost_items
      this.$store
        .dispatch('saveItem', {
          model: 'works',
          item: saveable,
          queries: worksQueries.forList,
          showSaveMessage: true,
        })
        .then((savedItem) => {
          if (savedItem && savedItem.id) {
            this.showDialog = false
            this.workEditModalProps.callback(savedItem)
          }
          this.loading = false
        })
    },

    reRenderForm() {
      this.loading = true
      this.$nextTick(() => {
        this.loading = false
      })
    },
  },
}
</script>
