<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <InspectionActivityParkInfo
          :read-only="true"
        />
        <InspectionWorks
          :title="$i18n.t('inspection.activeWorksTitle').toUpperCase()"
          :state-ids="activeWorkStateIds"
          :read-only="true"
        />
        <InspectionWorks
          :title="$i18n.t('inspection.extraWorksTitle').toUpperCase()"
          color="green"
          :filter-work-type-ids="extraWorkTypeIds"
          :state-ids="extraWorkStateIds"
          :read-only="true"
          :created-at="startOfToday()"
          work-form-template="extra"
        />
        <InspectionTasks
          :read-only="true"
        />
        <InspectionProductInstances
          :title="$i18n.t('inspection.extraWorksTitle').toUpperCase()"
          color="green"
          :state-ids="activeWorkStateIds"
          :read-only="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <InspectionFollowUpWork />
      </v-col>
      <v-col cols="12">
        <InspectionSummary />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          :loading="saving"
          rounded
          color="#8cc477"
          dark
          class="elevation-0 mr-5"
          @click="finishInspection"
        >
          {{ $i18n.t('inspection.finish') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import InspectionActivityParkInfo from '@/components/Inspection/InspectionActivityParkInfo'
import InspectionWorks from '@/components/Inspection/InspectionWorks'
import InspectionTasks from '@/components/Inspection/InspectionTasks'
import InspectionProductInstances from '@/components/Inspection/InspectionProductInstances'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import api from '@/store/api/'
import InspectionFollowUpWork from '@/components/Inspection/InspectionFollowUpWork'
import InspectionSummary from '@/components/Inspection/InspectionSummary'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'InspectionStep4',

  data () {
    return {
      saving: false,
      activeWorkStateIds: [
        state.workStateIds.new,
        state.workStateIds.proposed,
        state.workStateIds.shoppingCart,
        state.workStateIds.ordered,
      ],
      extraWorkTypeIds: [
        state.workTypeIds.extra,
      ],
      extraWorkStateIds: [
        state.workStateIds.new,
        state.workStateIds.proposed,
        state.workStateIds.shoppingCart,
        state.workStateIds.ordered,
        state.workStateIds.completed,
      ],
    }
  },

  components: {
    InspectionSummary,
    InspectionFollowUpWork,
    InspectionProductInstances,
    InspectionTasks,
    InspectionWorks,
    InspectionActivityParkInfo,
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    finishInspection () {
      this.saving = true
      this.createWorksFromSelectedTasks().then(() => {
        this.createFollowUpWork().then(followUpWorkResponse => {
          if (!followUpWorkResponse) {
            this.saving = false
            return
          }
          const inspectionWork = {
            id: this.inspection.work.id,
            execution_time_string: (this.inspection.hours || '0') + ':' + (this.inspection.minutes || '00'),
            inspection_description: this.inspection.description || '',
            completion_date: moment().format('YYYY-MM-DD'),
            api_param: 'inspection',
          }
          api.saveItem('works', inspectionWork, [], {}).then(response => {
            const id = response && response.data && response.data.item && response.data.item.id
            if (id) {
              this.$store.dispatch('addAppMessage', {
                type: 'success',
                message: this.$i18n.t('general.saved'),
              })
              this.$router.push({ path: '/' })
              window.scrollTo(0, 0)
            } else {
              this.$store.dispatch('globalErrorDisplay', response)
              this.saving = false
            }
          })
        })
      })
    },

    createFollowUpWork () {
      return new Promise(resolve => {
        const item = this.newWorkTemplate()
        item.priority = this.inspection.followUpWork.priority
        item.work_description = this.inspection.followUpWork.description
        if (item.priority === null && item.work_description.length === 0) {
          // Empty followUpWork is not created
          resolve(true)
          return
        }
        item.error_description = this.$i18n.t('inspection.followUpWorkTitle')
        item.api_param = 'long_term_plan'
        item.work_type = {
          id: this.workTypeIds.actionPlan,
        }
        api.saveItem('works', item, [], {}).then(response => {
          const id = response && response.data && response.data.item && response.data.item.id
          if (id) {
            resolve(response)
          } else {
            this.$store.dispatch('globalErrorDisplay', response)
            resolve(false)
          }
        })
      })
    },

    createWorksFromSelectedTasks () {
      const tasks = this.inspection.tasks
      return new Promise(resolve => {
        const promises = []
        Object.keys(tasks).forEach(taskKey => {
          if (tasks[taskKey]) {
            promises.push(this.createWorkFromTask(taskKey))
          }
        })
        Promise.all(promises).then(responses => {
          resolve(responses)
        })
      })
    },

    createWorkFromTask (taskKey) {
      return new Promise(resolve => {
        const task = this.taskWorkSaveData(taskKey)
        task.api_param = 'task'
        api.saveItem('works', task, [], {}).then(response => {
          const id = response && response.data && response.data.item && response.data.item.id
          if (id) {
            resolve(response)
          } else {
            resolve({})
          }
        })
      })
    },

    newWorkTemplate () {
      const item = {
        '@class': 'Work',
        reporter_ifs_identifier: this.userInfo.person.ifs_person_identifier,
        reporter_name: this.userInfo.person.name,
      }
      if (this.inspection.work.organization_id) {
        item.organization = {
          id: this.inspection.work.organization_id,
        }
      }
      if (this.inspection.work.activity_park) {
        item.activity_park = {
          id: this.inspection.work.activity_park.id,
        }
      }
      return item
    },

    taskWorkSaveData (taskKey) {
      const item = this.newWorkTemplate()
      item.api_param = 'task'
      item.error_description = this.$i18n.t('inspection.taskErrorDescription')
      item.work_description = `${this.$i18n.t('inspection.taskWorkDescriptionPrefix')} ${this.$i18n.t('inspection.tasks.' + taskKey)}`
      item.work_type = {
        id: this.workTypeIds.task,
      }
      return item
    },

    startOfToday () {
      return moment().startOf('day').format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
