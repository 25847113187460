import Vue from 'vue'
import api from '@/store/api'

export default {
  addNewFile: ({ state, dispatch }, {
    file, files, forObjectClass, forFieldName,
    forObjectId, attachmentType,
  }) => {
    state.showLoader = true
    return new Promise(resolve => {
      api.saveAttachment({
        forObjectClass,
        forFieldName,
        forObjectId,
        attachmentType,
        file,
      }).then(response => {
        dispatch('globalErrorDisplay', response).then(errorsFound => {
          if (!errorsFound && files) {
            const newImage = (response.items && response.items[0]) || {}
            Vue.set(files, files.length, {
              '@class': 'Attachment',
              id: newImage.id,
              filename: newImage.filename
            })
            resolve(true)
          } else {
            resolve(false)
          }
          state.showLoader = false
        })
      })
    })
  },

  deleteFile: ({ state, dispatch }, { index, files }) => {
    return new Promise(resolve => {
      if (confirm('Delete?')) {
        const id = files[index].id
        state.showLoader = true
        api.deleteItem('attachments', id).then(() => {
          Vue.delete(files, index)
          state.showLoader = false
          resolve(true)
        })
      } else {
        resolve(false)
      }
    })
  },
}
