<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-subtitle">
        {{ $i18n.t('activityParks.play_areas') }}
      </v-col>
      <v-col cols="12">
        <v-container>
          <v-row
            v-for="(item, index) in activityPark.play_areas"
            :key="index"
          >
            <v-col cols="3">
              <v-text-field
                v-model="activityPark.play_areas[index].number"
                :label="$t('playAreas.number')"
                :key="index"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="7">
              <v-textarea
                v-model="activityPark.play_areas[index].description"
                :label="$t('playAreas.description')"
                :key="index"
                rows="3"
                v-bind="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="2" class="text-center align-self-center">
              <v-btn @click="deleteByIndex(index)" icon>
                <v-icon>fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12">
        <v-btn
          rounded
          class="elevation-0"
          @click="addNewPlayArea"
        >
          {{ $i18n.t('general.add') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'PlayAreas',

  props: {
    activityPark: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    addNewPlayArea () {
      this.$store.dispatch('addNewChildFor', {
        forItem: this.activityPark,
        childModel: 'play_areas',
        queries: ['description', 'number'],
      }).then(() => {
        this.autoPopulateRecentlyAddedPlayAreaNumber()
      })
    },

    autoPopulateRecentlyAddedPlayAreaNumber () {
      let maxNumber = 0
      this.activityPark.play_areas.forEach(playArea => {
        if (playArea.number && parseInt(playArea.number) > maxNumber) { maxNumber = parseInt(playArea.number) }
      })
      this.$set(this.activityPark.play_areas[this.activityPark.play_areas.length - 1], 'number', maxNumber + 1)
    },

    deleteByIndex (index) {
      this.$delete(this.activityPark.play_areas, index)
    },
  },
}
</script>
