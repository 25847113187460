const activityParksDefaultFilters = {
  ids: null,
  something_date_month: null,
  something_date_year: null,
  something_type: null,
  type: null,
  inspectedInLastYear: false,
  responsiblePeople: [],
  activity_park_type: [],
  work_type: [],
  work_priority: [],
}

const activityParksListDefaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ['created_at'],
  sortDesc: [true],
}

const activityParkEditModalDefaultProps = {
  dialog: false,
  id: null,
  activityPark: null,
  callback: null,
  imagesOnly: false,
}

const activityParkFeedbackModalDefaultProps = {
  dialog: false,
  id: null,
  callback: null,
}

export default {
  mapView: false,
  mapExpanded: false,
  activityParksItems: [],
  activityParksItemsTotal: 0,
  activityParksAllIds: [],

  activityParkEditModalDefaultProps: JSON.parse(JSON.stringify(activityParkEditModalDefaultProps)),
  activityParkEditModalProps: JSON.parse(JSON.stringify(activityParkEditModalDefaultProps)),

  activityParkFeedbackModalDefaultProps: JSON.parse(JSON.stringify(activityParkFeedbackModalDefaultProps)),
  activityParkFeedbackModalProps: JSON.parse(JSON.stringify(activityParkFeedbackModalDefaultProps)),

  activityParkItemForEdit: {},
  activityParksLoading: true,
  activityParksListDefaultOptions: activityParksListDefaultOptions,
  activityParksDefaultFilters: activityParksDefaultFilters,
  activityParksListOptions: JSON.parse(JSON.stringify(activityParksListDefaultOptions)),
  activityParksFilters: JSON.parse(JSON.stringify(activityParksDefaultFilters)),
  activityParksOldState: {},

  showActivityParksSearchForm: false,

  showActivityParkEditModal: false,
  showActivityParkDeleteModal: false,

  selectedActivityPark: null,
  activityParksResultSetFilters: null
}
