import apiRequests from '@/store/api/apiRequests'
import deleteApi from '@/store/api/deleteApi'
import getApi from '@/store/api/getApi'
import saveApi from '@/store/api/saveApi'
import sessionApi from '@/store/api/sessionApi'
import helper from '@/helper'

const exports = {
  ...apiRequests,
  ...deleteApi,
  ...getApi,
  ...saveApi,
  ...sessionApi,

  loadHasManyListFor (className, fieldName, forClass, forId, queries, fetchCount) {
    const filters = [{
      key: 'existing',
      value: 'true'
    }, {
      key: 'limit',
      value: fetchCount
    }]
    return this.sendRequest('/api/' + helper.objectClassUnderscoredName(className) + '/for/' + forClass + '/' +
      forId + '/' + fieldName, filters, queries
    )
  },
}
export default exports
