<template>
  <v-container fluid class="productInstances-list">
    <ListTopPagination
      v-if="!modal"
      v-model="productInstancesListOptions.page"
      :total-pages="pages"
      model="productInstances"
    />
    <v-progress-linear
      v-if="productInstancesLoading && !modal"
    />
    <v-container fluid class="items-container">
      <v-row>
        <v-col
          v-if="!readOnly && userInfo.allow_edit_product_instances"
          cols="12" md="6" lg="4" xl="3"
          @click="addNew"
        >
          <v-container class="list-item elevation-3 fill-height add-new-button">
            <v-row class="align-center text-center">
              <v-col cols="12">
                <v-icon>fa-plus</v-icon>
                <br><br>
                <span class="add-title">
                  {{ $i18n.t('activityParks.addNewEquipment') }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          v-for="(item, i) in productInstancesItemsComputed"
          :key="i"
          cols="12" md="6" lg="4" xl="3"
        >
          <ProductInstancesListItem
            :item="item"
            :get-list-items="getListItems"
            :read-only="readOnly"
            :is-inspection="isInspection"
            :load-inspection-product-instances="loadInspectionProductInstances"
          />
        </v-col>
      </v-row>
    </v-container>
    <ListBottomPagination
      v-if="!modal"
      v-model="productInstancesListOptions.page"
      :total-pages="pages"
    />
  </v-container>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import ProductInstancesListItem from '@/components/ProductInstances/ProductInstancesListItem'
import ListTopPagination from '@/components/Shared/ListTopPagination'
import ListBottomPagination from '@/components/Shared/ListBottomPagination'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ProductInstancesList',

  data () {
    return {
      model: 'productInstances',
    }
  },

  components: {
    ListBottomPagination,
    ListTopPagination,
    ProductInstancesListItem,
  },

  props: {
    isInspection: {
      type: Boolean,
      default: false,
    },
    inspectionProductInstances: {
      type: Array,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showNewInspectionProductInstanceForm: {
      type: Function,
      default: () => {},
    },
    loadInspectionProductInstances: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    productInstancesItemsComputed () {
      if (this.isInspection) {
        return this.inspectionProductInstances
      }
      return this.productInstancesItems
    },

    pages () {
      return Math.ceil(this.productInstancesItemsTotal / this.productInstancesListOptions.itemsPerPage)
    },
  },

  methods: {
    ...allMethods,

    addNew () {
      this.isInspection ? this.showNewInspectionProductInstanceForm() : this.showAddNewForm()
    },
  },
}
</script>

<style lang="scss">
.productInstances-list {
  padding: 0 15px 12px 15px;
  .items-container {
    padding: 0 15px;
  }
}
</style>
