export default {
  '@class': 'string',
  id: 'number',
  token: 'string',
  api_param: 'string',
  activity_park: 'reference',
  error_description: 'string',
  images: 'array',
  organization: 'reference',
  priority: 'string',
  product_instance: 'reference',
  registration_date: 'date',
  work_description: 'string',
  inspection_description: 'string',
  reporter_ifs_identifier: 'string',
  reporter_name: 'string',
  work_type: 'reference',
  standard: 'reference',
  fault_description: 'reference',
  main_object_state: 'reference',
  shown_on_report: 'string',
  work_cost_items: 'has-many',
  expense_items: 'has-many',
  spare_part_items: 'has-many',
  shopping_cart_person: 'reference',
}
