<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <InspectionWorks
          :add-new="true"
          :filter-work-type-ids="extraWorkTypeIds"
          :state-ids="extraWorkStateIds"
          :created-at="startOfToday()"
          work-form-template="extra"
          :title="$i18n.t('inspection.extraWorksTitle').toUpperCase()"
          color="green"
        />
      </v-col>
      <v-col cols="12">
        <InspectionTasks />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import InspectionWorks from '@/components/Inspection/InspectionWorks'
import InspectionTasks from '@/components/Inspection/InspectionTasks'
import state from '@/store/state'

export default {
  name: 'InspectionStep2',

  components: { InspectionTasks, InspectionWorks },

  data () {
    return {
      extraWorkTypeIds: [
        state.workTypeIds.extra,
      ],
      extraWorkStateIds: [
        state.workStateIds.new,
        state.workStateIds.proposed,
        state.workStateIds.shoppingCart,
        state.workStateIds.ordered,
        state.workStateIds.completed,
      ],
    }
  },

  methods: {
    startOfToday () {
      return moment().startOf('day').format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
