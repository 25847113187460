import api from '@/store/api'
import workOrdersQueries from '@/components/WorkOrders/workOrdersQueries'

export default {
  getWorkOrdersListItems () {
    this.workOrdersLoading = true
    window.scrollTo(0, 0)
    this.$store.dispatch('getListItems', {
      model: 'workOrders',
      filters: this.getWorkOrdersListFilters(),
      queries: workOrdersQueries.forList,
    }).then(() => {
      this.workOrdersLoading = false
    })
  },

  getWorkOrdersListFilters () {
    return this.getListGeneralFilters('workOrders')
  },
}
