<template>
  <v-dialog
    v-model="showUserPasswordForm"
    overlay-opacity="1"
    overlay-color="white"
    max-width="800"
  >
    <v-toolbar color="grey elevation-0" dark>
      <v-icon class="mx-5">fa-key</v-icon>
      <v-toolbar-title>{{ $i18n.t('change_password.title') }}</v-toolbar-title>
    </v-toolbar>
    <v-container class="white pa-5">
      <v-row>
        <v-col cols="12">
          <!-- dummy input to prevent browser focusing global search field when auto populating user+pass -->
          <div style="position: absolute; top: -10000px;">
            <input type="text" name="dummy" />
          </div>
          <v-text-field
            v-model="current_password"
            :label="$i18n.t('change_password.old_password')"
            type="password"
            v-bind="textFieldAttributes"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="password"
            :label="$i18n.t('change_password.new_password')"
            type="password"
            v-bind="textFieldAttributes"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="password_confirmation"
            :label="$i18n.t('change_password.new_password_again')"
            type="password"
            :clearable="false"
            v-bind="textFieldAttributes"
          />
        </v-col>
        <v-col cols="6">
          <v-btn
            color="#8cc477"
            :dark="!disabled"
            :disabled="disabled"
            :loading="saving"
            v-bind="popupButtonFormatAttributes"
            @click="changePassword"
          >{{ $i18n.t('change_password.submit') }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="#666"
            block
            outlined
            @click="showUserPasswordForm = false"
            v-bind="popupButtonFormatAttributes"
          >{{ $i18n.t('general.cancelAndCloseForm') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import api from '@/store/api'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ChangePassword',

  data () {
    return {
      saving: false,
      current_password: '',
      password: '',
      password_confirmation: '',
      textFieldAttributes: {
        outlined: true,
        color: '#666',
        rounded: true,
        hideDetails: true,
        dense: false,
        clearable: false,
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    disabled () {
      return !(this.current_password && this.password && this.password_confirmation)
    }
  },

  methods: {
    changePassword () {
      this.saving = true
      api.changePassword(this.current_password, this.password, this.password_confirmation).then(response => {
        if (response && response.status === 'ok' && response.message) {
          const message = this.$i18n.t(response.message.replace('aava:', ''))
          this.$store.dispatch('showMessage', {
            message,
            type: 'success'
          })
          this.showUserPasswordForm = false
        } else {
          let message = 'Error'
          if (response.data && response.data.messages && response.data.messages[0]) {
            message = this.$i18n.t(response.data.messages[0].key.replace('aava:', ''))
          }
          this.$store.dispatch('showMessage', {
            message,
            type: 'error'
          })
        }
        this.saving = false
      })
    }
  },
}
</script>
