<template>
  <v-container
    fluid
    :style="mapExpanded ? '' : 'max-width: 1600px;'"
    class="activity-parks body-container pa-0 elevation-3"
  >
    <ListSearchToolbar
      v-if="!mapExpanded"
      model="activityParks"
      @change="updateList"
    />
    <ListTitleToolbar
      v-if="!mapExpanded"
      model="activityParks"
    />
    <Map v-if="mapView" />
    <ActivityParksList v-else />
    <v-dialog
      width="800"
      v-model="showActivityParkDeleteModal"
    >
      <ItemDeleteModalWithReasons
        v-if="showActivityParkDeleteModal"
        model="activityParks"
      />
    </v-dialog>
    <v-dialog
      width="1000"
      v-model="showActivityParksSearchForm"
    >
      <ActivityParksSearchForm />
    </v-dialog>
  </v-container>
</template>

<script>
import ListSearchToolbar from '@/components/Shared/ListSearchToolbar'
import ListTitleToolbar from '@/components/Shared/ListTitleToolbar'
import ActivityParksList from '@/components/ActivityParks/ActivityParksList'
import ActivityParksSearchForm from '@/components/ActivityParks/ActivityParksSearchForm'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
import ItemDeleteModalWithReasons from '@/components/Shared/ItemDeleteModalWithReasons'
import Map from '@/components/ActivityParks/Map'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParks',

  data () {
    return {
      model: 'activityParks',
    }
  },

  components: {
    Map,
    ItemDeleteModalWithReasons,
    ActivityParksSearchForm,
    ActivityParksList,
    ListTitleToolbar,
    ListSearchToolbar,
  },

  mounted () {
    // this.getListItems()
  },

  created () {
    if (!this.activityParksResultSetFilters) {
      this.updateList()
    }
  },

  watch: {
    listOptions () {
      this.updateList()
    },

    oldFilters (current) {
      if (!current) {
        this.updateList()
      }
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    listOptions () {
      return JSON.stringify(this.activityParksListOptions)
    },

    oldFilters () {
      return this.activityParksResultSetFilters
    },

    currentFilters () {
      return JSON.stringify({
        globalFilters: this.globalFilters,
        activityParksListOptions: this.activityParksListOptions
      })
    }
  },

  methods: {
    ...allMethods,

    updateList () {
      this.getListItems().then(() => {
        this.activityParksResultSetFilters = this.currentFilters
      })
    }
  },
}
</script>
