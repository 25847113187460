<template>
  <v-toolbar
    color="white"
    class="main-toolbar"
    height="70"
  >
    <v-container
      fluid
      style="max-width: 1560px; position: relative"
      class="pa-0"
    >
      <div class="toolbar-bck-btn">
        <v-btn
          icon
          @click="goBack"
        >
          <v-icon>fa-arrow-circle-left</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters>
        <v-col
          cols="12"
          class="align-self-center text-center"
        >
          <v-btn
            v-for="(button, index) in menuItems.filter(
              (menuItem) => menuItem.show
            )"
            :key="index"
            :color="isSelected(button) ? '#8cc477' : '#808285'"
            :text="!isSelected(button)"
            :dark="isSelected(button)"
            :class="
              (isSelected(button) ? 'menu-item-selected' : '') +
              ' mr-2 menu-item elevation-0'
            "
            :to="button.route"
            @click="onClick(button)"
          >
            <v-icon class="mr-3">
              {{ button.icon }}
            </v-icon>
            <template v-if="$vuetify.breakpoint.lgAndUp">
              {{ button.name.toUpperCase() }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer />
    </v-container>
  </v-toolbar>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'Toolbar',

  computed: {
    ...mapFields(Object.keys(state)),

    menuItems() {
      return [
        {
          name: this.$i18n.t('general.home'),
          icon: 'fa-home',
          show: true,
          route: '/',
        },
        {
          name: this.$i18n.t('activityParks.title'),
          icon: 'fa-map-marker-alt',
          show: true,
          route: '/parks',
        },
        {
          name: this.$i18n.t('productInstances.title'),
          icon: 'fa-child',
          show: true,
          route: '/equipments',
        },
        {
          name: this.$i18n.t('works.title'),
          icon: 'fa-exclamation-triangle',
          show: true,
          route: '/works',
        },
        {
          name: this.$i18n.t('workOrders.title'),
          icon: 'fa-tasks',
          show: this.userInfo && this.userInfo.allow_ordering,
          route: '/work_orders',
        },
        {
          name: this.$i18n.t('organizations.title'),
          icon: 'fa-building',
          show: this.userInfo && this.userInfo.allow_list_organizations,
          route: '/organizations',
        },
        {
          name: this.$i18n.t('ratings.title'),
          icon: 'fa-star',
          show: this.userInfo && this.userInfo.ratings_visible,
          route: '/ratings',
        },
      ]
    },
  },

  methods: {
    isSelected(button) {
      return button.route === this.$route.path
    },

    goBack() {
      window.history.go(-1)
    },

    onClick(button) {
      // The activity parks view has special magic to support back button.
      if (button.route === '/parks') {
        this.activityParksResultSetFilters = null
        this.activityParksListOptions.page = 1
      }
    },
  },
}
</script>

<style lang="scss">
.main-toolbar {
  position: fixed !important;
  top: 80px !important;
  width: 100%;
  padding: 0 20px;
  z-index: 3;
  box-shadow: 0 3px 3px -2px rgba(100, 100, 100, 0.2),
    0 3px 4px 0 rgba(100, 100, 100, 0.24), 0 1px 8px 0 rgba(100, 100, 100, 0.22) !important;
  @media (min-width: 1264px) {
    .container {
      max-width: 1785px;
    }
  }
  .toolbar-bck-btn {
    position: absolute;
    left: 0;
    top: -4px;
  }
  .menu-item-selected {
    /* color: #8cc477 !important; */
  }
  .menu-item {
    .v-icon {
      font-size: 18px !important;
    }
    .v-btn__content {
      font-weight: 700 !important;
      font-size: 16px !important;
      letter-spacing: 0 !important;
    }
  }
}
</style>
