<template>
  <v-container class="elevation-3">
    <v-row class="shopping-cart">
      <v-col cols="12" class="cart-title align-self-center">
        <v-icon class="pr-3">fa-shopping-cart</v-icon>
        {{ $i18n.t('shoppingCart.title').toUpperCase() }}
      </v-col>
      <v-col cols="12">
        <v-spacer />
      </v-col>
      <template v-if="orderItems.length > 0">
        <v-col cols="12" class="title-2">
          {{ $i18n.t('shoppingCart.sendOrder').toUpperCase() }}
        </v-col>
        <v-col cols="12" class="mx-0 px-0">
          <ShoppingCartItems
            :items="orderItems"
            :saving="saving"
          />
        </v-col>
        <v-col cols="12">
          <b><u>
            {{ $i18n.t('shoppingCart.extraInfo') }}
          </u></b>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="orderText"
            :label="$i18n.t('shoppingCart.orderExtraInfo')"
          >
            ....
          </v-textarea>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            rounded
            :dark="!saving"
            class="elevation-0"
            :color="greenColor"
            :loading="saving === 'orders'"
            :disabled="!!saving"
            @click="sendOrders('orders')"
          >
            {{ $i18n.t('shoppingCart.sendOrder') }}
          </v-btn>
        </v-col>
      </template>
      <template v-if="offerItems.length > 0">
        <v-col cols="12" class="title-2">
          {{ $i18n.t('shoppingCart.sendOffer').toUpperCase() }}
        </v-col>
        <v-col cols="12" class="mx-0 px-0">
          <ShoppingCartItems
            :items="offerItems"
            :saving="saving"
          />
        </v-col>
        <v-col cols="12">
          <b><u>
            {{ $i18n.t('shoppingCart.extraInfo') }}
          </u></b>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            v-model="offerText"
            :label="$i18n.t('shoppingCart.offerExtraInfo')"
          >
            ....
          </v-textarea>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            outlined
            rounded
            color="#666"
            class="elevation-0"
            :loading="saving === 'offers'"
            :disabled="!!saving"
            @click="sendOrders('offers')"
          >
            {{ $i18n.t('shoppingCart.sendOffer') }}
          </v-btn>
        </v-col>
      </template>
      <v-col v-if="offerItems.length === 0 && orderItems.length === 0">
        <h4>{{ $i18n.t('shoppingCart.empty') }}</h4>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      width="600"
      hide-overlay
    >
      <v-card>
        <v-container
          class="shopping-cart-dialog"
        >
          <v-row>
            <v-col cols="9" class="dialog-text-1">
              {{ dialogText1 }}
            </v-col>
            <v-col cols="3" class="text-right pr-5">
              <v-btn
                icon
                @click="showDialog = false"
              >
                <v-icon>fa-times</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-icon large>fa-envelope</v-icon>
            </v-col>
            <v-col cols="10" class="align-self-center">
              {{ dialogText2 }}
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ShoppingCartItems from '@/components/ShoppingCart/ShoppingCartItems'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ShoppingCart',

  data () {
    return {
      saving: '',
      showDialog: false,
      dialogText1: '',
      dialogText2: '',
      orderText: '',
      offerText: '',
    }
  },

  components: { ShoppingCartItems },

  computed: {
    ...mapFields(Object.keys(state)),

    offerItems () {
      return this.shoppingCartItems.filter(item => !item.cost || (item.cost && parseInt(item.cost)) === 0)
    },

    orderItems () {
      return this.shoppingCartItems.filter(item => item.cost > 0)
    },
  },

  created () {
    window.scrollTo(0, 0)
  },

  methods: {
    ...allMethods,

    sendOrder (organizationId, items, type) {
      return new Promise(resolve => {
        const item = {
          '@class': type === 'orders' ? 'WorkOrder' : 'WorkOffer',
          organization: {
            '@class': 'Organization',
            id: organizationId,
          },
          person: {
            '@class': 'Person',
            id: this.userInfo.person.id,
          },
          additional_info: type === 'orders' ? this.orderText : this.offerText
        }
        if (type === 'orders') {
          item.order_date = moment().format('YYYY-MM-DD')
          item.works = items.map(work => {
            return {
              '@class': 'Work',
              id: work.id,
            }
          })
        } else {
          item.offer_date = moment().format('YYYY-MM-DD')
        }
        this.$store.dispatch('saveItem', {
          item,
          model: type === 'orders' ? 'work_orders' : 'work_offers',
        }).then(response => {
          const promises = []
          if (response && response.works) {
            response.works.forEach(work => {
              promises.push(this.setWorkAsOrdered(work))
            })
          }
          if (type === 'offers' && response) {
            // Works are marked as ordered when WorkOfferItems have been created
            this.sendOfferItems(response, items).then(() => {
              resolve(response)
            })
          } else {
            Promise.all(promises).then(responses => {
              resolve(response)
            })
          }
        })
      })
    },

    sendOfferItems (offer, works) {
      return new Promise(resolve => {
        const promises = []
        works.forEach(work => {
          promises.push(this.sendOfferItem(offer, work))
        })
        Promise.all(promises).then(responses => {
          resolve()
        })
      })
    },

    sendOfferItem (offer, work) {
      const item = {
        '@class': 'WorkOfferItem',
        work_offer: {
          '@class': 'WorkOffer',
          id: offer.id,
        },
        work: {
          '@class': 'Work',
          id: work.id,
        },
      }
      return new Promise(resolve => {
        this.$store.dispatch('saveItem', {
          item,
          model: 'work_offer_items',
        }).then(response => {
          this.setWorkAsOrdered(work).then(() => {
            resolve()
          })
        })
      })
    },

    sendOrders (type) {
      this.saving = type
      const promises = []
      const ordersByOrganization = this.getOrdersByOrganization(type)
      Object.keys(ordersByOrganization).forEach(organizationId => {
        const orderItems = ordersByOrganization[organizationId]
        promises.push(this.sendOrder(organizationId, orderItems, type))
      })
      Promise.all(promises).then(responses => {
        this.showDialog = true
        this.dialogText1 = this.$i18n.t('shoppingCart.' + (type === 'orders' ? 'order' : 'offer') + 'IsSent').toUpperCase()
        this.dialogText2 = this.$i18n.t('shoppingCart.' + (type === 'orders' ? 'order' : 'offer') + 'IsSent')
        this.$store.dispatch('getShoppingCartItems')
        this.saving = ''
      })
    },

    getOrdersByOrganization (type) {
      const byOrganization = {}
      const allItems = type === 'orders'
        ? this.orderItems
        : this.offerItems
      allItems.forEach(item => {
        if (!item.organization) {
          console.error('Missing organization for work', item)
          return
        }
        if (!byOrganization[item.organization.id]) {
          byOrganization[item.organization.id] = []
        }
        byOrganization[item.organization.id].push(item)
      })
      return byOrganization
    },

    clearOfferItems () {
      this.shoppingCartItems = this.shoppingCartItems.filter(item => item.priority !== 'a')
    },

    clearOrderItems () {
      this.shoppingCartItems = this.shoppingCartItems.filter(item => item.priority === 'a')
    },
  }
}
</script>

<style lang="scss">
.shopping-cart-dialog {
  color: #777;
  .dialog-text-1 {
    padding: 15px 0 0 35px !important;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
  }
}
.shopping-cart {
  background: white;
  padding: 20px !important;
  .cart-title {
    font-size: 18px;
    font-weight: 600;
  }
  .title-2 {
    font-weight: 600;
    font-size: 16px;
  }
  .v-textarea {
    border-radius: 14px !important;
  }
}
</style>
