<template>
  <v-container
    style="max-width: 1600px;"
    fluid
    class="body-container pa-0"
  >
    <ListSearchToolbar model="" />
    <v-row class="px-5 py-3">
      <v-col cols="12" lg="5">
        <HomeWelcome />
        <HomeMessages v-if="userInfo && userInfo.allow_show_announcements"/>
        <HomeRecentInspections v-if="userInfo && userInfo.allow_show_latest_events"/>
      </v-col>
      <v-col
        v-if="userInfo && userInfo.allow_show_inspection"
        cols="12"
        lg="7"
      >
        <HomeInspectionWorks />
      </v-col>
      <v-col
        v-else
        cols="12"
        lg="7"
      >
        <HomeWorks />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import state from '@/store/state'
import HomeWelcome from '@/components/Home/HomeWelcome'
import HomeRecentInspections from '@/components/Home/HomeRecentInspections'
import HomeMessages from '@/components/Home/HomeMessages'
import HomeInspectionWorks from '@/components/Home/HomeInspectionWorks'
import HomeWorks from '@/components/Home/HomeWorks'
import ListSearchToolbar from '@/components/Shared/ListSearchToolbar'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'Home',
  components: { ListSearchToolbar, HomeInspectionWorks, HomeWorks, HomeMessages, HomeRecentInspections, HomeWelcome },
  computed: {
    ...mapFields(Object.keys(state)),
  },
}
</script>
