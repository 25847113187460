<template>
  <v-container class="inspection-expansion">
    <v-row>
      <v-expansion-panels
        v-model="panel"
        accordion flat
      >
        <v-expansion-panel
          active-class="elevation-3"
        >
          <v-expansion-panel-header
            :class="'h2 ' + (!readOnly ? 'header-green' : '')"
          >
            {{ $i18n.t('inspection.maintenanceTitle').toUpperCase() }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-container
              fluid class="py-0 px-5"
            >
              <v-row>
                <v-col
                  v-if="!readOnly"
                  cols="12"
                >
                  <v-btn
                    text
                    @click="showTask = true"
                  >
                    <span class="h2">{{ $i18n.t('inspection.maintenanceTitleText').toUpperCase() }}</span>
                    <v-icon class="ml-3">fa-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-container
                  v-if="showTask"
                  class="py-0"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      v-for="(taskKey, index) in Object.keys(inspection.tasks)"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="inspection.tasks[taskKey]"
                        :disabled="readOnly || inspection.noTasksDone"
                        :label="$i18n.t('inspection.tasks.' + taskKey)"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <div class="h-spacer" />
                    </v-col>
                  </v-row>
                </v-container>
                <v-col></v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="inspection.noTasksDone"
                    :disabled="readOnly || selectedTasks.length > 0"
                    :label="$i18n.t('inspection.noMaintenanceTasks')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'InspectionTasks',

  data () {
    return {
      panel: 0, // 0 - to auto open
      showTask: false,
    }
  },

  created () {
    if (this.readOnly || this.selectedTasks.length > 0) {
      this.showTask = true
    }
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    'inspection.noTasksDone': function (value) {
      if (!value) { return }
      this.disableAllTasks()
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    selectedTasks () {
      return Object.keys(this.inspection.tasks).filter(taskKey => this.inspection.tasks[taskKey])
    },
  },

  methods: {
    disableAllTasks () {
      this.$set(this.inspection, 'tasks', JSON.parse(JSON.stringify(this.inspectionDefaultProps.tasks)))
    },
  },
}
</script>
