import moment from 'moment'

const inflect = require('i')(true)

export default {
  // Sort images
  // Sort images with order or with id if not sorted before
  // Order is new attribute and also not present for existing images
  // Function needed as API is only keeping a field 'order' but not doing the sorting of result
  sortItemImages (item) {
    if ('images' in item) {
      item.images = item.images.sort((a, b) => {
        // Sort with order?
        if (a.order !== null && b.order !== null) {
          return a.order > b.order ? 1 : -1
        }
        // Sort with id
        return a.id > b.id ? 1 : -1
      })
    }
    return item
  },

  formatPrice (cost, decimalPoints) {
    return (Math.round(cost * 100) / 100).toFixed(decimalPoints) + ' €'
  },

  objectClassUnderscoredName (className) {
    if (className.toLowerCase() === 'any') { return 'any' }
    return className ? inflect.pluralize(inflect.underscore(className)).toLowerCase() : ''
  },

  modelNameUnderscoredNameSingular: modelName => {
    if (!modelName) { return }
    if (modelName.toLowerCase() === 'any') { return 'any' }
    return modelName ? inflect.singularize(inflect.underscore(modelName)).toLowerCase() : ''
  },

  objectClassPluralName (className) {
    return inflect.camelize(inflect.pluralize(className))
  },

  objectClassPluralNameVariable (className) {
    return inflect.camelize(inflect.pluralize(className), false)
  },

  modelSingularCamelCase (model) {
    return inflect.singularize(inflect.camelize(model))
  },

  objectClassSingularName (className) {
    return inflect.camelize(inflect.singularize(className))
  },

  formatDate (date) {
    if (!date) { return }
    return moment(date).format('DD.MM.YYYY')
  },

  formatDateTime (date) {
    if (!date) { return }
    return moment(date).format('DD.MM.YYYY HH:mm')
  },
}
