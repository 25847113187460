<template>
  <v-list class="ei-menu">
    <v-list-item>
      <v-list-item-title>
        <b>
          {{ $i18n.t('general.actions') }}
        </b>
      </v-list-item-title>
      <v-list-item-icon>
        <v-icon>fa-times</v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item
      v-for="(menuItem, index) in menuItems.filter(menuItem => menuItem.show)"
      :key="index"
      @click="menuItem.action(menuItem.actionProps)"
    >
      <v-list-item-avatar class="mr-3">
        <v-icon small>{{ menuItem.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ menuItem.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParksListItemMenu',

  data () {
    return {
      model: 'activityParks',
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    callbackOnItemChange: {
      type: Function,
      default: () => {},
    },
    callbackOnItemHide: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    menuItems () {
      return [{
        name: this.$i18n.t('activityParks.changeInfoTitle'),
        icon: 'fa-edit',
        show: !this.modal,
        action: this.showActivityParkItemForEdit,
        actionProps: {
          id: this.item.id,
          callback: this.callbackOnItemChange,
        },
      }, {
        name: this.$i18n.t('activityParks.hide'),
        icon: 'fa-eye-slash',
        show: this.userInfo && this.userInfo.allow_hide_activity_park,
        action: this.hideActivityPark,
        actionProps: {
          id: this.item.id,
          callback: this.callbackOnItemHide,
        },
      }, {
        name: this.$i18n.t('activityParks.deleteTitle'),
        icon: 'fa-trash-alt',
        show: this.userInfo && this.userInfo.allow_remove_activity_park,
        action: this.deleteActivityPark,
        actionProps: this.item.id,
      }, {
        name: this.$i18n.t('activityParks.giveFeedback'),
        icon: 'fa-comment-alt',
        show: !this.modal,
        action: this.showActivityParkFeedbackModal,
        actionProps: {
          id: this.item.id,
          callback: this.callbackOnItemChange,
        },
      }]
    },
  },

  methods: {
    ...allMethods,
  },
}
</script>
