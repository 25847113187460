<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      overlay-color="white"
      overlay-opacity="0.8"
      width="700"
    >
      <v-card>
        <v-toolbar class="elevation-0" dark color="grey">
          <v-toolbar-title>
            {{ $i18n.t('privacy_policy_modal.title') }}
          </v-toolbar-title>
        </v-toolbar>

        <v-divider></v-divider>
        <v-container white class="pa-5 my-1">
          <v-row>
            <v-col cols="5" class="align-self-center">
              {{ $i18n.t('privacy_policy_modal.privacy_policy_text') }}
            </v-col>
            <v-col cols="7">
              <v-btn
                :href="'privacy_policy_' + locale + '.pdf'"
                dark
                color="grey lighten-1"
                block
                height="40"
                target="_blank"
              >
                {{ $i18n.t('privacy_policy_modal.privacy_policy_link') }}
              </v-btn>
            </v-col>
            <v-col cols="5" class="align-self-center">
              {{ $i18n.t('privacy_policy_modal.cookie_policy_text') }}
            </v-col>
            <v-col cols="7">
              <v-btn
                :href="'cookie_policy_' + locale + '.pdf'"
                dark
                color="grey lighten-1"
                block
                height="40"
                target="_blank"
              >
                {{ $i18n.t('privacy_policy_modal.cookie_policy_link') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#808285"
                text
                class="mr-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>fa-globe-africa</v-icon>
                <span class="ml-2 mr-2">
                  {{ locale.toUpperCase() }}
                </span>
                <v-icon small>fa-angle-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeLang('en')">
                <v-list-item-title>EN</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLang('fi')">
                <v-list-item-title>FI</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLang('se')">
                <v-list-item-title>SE</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            color="#8cc477"
            dark
            height="40"
            class="mx-3 my-2"
            @click="acceptCookiePolicy"
          >
            {{ $i18n.t('privacy_policy_modal.button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/store/api'

import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'CookieDialog',

  data () {
    return {
      dialog: false,
    }
  },

  created () {
    this.checkIfCookiePolicyAccepted()
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    changeLang (lang) {
      this.locale = lang
      this.$i18n.locale = lang
    },

    checkIfCookiePolicyAccepted () {
      this.dialog = !this.userInfo.privacy_policy_accepted
    },

    acceptCookiePolicy () {
      this.dialog = false
      this.$set(this.userInfo, 'privacy_policy_accepted', true)
      api.acceptCookiePolicy()
    },
  },
}
</script>
