const workOrdersListDefaultOptions = {
  page: 1,
  itemsPerPage: 20,
  sortBy: ['created_at'],
  sortDesc: [true],
}

export default {
  workOrdersItems: [],
  workOrdersItemsTotal: 0,
  workOrdersAllIds: [],

  workOrdersLoading: true,
  workOrderItemLoading: false,
  workOrdersListDefaultOptions: workOrdersListDefaultOptions,
  workOrdersListOptions: JSON.parse(JSON.stringify(workOrdersListDefaultOptions)),

  showWorkOrdersSearchForm: false,

  hideWorksFieldsInListOnTablet: [
    'work_number',
    'cost',
  ],
  workOrderStateIds: {
    ordered: 7,
    completed: 8,
    draft: 6,
  },
}
