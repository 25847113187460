<template>
  <v-container
    fluid
    style="max-width: 1600px;"
    class="body-container pa-0 elevation-3"
  >
    <ListTitleToolbar model="organizations" />
    <OrganizationsList ref="organizationsList" />
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ListTitleToolbar from '@/components/Shared/ListTitleToolbar'
import allMethods from '@/components/allMethods'
import OrganizationsList from '@/components/Organizations/OrganizationsList'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'Organizations',

  data () {
    return {
      model: 'organizations',
    }
  },

  components: {
    OrganizationsList,
    ListTitleToolbar,
  },

  watch: {
    organizationsListOptionsTrigger () {
      this.getListItems()
    },
    'organizationsListOptions.page' (now, before) {
      // When page has changed, close all expanded in Works List
      // otherwise expand-all button does not work first time
      if (now !== before) {
        this.$refs.organizationsList.closeAllExpanded()
      }
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    organizationsListOptionsTrigger () {
      return JSON.stringify(this.organizationsListOptions)
    },
  },

  created () {
    this.organizationsListOptions.page = 1
    this.getListItems()
  },

  methods: {
    ...allMethods,
  },
}
</script>
