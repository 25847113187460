<template>
  <v-dialog
    v-model="showDialog"
    content-class="elevation-0"
    overlay-color="white"
    overlay-opacity="1"
  >
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          :href="imageLinkForDownload"
          download
        >
          <v-list-item-title>{{ $i18n.t('general.downloadImage') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-container fluid class="pa-5 ma-0 elevation-10">
      <v-row>
        <v-col cols="12" class="text-right pb-3">
          <v-btn
            fab
            small
            class="elevation-0"
            @click="showDialog = false"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-carousel
            :hide-delimiters="images.length < 2"
            :show-arrows="images.length > 1"
            :height="height"
            class="rounded-12"
          >
            <v-carousel-item
              v-for="(item,i) in images"
              :key="i"
              :src="'/content/attachments/' + item.id + '/' + item.filename"
              contain
              @contextmenu="showImageDownloadMenu($event, '/content/attachments/' + item.id + '/' + item.filename)"
            />
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import allMethods from '@/components/allMethods'

export default {
  name: 'ImagesCarouselModal',

  data () {
    return {
      showMenu: false,
      imageLinkForDownload: null,
      x: 0,
      y: 0,
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    height () {
      return this.$store.state.innerHeight - 240
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
    ...allMethods,
  },
}
</script>
