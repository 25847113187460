<template>
  <v-container fill-height>
    <v-text-field
      v-model="username"
      class="mr-3"
      @keyup.enter="login"
    />
    <v-text-field
      v-model="password"
      type="password"
      class="mr-3"
      @keyup.enter="login"
    />
    <v-btn @click="login" text>Login</v-btn>
  </v-container>
</template>

<script>

/*
  In case of portal using login portal for authentication
  provide option for developer to easily login
  without setting up single domain for all portals with nginx.
*/

import api from '@/store/api'

export default {
  name: 'DeveloperLogin',

  data () {
    return {
      username: null,
      password: null
    }
  },

  methods: {
    login () {
      api.sendLoginRequest({
        name: this.username,
        password: this.password
      }).then(response => {
        if (response.status === 'ok') {
          location.reload()
        } else {
          alert(response.status)
        }
      })
    }
  }
}
</script>
