<template>
  <div class="multiselect-dropdown">
    <v-text-field
      v-model="inputString"
      :label="dynamicLabel"
      ref="input"
      hide-details
      single-line
      autocomplete="off"
      :clearable="false"
      v-bind="styleAttributes"
      @click="openMenu($event)"
      @focus="openMenu($event)"
      @keyup="openMenu($event)"
      @keyup.enter="addInputStringToArray"
      @blur="blur($event)"
    >
      <template v-slot:append>
        <div class="input-actions">
          <div
            v-if="selectedStrings.length > 0"
            class="badge green-bg"
          >
            {{ selectedStrings.length }}
          </div>
          <v-btn
            v-if="selectedStrings.length > 0"
            fab small text
            @click.stop.prevent="clearAllStrings()"
          >
            <v-icon>
              fa-times
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-text-field>
    <v-menu
      v-model="showMenu"
      :activator="$refs.input && $refs.input.$el"
      offset-y
      :close-on-content-click="false"
    >
      <div style="max-height: 400px;">
        <v-list>
          <v-list-item v-if="selectedStrings.length < 2">
            <v-list-item-title>{{ $i18n.t('general.pressEnterForMultiple') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(selectedString, index) in selectedStrings"
            :key="index"
            @click.stop="removeString(index)"
          >
            <v-list-item-action>
              <v-icon color="red">fa-times</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ selectedString }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import api from '@/store/api'

export default {
  name: 'MultiselectString',

  data () {
    return {
      showMenu: false,
      inputString: '',
      searchOption: null,
      loadingOptions: false,
      timer: null,
      menuOpeningDisabled: false,
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: '',
    },
    styleAttributes: {
      type: Object,
      default: () => {},
    },
    searchClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },

  watch: {
    showMenu () {
      // When after 2 seconds menu is closed, user has finished typing
      // If something in inputString, add text to selected strings and clear inputString
      setTimeout(() => {
        if (this.showMenu) { return }
        this.addInputStringToArray()
      }, 2000)
    },
  },

  computed: {
    selectedStrings () {
      return this.item[this.fieldName]
    },

    selectedIds () {
      return this.selectedStrings.map(item => item.id)
    },

    dynamicLabel () {
      if (this.selectedStrings.length > 0 && !this.showMenu) {
        return this.selectedStrings.join(', ')
      }
      return this.label
    },
  },

  methods: {
    addInputStringToArray () {
      if (this.inputString) {
        const strings = this.item[this.fieldName]
        this.$set(strings, strings.length, this.inputString)
        this.inputString = ''
      }
    },

    clearAllStrings () {
      this.menuOpeningDisabled = true
      this.item[this.fieldName] = []
      this.showMenu = false
      this.inputString = ''
      setTimeout(() => {
        this.menuOpeningDisabled = false
      }, 400)
    },

    openMenu (e) {
      if (e && e.key === 'Escape') { return }
      setTimeout(() => {
        if (this.menuOpeningDisabled) {
          this.$refs.input.blur()
          return
        }
        this.showMenu = true
      }, 300)
    },

    removeString (index) {
      this.$delete(this.item[this.fieldName], index)
    },

    blur (e) {
      this.addInputStringToArray()
    },
  },
}
</script>

<style lang="scss">
.multiselect-dropdown {
  .input-actions {
    margin-right: -15px;
  }
  .v-btn--fab.v-size--small {
    width: 30px;
    height: 30px;
  }
  .badge {
    float: left;
    margin-top: 2px;
    margin-right: 5px;
    line-height: 18px;
  }
}
</style>
