<template>
  <v-container
    fluid
    :class="'items-list ' + (modal ? 'items-list-modal' : '')"
  >
    <ListTopPagination
      v-if="!modal"
      v-model="worksListOptions.page"
      :total-pages="pages"
      :expand-all="expandAll"
      model="works"
    />
    <v-data-table
      :headers="headersFiltered"
      :items="worksItemsComputed"
      :expanded.sync="expanded"
      :hide-default-footer="true"
      :item-class="itemStyle"
      :options.sync="worksListOptions"
      :loading="modal ? false : worksLoading"
      :loading-text="$i18n.t('works.loading')"
      :server-items-length="worksItems.length"
      item-key="id"
      show-expand
      class="elevation-1"
      @click:row="rowClick"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <WorksListItemExpanded
          v-if="headers"
          :work="item"
          :headers="headers"
          :delete-work="deleteWork"
          :reload-list="onUpdate || getWorksListItems"
          :work-form-template="workFormTemplate"
          :modal="modal"
          :read-only="readOnly"
          :force-editable="true"
        />
      </template>
      <template slot="no-data">
        {{ $i18n.t('works.listEmpty') }}
      </template>
      <template v-slot:item.main_date="{ item }">
        {{ formatDate(item.main_date) }}
      </template>
      <template v-slot:item.cost="{ item }">
        {{ formatPrice(item.cost, 2) }}
      </template>
      <template v-slot:item.main_object_state_id="{ item }">
        <div
          v-if="item.main_object_state"
          :style="objectStateStyle(item.main_object_state)"
        >
          {{ item.main_object_state[`name_${$i18n.locale}`] }}
        </div>
      </template>
      <template v-slot:item.priority="{ item }">
        <div
          v-if="item.priority"
          class="priority-box"
          :style="priorityStyle(item.priority)"
        >
          {{ item.priority.toUpperCase() }}
        </div>
      </template>
      <template v-slot:item.error_description="{ item }">
        <div>
          {{
            item.fault_description
              ? item.fault_description[`name_${$i18n.locale}`]
              : item.error_description
          }}
        </div>
      </template>
      <template v-slot:item.work_type="{ item }">
        <div v-if="item.work_type">
          {{ item.work_type[`name_${$i18n.locale}`] }}
        </div>
      </template>
    </v-data-table>
    <ListBottomPagination
      v-if="!modal"
      v-model="worksListOptions.page"
      :total-pages="pages"
    />
  </v-container>
</template>

<script>
import helper from '@/helper'
import ListTopPagination from '@/components/Shared/ListTopPagination'
import ListBottomPagination from '@/components/Shared/ListBottomPagination'
import WorksListItemExpanded from '@/components/Works/WorksListItemExpanded'
import allMethods from '@/components/allMethods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'WorksList',

  components: {
    WorksListItemExpanded,
    ListBottomPagination,
    ListTopPagination,
  },

  data() {
    return {
      expanded: [],
    }
  },

  props: {
    onUpdate: {
      type: Function,
      default: null,
    },
    isInspection: {
      type: Boolean,
      default: false,
    },
    inspectionWorks: {
      type: Array,
      default: () => {},
    },
    modal: {
      // Works list is shown outside of Works module. Use light mode (no pagination, light design)
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    workFormTemplate: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    headersFiltered() {
      return this.headers.filter((header) => header.show)
    },

    headers() {
      const headers = [
        {
          text: this.$i18n.t('works.mainDateShort'),
          align: 'start',
          value: 'main_date',
          width: '90px',
          show:
            !this.modal && this.userInfo && this.userInfo.allow_show_work_dates,
        },
        {
          text: this.$i18n.t('activityParks.activityPark'),
          value: 'activity_park.name',
          sortable: !this.modal,
          show: true,
        },
        {
          text: this.$i18n.t('works.workNumber'),
          value: 'work_number',
          width: '80px',
          sortable: !this.modal,
          show: !this.modal,
        },
        {
          text: this.$i18n.t('works.priority'),
          value: 'priority',
          width: '80px',
          sortable: !this.modal,
          show: true,
        },
        {
          text: this.$i18n.t('works.errorDescription'),
          value: 'error_description',
          sortable: !this.modal,
          show: true,
        },
        {
          text: this.$i18n.t('works.workType'),
          value: 'work_type',
          width: '250px',
          sortable: !this.modal,
          show: !this.isInspection || this.workFormTemplate !== 'extra',
        },
        {
          text: this.$i18n.t('works.cost'),
          value: 'cost',
          width: '120px',
          align: 'right',
          sortable: !this.modal,
          show: !this.modal && this.userInfo && this.userInfo.allow_show_prices,
        },
        {
          text: this.$i18n.t('general.objectState'),
          value: 'main_object_state_id',
          width: '70px',
          sortable: !this.modal,
          show: !this.modal,
        },
        {
          text: '',
          value: 'data-table-expand',
          show: true,
        },
      ]
      if (!this.modal && !this.$vuetify.breakpoint.lgAndUp) {
        return headers.filter(
          (header) => !this.hideWorksFieldsInListOnTablet.includes(header.value)
        )
      }
      return headers
    },

    pages() {
      return Math.ceil(
        this.worksItemsTotal / this.worksListOptions.itemsPerPage
      )
    },

    worksItemsComputed() {
      if (this.isInspection) {
        return this.inspectionWorks
      }
      return this.worksItems
    },
  },

  methods: {
    ...allMethods,

    rowClick(work, extra) {
      // Toggle expanded
      extra.expand(!extra.isExpanded)
    },

    closeAllExpanded() {
      this.expanded = []
    },

    expandAll() {
      if (this.expanded && this.expanded.length > 10) {
        this.closeAllExpanded()
      } else {
        this.expanded = this.worksItems
      }
    },

    itemStyle(item) {
      if (item.index % 2) {
        return 'odd-row'
      }
    },

    formatDate(date) {
      return helper.formatDate(date)
    },
  },
}
</script>

<style>
.v-data-table__expanded {
  cursor: pointer;
}
</style>
