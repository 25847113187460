<template>
  <v-toolbar
    class="list-search-toolbar elevation-0"
    color="#f2f2f2"
  >
    <v-container
      fluid
      class="pa-0"
    >
      <v-row dense>
        <v-col>
          <MultiselectDropdown
            :item="globalFilters"
            :label="
              $i18n.t('general.all') +
              ' ' +
              $i18n.t('organizations.title').toLocaleLowerCase()
            "
            search-class="organizations"
            field-name="organization"
            :style-attributes="styleAttributes"
            @change="$emit('change')"
          />
        </v-col>
        <v-col>
          <MultiselectDropdown
            v-if="
              ['works', 'productInstances', 'activityParks'].includes(model)
            "
            :item="globalFilters"
            :label="
              $i18n.t('general.all') +
              ' ' +
              $i18n.t('activityParks.title').toLocaleLowerCase()
            "
            search-class="activity_parks"
            field-name="activity_park"
            class="mx-2"
            :style-attributes="styleAttributes"
            @change="$emit('change')"
          />
        </v-col>
        <v-col v-if="['works'].includes(model)">
          <v-text-field
            v-model="globalFilters.searchTerm"
            :label="$i18n.t('general.searchTerm')"
            search-class="activity_parks"
            hide-details
            clearable
            v-bind="styleAttributes"
            @keyup.enter="search"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-btn
      id="search-button"
      v-if="model"
      rounded
      class="elevation-0 ml-2"
      @click="search"
    >
      {{ $t('general.search') }}
      <v-icon
        class="ml-3"
        color="#999"
        small
      >
        fa-search
      </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '../../store/state'
import allMethods from '@/components/allMethods'
import MultiselectDropdown from '@/components/Shared/MultiselectDropdown'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListSearchToolbar',

  components: { MultiselectDropdown },

  props: {
    model: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    styleAttributes() {
      return {
        color: '#777',
        fill: true,
        solo: true,
        rounded: true,
        dense: true,
        backgroundColor: 'white',
      }
    },
  },

  methods: {
    ...allMethods,

    search() {
      this.goToFirstPage()
      this.getListItems()
    },
  },
}
</script>

<style lang="scss">
.list-search-toolbar {
  .fa-chevron-down {
    font-size: 14px !important;
  }
}

#search-button {
  background: #fff;
}
</style>
