<template>
  <v-container class="dialog-container">
    <v-row class="header-row">
      <v-col cols="10" class="h1">
        {{ $i18n.t('general.detailedSearch').toUpperCase() }}:
      </v-col>
      <v-col cols=2 class="text-right ma-0 pa-0">
        <v-btn small fab text @click="showActivityParksSearchForm = false">
          <v-icon >
            fa-times
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="content-row">
      <v-col>
        <v-container
          fluid class="search-form"
          :style="{ height: dialogHeight + 'px' }"
        >
          <v-row>
            <v-col cols="12">
              {{ $i18n.t('general.searchExplanation') }}
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="filters.inspectedInLastYear"
                :label="$i18n.t('activityParks.inspectedInLastYear')"
              />
            </v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('organizations.title') }}
            </v-col>

            <v-col cols="12">
              <MultiselectDropdown
                :item="globalFilters"
                :label="$i18n.t('general.all') + ' ' + $i18n.t('organizations.title').toLocaleLowerCase()"
                search-class="organizations"
                field-name="organization"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('activityParks.activityParkType') }}
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('activityParks.activityParkType')"
                :load-on-focus="true"
                :style-attributes="textFieldFormatAttributes"
                search-class="activity_park_types"
                field-name="activity_park_type"
              />
            </v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('activityParks.selectContactPerson') }}
            </v-col>

            <v-col cols="12">
              <MultiselectString
                :item="filters"
                :label="$i18n.t('activityParks.selectContactPerson')"
                field-name="responsiblePeople"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('works.workType') }}
            </v-col>

            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('works.workType')"
                :load-on-focus="true"
                :style-attributes="textFieldFormatAttributes"
                search-class="work_types"
                field-name="work_type"
              />
            </v-col>

            <v-col cols="12" class="h3">
              {{ $i18n.t('activityParks.work_priority') }}
            </v-col>

            <v-col cols="8">
              <PrioritySelect
                v-model="filters.work_priority"
                :multiple="true"
                :show-all-button="true"
              />
            </v-col>

          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="buttons-row">
      <v-col cols="6">
        <v-btn
          color="#8cc477"
          dark
          v-bind="popupButtonFormatAttributes"
          @click="search()"
        >
          {{ $i18n.t('general.showSearchResults') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="#666"
          block
          outlined
          v-bind="popupButtonFormatAttributes"
          @click="clear()"
        >
          {{ $i18n.t('general.clearSearch') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import allMethods from '@/components/allMethods'
import MultiselectDropdown from '@/components/Shared/MultiselectDropdown'
import MultiselectString from '@/components/Shared/MultiselectString'
import PrioritySelect from '@/components/Shared/PrioritySelect'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ActivityParksSearchForm',

  components: {
    PrioritySelect,
    MultiselectString,
    MultiselectDropdown,
  },

  data () {
    return {
      model: 'activityParks',
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    filters () {
      return this.activityParksFilters
    },
  },

  methods: {
    ...allMethods,

    search () {
      this.goToFirstPage()
      this.getListItems()
      this.showActivityParksSearchForm = false
    },

    clear () {
      this.clearSearch()
      this.search()
    },
  },
}
</script>
