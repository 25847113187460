const productInstancesDefaultFilters = {
  name: null,
  ifs_identifier: null,
  product_code: [],
  product_number: [],
  product_instance_type: [],
  product_instance_category: [],
  manufacturer: [],
  safety_platform_type: [],
  material: null,
  installation_from_month: null,
  installation_from_year: null,
  installation_to_month: null,
  installation_to_year: null,
}

const productInstancesListDefaultOptions = {
  page: 1,
  itemsPerPage: 11,
  sortBy: ['updated_at'],
  sortDesc: [false],
}

const productInstanceEditModalDefaultProps = {
  dialog: false,
  id: null,
  activityPark: null,
  callback: null,
}

export default {
  productInstancesItems: [],
  productInstancesItemsTotal: 0,
  productInstancesAllIds: [],

  productInstanceEditModalDefaultProps: JSON.parse(JSON.stringify(productInstanceEditModalDefaultProps)),
  productInstanceEditModalProps: JSON.parse(JSON.stringify(productInstanceEditModalDefaultProps)),

  productInstanceItemForShow: {},
  productInstancesLoading: true,
  productInstanceItemLoading: false,
  productInstancesListDefaultOptions: productInstancesListDefaultOptions,
  productInstancesDefaultFilters: productInstancesDefaultFilters,
  productInstancesListOptions: JSON.parse(JSON.stringify(productInstancesListDefaultOptions)),
  productInstancesFilters: JSON.parse(JSON.stringify(productInstancesDefaultFilters)),

  staticLists: {
    material: {
      metal: 'productInstances.material.metal',
      plastic: 'productInstances.material.plastic',
      wood: 'productInstances.material.wood',
      rubber_granulate: 'productInstances.material.rubber_granulate',
      hpl_laminate: 'productInstances.material.hpl_laminate',
    },
    foundation: {
      deep: 'productInstances.foundation.deep',
      surface: 'productInstances.foundation.surface',
      wood: 'productInstances.foundation.wood',
      free_standing: 'productInstances.foundation.free_standing',
    },
    cost_types: {
      external: 'works.cost_types.external',
      fixed_price: 'works.cost_types.fixed_price',
      material: 'works.cost_types.material',
      personnel: 'works.cost_types.personnel',
    },
    product_code: {
      lappset: 'productInstances.product_code.lappset',
      product: 'productInstances.product_code.product',
    },
  },

  showProductInstancesSearchForm: false,

  showProductInstancesShowModal: false,
  productInstanceDeleteTrigger: 0, // Trigger that is altered on Product Instance delete, watched in Inspection Report
}
