import api from '@/store/api'
import productInstancesQueries from '@/components/ProductInstances/productInstancesQueries'
import helper from '@/helper'

export default {
  getProductInstancesListItems () {
    this.productInstancesLoading = true
    window.scrollTo(0, 0)
    this.$store.dispatch('getListItems', {
      model: 'productInstances',
      filters: this.getProductInstancesListFilters(),
      queries: productInstancesQueries.forList,
    }).then(() => {
      this.productInstancesLoading = false
    })
  },

  showProductInstanceEditModal (id, {
    activityPark = null,
    callback = () => {},
  }) {
    this.showProductInstancesShowModal = false
    // Reset props
    this.productInstanceEditModalProps = JSON.parse(JSON.stringify(this.productInstanceEditModalDefaultProps))
    // On next tick open with new props
    this.$nextTick(() => {
      this.productInstanceEditModalProps = {
        dialog: true,
        id,
        activityPark,
        callback,
      }
    })
  },

  getProductInstancesListFilters () {
    const filters = this.getListGeneralFilters('productInstances')
    const productInstancesFilters = this.productInstancesFilters;

    ['product_instance_type', 'safety_platform_type', 'product_instance_category', 'manufacturer'].forEach(filterName => {
      if (productInstancesFilters[filterName] && productInstancesFilters[filterName].length > 0) {
        filters['_' + filterName] = '(' + productInstancesFilters[filterName].map(o => o.id).join(' ') + ')'
      }
    });

    [
      'product_number',
      'product_code',
    ].forEach(filterName => {
      if (productInstancesFilters[filterName] && productInstancesFilters[filterName].length > 0) {
        filters['_' + filterName] = '(' + productInstancesFilters[filterName].join(' ') + ')'
      }
    });

    [
      'installation_from_month',
      'installation_from_year',
      'installation_to_month',
      'installation_to_year',
      'material',
      'foundation',
    ].forEach(filterName => {
      if (this.productInstancesFilters[filterName]) {
        filters[`_${filterName}`] = this.productInstancesFilters[filterName]
      }
    })
    filters._in_operation = true
    return filters
  },

  showProductInstanceItemForShow (id) {
    if (!id) { return }
    this.showProductInstancesShowModal = true
    this.productInstanceItemLoading = true
    const queries = productInstancesQueries.forShow
    this.productInstanceItemForShow = {}
    api.getItemInfo('product_instances', id, queries, {})
      .then(response => {
        this.$store.dispatch('globalErrorDisplay', response)
        if (response && response.item) {
          this.productInstanceItemForShow = response.item
          this.productInstanceItemLoading = false
        }
      })
  },
}
