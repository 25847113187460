<template>
  <td
    :colspan="headers.length"
    :class="work.index % 2 ? 'odd-row-td' : ''"
  >
    <v-container fluid>
      <ImagesCarouselModal
        v-model="showImagesModal"
        v-if="showImagesModal"
        :images="work.images || []"
      />
      <v-row>
        <v-col class="flex-grow-0 flex-shrink-0 images">
          <v-img
            v-if="work.images && work.images[0]"
            aspect-ratio="1.6"
            min-width="300px"
            max-width="300px"
            class="rounded-12"
            :lazy-src="
              '/content/attachments/' +
              work.images[0].id +
              '/playcare_preview.png'
            "
            :src="
              '/content/attachments/' +
              work.images[0].id +
              '/' +
              work.images[0].filename
            "
            @click="showImagesModal = true"
          />
          <div
            v-if="work.images.length > 1"
            class="badge green-bg"
          >
            {{ work.images.length }}
          </div>
        </v-col>
        <v-col
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0"
        >
          <template v-if="!$vuetify.breakpoint.lgAndUp">
            <b>{{ $i18n.t('works.cost') }}:</b> {{ formatPrice(work.cost, 2)
            }}<br />
            <b>{{ $i18n.t('works.workNumber') }}:</b> {{ work.work_number
            }}<br />
          </template>
          <b>{{ $i18n.t('works.organization') }}:</b>
          {{ work.organization && work.organization.summary }}<br />
          <b>{{ $i18n.t('works.selectOrganizationPerson') }}:</b>
          {{ work.organization_contact_person }}<br />
          <br />
          <b>{{ $i18n.t('productInstances.equipment') }}:</b>
          {{ work.product_instance && work.product_instance.name }}<br />
          <b>{{ $i18n.t('productInstances.type') }}:</b>
          {{
            work.product_instance &&
            work.product_instance[`product_instance_type_name_${$i18n.locale}`]
          }}<br />
          <b>{{ $i18n.t('works.standard') }}: </b>
          {{ work.standard && work.standard[`name_${$i18n.locale}`] }}<br />
          <b>{{ $i18n.t('works.workDescription') }}: </b>
          {{ work.work_description }}<br /><br />
          <b v-if="work.work_cost_items.length > 0"
            >{{ $i18n.t('works.work_cost_items') }}:
          </b>
          <table
            v-if="work.work_cost_items.length > 0"
            class="work-order-works"
          >
            <thead>
              <tr>
                <th>{{ $i18n.t('workCostItems.cost_type') }}</th>
                <th>{{ $i18n.t('workCostItems.catalog_no') }}</th>
                <th>{{ $i18n.t('workCostItems.description') }}</th>
                <th class="text-right">
                  {{ $i18n.t('workCostItems.quantity') }}
                </th>
                <th class="text-right">
                  {{ $i18n.t('workCostItems.unit_sales_price') }}
                </th>
                <th class="text-right">
                  {{ $i18n.t('workCostItems.total_amount') }}
                </th>
              </tr>
            </thead>
            <tr
              v-for="(costItem, index) in work.work_cost_items"
              :key="index"
            >
              <td>{{ $i18n.t(staticLists.cost_types[costItem.cost_type]) }}</td>
              <td>{{ costItem.catalog_no }}</td>
              <td>{{ costItem.description }}</td>
              <td class="text-right">
                {{ costItem.quantity }} {{ $i18n.t('general.quantity') }}
              </td>
              <td class="text-right">
                {{ formatPrice(costItem.unit_sales_price, 2) }}
              </td>
              <td class="text-right">
                {{ formatPrice(costItem.total_amount, 2) }}
              </td>
            </tr>
          </table>
        </v-col>
        <v-col
          style="min-width: 250px"
          class="text-right flex-grow-0 flex-shrink-0"
        >
          <v-btn
            v-for="(action, index) in this.actions"
            :key="index"
            :class="'elevation-4 ' + action.class"
            :color="action.color"
            @click="action.action(work)"
            :loading="action.loading"
          >
            <v-icon :class="action.iconClass || []">
              {{ action.icon }}
            </v-icon>
          </v-btn>
          <v-menu
            v-if="this.workFormTemplate !== 'extra' && modal && !readOnly"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="white"
              >
                <v-icon> fa-bars </v-icon>
              </v-btn>
            </template>
            <v-list class="ei-menu">
              <v-list-item>
                <v-list-item-title>
                  <b>
                    {{ $i18n.t('general.actions') }}
                  </b>
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa-times</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="deleteItem(work)">
                <v-list-item-avatar class="mr-3">
                  <v-icon small>fa-times</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $i18n.t('works.deleteTitle') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </td>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import helper from '@/helper'
import state from '@/store/state'
import ImagesCarouselModal from '@/components/Shared/ImagesCarouselModal'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'WorksListItemExpanded',

  components: {
    ImagesCarouselModal,
  },

  data() {
    return {
      actionInProgress: '',
      showImagesModal: false,
      statesWithCartEnabled: [
        state.workStateIds.new,
        state.workStateIds.proposed,
        state.workStateIds.shoppingCart,
      ],
    }
  },

  props: {
    work: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => {},
    },
    reloadList: {
      type: Function,
      default: () => {},
    },
    workFormTemplate: {
      type: String,
      default: '',
    },
    modal: {
      type: Boolean,
      default: false,
    },
    forceEditable: {
      // Force edit button to be visible even if modal is true.  This prop is probably a bad idea.
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    displayEditButton() {
      return this.forceEditable || !this.modal
    },

    actions() {
      const actions = [
        {
          action: this.toggleInReport,
          loading: this.actionInProgress === 'toggle-in-report',
          iconClass: this.reportToggleClass,
          icon: this.work.shown_on_report
            ? 'fa-clipboard-check'
            : 'fa-clipboard',
          show: !this.modal,
        },
        {
          action: this.addToCart,
          loading: this.actionInProgress === 'toggle-in-cart',
          icon: 'fa-shopping-cart',
          show: this.showShoppingCart,
        },
        {
          action: this.openEditForm,
          loading: false,
          icon: 'fa-edit',
          show: this.displayEditButton,
        },
        {
          action: this.deleteItem,
          iconClass: 'delete-button',
          loading: this.actionInProgress === 'delete',
          icon: 'fa-trash-alt',
          show:
            !this.modal &&
            this.worksIsInCart &&
            this.showActionButtons &&
            this.userInfo &&
            this.userInfo.allow_remove_work,
        },
        {
          action: this.deleteFromCart,
          color: '#8cc477',
          class: 'cart-remove',
          loading: this.actionInProgress === 'toggle-in-cart',
          icon: 'fa-shopping-cart',
          show:
            !this.modal &&
            this.showActionButtons &&
            !this.worksIsInCart &&
            this.worksIsInUsersCart &&
            this.userInfo &&
            this.userInfo.allow_ordering,
        },
      ]
      return actions.filter((action) => action.show)
    },

    reportToggleClass() {
      return this.work.shown_on_report ? 'in-report' : 'not-in-report'
    },

    showActionButtons() {
      return (
        this.work &&
        this.work.main_object_state &&
        this.statesWithCartEnabled.includes(this.work.main_object_state.id)
      )
    },

    worksIsInCart() {
      return (
        this.work.main_object_state &&
        this.work.main_object_state.id !== this.workStateIds.shoppingCart
      )
    },

    worksIsInUsersCart() {
      return (
        this.work.shopping_cart_person &&
        this.work.shopping_cart_person.id === this.userInfo.person.id
      )
    },

    showShoppingCart() {
      return (
        !this.modal &&
        this.work.work_type &&
        this.work.work_type.id !== this.workTypeIds.actionPlan &&
        this.worksIsInCart &&
        this.showActionButtons &&
        ((helper.formatPrice(this.work.cost, 2) !== '0.00 €' &&
          this.userInfo &&
          this.userInfo.allow_ordering) ||
          (helper.formatPrice(this.work.cost, 2) === '0.00 €' &&
            this.userInfo &&
            this.userInfo.allow_send_offer))
      )
    },
  },

  methods: {
    ...allMethods,

    openEditForm() {
      // Reset props
      this.workEditModalProps = JSON.parse(
        JSON.stringify(this.workEditModalDefaultProps)
      )
      // On next tick open with new props
      this.$nextTick(() => {
        this.workEditModalProps = {
          dialog: true,
          id: this.work.id,
          activityPark: null,
          callback: this.reloadList,
        }
      })
    },

    deleteItem(work) {
      this.workDeleteConfirmForWorkId = work.id
    },

    toggleInReport(work) {
      if (this.actionInProgress) {
        return
      }
      this.actionInProgress = 'toggle-in-report'
      this.toggleWorkInReport(work).then(() => {
        // work.shown_on_report = !work.shown_on_report
        this.actionInProgress = ''
      })
    },

    deleteFromCart(work) {
      if (this.actionInProgress) {
        return
      }
      this.actionInProgress = 'toggle-in-cart'
      this.deleteWorkFromCart(work).then(() => {
        this.actionInProgress = ''
      })
    },

    addToCart(work) {
      if (this.actionInProgress) {
        return
      }
      this.actionInProgress = 'toggle-in-cart'
      this.addWorkToCart(work).then(() => {
        this.actionInProgress = ''
      })
    },
  },
}
</script>
