<template>
  <v-container
    fluid
    class="pa-0"
  >
    <ActivityParkItemModal
      v-if="showActivityParkModalForId"
      v-model="showActivityParkModalForId"
      @reload="refreshMap"
    />
    <v-row
      :no-gutters="mapExpanded"
      style="position: relative;"
    >
      <div class="map-expand-button">
        <v-btn @click="toggleMapExpanded()" class="square-btn">
          <v-icon>{{ mapExpanded ? 'fa-compress' : 'fa-expand' }}</v-icon>
        </v-btn>
      </div>
      <div class="map-search-button">
        <v-btn @click="showMapSearchForm()" class="square-btn">
          <v-icon>fa-search</v-icon>
        </v-btn>
      </div>
      <div
        v-if="activityParksLoading"
        class="map-loader-container"
      >
        <v-progress-circular
          indeterminate
          size="100"
          width="10"
        />
      </div>
      <v-col cols="12">
        <div
          id="map-area"
          :style="mapStyle"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import ActivityParkItemModal from '@/components/ActivityParks/ActivityParkItemModal'
import allMethods from '@/components/allMethods'

const imagePath = '/content/attachments/'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const API_URL = 'https://maps.googleapis.com/maps/api/js'
const CALLBACK_NAME = '__googleMapsApiOnLoadCallback'

const loadGoogleMapsApi = (key) => {
  return new Promise(resolve => {
    if (window.google && window.google.maps) {
      resolve(window.google.maps)
      return
    }

    window[CALLBACK_NAME] = function () {
      resolve(window.google.maps)
      delete window[CALLBACK_NAME]
    }

    const scriptElement = document.createElement('script')
    const params = ['callback=' + CALLBACK_NAME]
    params.push('key=' + key)

    scriptElement.src = API_URL + '?' + params.join('&')
    document.body.appendChild(scriptElement)
  })
}

const mapOptions = {
  zoom: 15,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
}

export default {
  name: 'Map',

  components: {
    ActivityParkItemModal,
  },

  data () {
    return {
      showActivityParkModalForId: null,
      height: window.innerHeight - 166,
      googleMaps: null,
      map: null,
      geocoder: null,
      infoWindows: {},
      markers: [],
      bounds: null,
      iconColors: {
        red: '#E32831',
        green: '#4CAF50',
        orange: '#FF9800',
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    pages () {
      return Math.ceil(this.activityParksItemsTotal / this.activityParksListOptions.itemsPerPage)
    },

    mapStyle () {
      const topToolbars = this.mapExpanded ? 150 : 320
      return {
        height: (this.innerHeight - topToolbars) + 'px',
      }
    },
  },

  watch: {
    activityParksLoading (value) {
      if (value) { return }
      this.placeMapMarkers()
    }
  },

  created () {
    loadGoogleMapsApi(this.systemConfig.google_maps_api_key)
      .then(googleMaps => {
        this.bounds = new window.google.maps.LatLngBounds()
        this.googleMaps = googleMaps
        this.map = new this.googleMaps.Map(document.getElementById('map-area'), mapOptions)
        this.showAddresses().then(() => {
          this.map.fitBounds(this.bounds)
        })
      })
  },

  methods: {
    ...allMethods,

    showMapSearchForm () {
      this.showActivityParksSearchForm = true
    },

    refreshMap () {
      this.clearMarkers()
      this.getActivityParksListItems().then(() => {
        this.placeMapMarkers()
      })
    },

    placeMapMarkers () {
      this.clearMarkers()
      this.showAddresses().then(() => {
        this.map.fitBounds(this.bounds)
      })
    },

    clearMarkers () {
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.markers = []
      this.bounds = new window.google.maps.LatLngBounds()
    },

    toggleMapExpanded () {
      window.scrollTo(0, 0)
      this.mapExpanded = !this.mapExpanded
    },

    showAddresses () {
      const promises = []
      return new Promise(resolve => {
        this.activityParksItems.forEach((activityPark, i) => {
          this.geocoder = new this.googleMaps.Geocoder()
          const color = activityPark && activityPark.works && activityPark.works.length > 0
            ? activityPark.works.filter(work => work.priority === 'a').length > 0
              ? 'red'
              : 'orange'
            : 'green'
          promises.push(this.geoCodeAndPlotAddress({
            i,
            id: activityPark.id,
            name: activityPark.name,
            address: activityPark.address,
            organization: activityPark.organization,
            color,
            image: (activityPark.images && activityPark.images[0]) || {},
          }))
        })
        Promise.all(promises).then(responses => {
          resolve()
        })
      })
    },

    mapIcon (color) {
      return {
        path: 'M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z',
        fillColor: this.iconColors[color],
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.65
      }
    },

    openActivityParkInfo (id) {
      this.showActivityParkModalForId = id
    },

    geoCodeAndPlotAddress (location) {
      return new Promise(resolve => {
        if (!location.address.latitude || !location.address.longitude) {
          resolve()
          return
        }
        const latLong = {
          lat: parseFloat(location.address.latitude),
          lng: parseFloat(location.address.longitude),
        }

        const marker = new this.googleMaps.Marker({
          position: latLong,
          map: this.map,
          icon: this.mapIcon(location.color),
        })
        this.markers.push(marker)
        const infoWindow = new this.googleMaps.InfoWindow()

        const image = '<img src="' + imagePath + location.image.id + '/' + location.image.filename + '" style="width: 300px;">'

        const boxText = document.createElement('div')
        boxText.id = location.id
        boxText.className = 'map-info-window'
        boxText.innerHTML = image + '<br>' +
          '<div style="padding: 10px;"><b>' + location.name + '</b>' + '<br>' +
          '<b>' + location.organization.name + '</b>' +
          '<br>' + location.address.summary +
          '<br>Lat: ' + location.address.latitude + ', Long: ' + location.address.longitude + '</div>'
        this.infoWindows[location.id] = boxText

        infoWindow.setContent(boxText)
        window.google.maps.event.addListener(marker, 'click', function () {
          infoWindow.open(this.map, marker)
        })

        window.google.maps.event.addDomListener(this.infoWindows[location.id], 'click', () => {
          this.openActivityParkInfo(location.id)
        })
        this.bounds.extend(latLong)
        resolve({ address: location.address.summary })
      })
    },
  },
}
</script>

<style>
#map-area {
  width: 100%;
}
.map-info-window {
  cursor: pointer;
  max-width: 300px;
  overflow: hidden;
}
.map-expand-button {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 3;
}
.map-search-button {
  position: absolute;
  top: 25px;
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 2;
}
.map-loader-container {
  position: absolute;
  top: 200px;
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 3;
}
</style>
