<template>
  <td :colspan="headers.length" :class="item.index % 2 ? 'odd-row-td' : ''">
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ $i18n.t('general.files') }}</b>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-group>
              <v-list-item
                v-for="(file, index) in files"
                :key="index"
                @click="openFile(file)"
              >
                <v-list-item-icon class="pr-4">
                  <v-icon small>fa-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ file.filename }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click.stop="deleteFile(index)"
                  >
                    <v-icon small color="red">fa-trash</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="6" class="align-self-center text-center">
          <b>{{ $i18n.t('general.addNewFile') }}</b><br><br>
          <v-btn
            @click.stop="selectFile()"
          >
            <v-icon small color="green">fa-upload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <input
        type="file"
        id="upload_file"
        style="position: fixed; top: -200px"
        @change="readFile($event);"
      />
    </v-container>
  </td>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'OrganizationsListItemExpanded',

  data () {
    return {
      actionInProgress: '',
      uploadFile: null,
      model: 'organizations',
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      default: () => {},
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    files () {
      return this.item.files
    },
  },

  methods: {
    ...allMethods,

    openFile (file) {
      window.open('/content/attachments/' + file.id + '/' + file.filename)
    },

    deleteFile (index) {
      this.$store.dispatch('deleteFile', { index, files: this.files })
    },

    selectFile () {
      const el = document.getElementById('upload_file')
      if (!el) { return }
      el.click()
    },

    readFile (e) {
      const file = e.target.files[0]
      if (!file) { return }
      this.uploadFile = URL.createObjectURL(file)
      const reader = new FileReader()
      reader.onload = e => {
        this.$store.dispatch('addNewFile', {
          file,
          files: this.files,
          forObjectClass: 'organizations', // this.item['@class'] - can't use this as custom api does not return @class
          forFieldName: 'files',
          forObjectId: this.item.id,
          attachmentType: 'file',
        }).then(() => {
          this.getListItems()
        })
      }
      reader.readAsText(file)
    },
  },
}
</script>
