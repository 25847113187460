const organizationsListDefaultOptions = {
  page: 1,
  itemsPerPage: 30,
  sortBy: ['created_at'],
  sortDesc: [true],
}
export default {
  organizationsItems: [],
  organizationsItemsTotal: 0,
  organizationsAllIds: [],
  organizationsLoading: true,
  organizationsListDefaultOptions: organizationsListDefaultOptions,
  organizationsListOptions: JSON.parse(JSON.stringify(organizationsListDefaultOptions)),

}
