import organizationQueries from '@/components/Organizations/organizationQueries'

export default {
  getOrganizationsListItems () {
    this.organizationsLoading = true
    window.scrollTo(0, 0)
    this.$store.dispatch('getListItems', {
      model: 'organizations',
      filters: this.getOrganizationsListFilters(),
      queries: organizationQueries.forList,
    }).then(() => {
      this.organizationsLoading = false
    })
  },

  getOrganizationsListFilters () {
    return this.getListGeneralFilters('organizations')
  },
}
