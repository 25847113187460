<template>
  <v-container class="inspection-expansion">
    <v-row>
      <v-expansion-panels
        v-model="panel"
        accordion
        flat
      >
        <v-expansion-panel active-class="elevation-3">
          <v-expansion-panel-header
            :class="
              'h2 ' + (color === 'green' && !readOnly ? 'header-green' : '')
            "
          >
            {{ title }} ({{ works.length }})
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-container
              v-if="loaded"
              fluid
              class="pa-0"
            >
              <v-row>
                <v-col cols="12">
                  <WorksList
                    :is-inspection="true"
                    :inspection-works="works"
                    :work-form-template="workFormTemplate"
                    :read-only="false"
                    :modal="true"
                    :on-update="() => loadInspectionWorks(false)"
                  />
                </v-col>
                <v-col
                  v-if="addNew"
                  cols="12"
                >
                  <v-btn
                    @click="showNewInspectionWorkForm()"
                    text
                  >
                    <span class="h2">{{
                      $i18n
                        .t(
                          `inspection.${
                            this.workFormTemplate === 'extra'
                              ? 'addNewAdditionalWork'
                              : 'addNewWork'
                          }`
                        )
                        .toUpperCase()
                    }}</span>
                    <v-icon class="ml-3">fa-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-progress-linear
                indeterminate
                color="grey"
              />
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import WorksList from '@/components/Works/WorksList'
import worksQueries from '@/components/Works/worksQueries'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'InspectionWorks',

  components: { WorksList },

  data() {
    return {
      loaded: false,
      works: [],
      panel: 0,
    }
  },

  props: {
    color: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
    workFormTemplate: {
      type: String,
      default: '',
    },
    stateIds: {
      type: Array,
      default: () => {},
    },
    createdAt: {
      type: String,
      default: () => {},
    },
    filterWorkTypeIds: {
      type: Array,
      default: () => {},
    },
  },

  created() {
    this.loadInspectionWorks()
    // Open expansion panel when read only in step 4
    if (this.readOnly) {
      this.panel = 0
    }
  },

  watch: {
    workDeleteConfirmForWorkId(value) {
      if (value) {
        return
      }
      this.loadInspectionWorks()
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...allMethods,

    showNewInspectionWorkForm() {
      const activityPark = this.inspection.activityPark
      this.showWorkEditModal('new', {
        activityPark: JSON.parse(JSON.stringify(activityPark)),
        callback: this.loadInspectionWorks,
        template: this.workFormTemplate,
      })
    },

    loadInspectionWorks(loader = true) {
      if (!this.inspection.activityPark) {
        return
      }
      this.loaded = !loader // Helps with keeping WorkList state after editing images.
      const filters = {
        _activity_park_id: this.inspection.activityPark.id,
        order: 'created_at desc',
        offset: 0,
        limit: 1000,
      }
      if (this.stateIds) {
        filters._main_object_state_id = '(' + this.stateIds.join(' ') + ')'
      }
      if (this.filterWorkTypeIds) {
        filters._work_type_id = '(' + this.filterWorkTypeIds.join(' ') + ')'
      }
      if (this.createdAt) {
        filters._created_at = this.createdAt
      }
      this.$store
        .dispatch('getListItems', {
          model: 'works',
          queries: worksQueries.forList,
          filters,
        })
        .then((response) => {
          if (response && response.items) {
            this.works = response.items
          }
          this.loaded = true
        })
    },
  },
}
</script>

<style lang="scss">
.inspection-expansion {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .items-list-modal {
    padding: 0;
    .v-data-table {
      box-shadow: none !important;
    }
  }
}
</style>
