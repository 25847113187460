<template>
  <v-container>
    <v-row class="pb-2">
      <v-expansion-panels
        v-model="panel1"
        accordion flat
      >
        <v-expansion-panel
          active-class="elevation-3"
        >
          <v-expansion-panel-header
            class="h2 header-green"
          >
            {{ $i18n.t('inspection.summaryDescription').toUpperCase() }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-container
              fluid class="pa-0"
            >
              <v-row class="pt-5 mt-3">
                <v-col cols="12" class="text-subtitle">{{ $i18n.t('inspection.summaryDescriptionText') }}</v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="inspection.description"
                    maxlength="60"
                    counter="60"
                    rows="4"
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class="pt-5 mt-5">
      <v-expansion-panels
        v-model="panel2"
        accordion flat
      >
        <v-expansion-panel
          active-class="elevation-3"
        >
          <v-expansion-panel-header
            class="h2 header-green"
          >
            {{ $i18n.t('inspection.summaryTimeSpentTitle').toUpperCase() }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-container
              fluid class="pa-0"
            >
              <v-row class="pt-5 mt-3">
                <v-col cols="12" class="text-subtitle">{{ $i18n.t('inspection.summaryTimeSpentText') }}</v-col>
                <v-col cols="3">
                  <v-text-field
                    @keypress="allowOnlyNumbers"
                    v-model="inspection.hours"
                    :label="$i18n.t('general.hours')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    @keypress="allowOnlyNumbers"
                    v-model="inspection.minutes"
                    :label="$i18n.t('general.minutes')"
                    v-bind="textFieldFormatAttributes"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'InspectionSummary',

  data () {
    return {
      panel1: 0,
      panel2: 0,
    }
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    allowOnlyNumbers (e) {
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault()
      }
    },
  },
}
</script>
