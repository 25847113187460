import api from '@/store/api'
import Vue from 'vue'

const logoUrl = '/login/img/play-care-logo-transparent.png'
const portalName = 'PlayCare'
const themeColor = '#607D8B'
const logoBgColor = '#eee'

const goToLoginAppWithAction = (action) => {
  window.location.href = window.location.origin + '/login/?' +
    'action=' + action +
    '&logoUrl=' + encodeURIComponent(logoUrl) +
    '&portalName=' + portalName +
    '&portalUrl=' + encodeURIComponent(window.location.href) +
    '&logoBgColor=' + encodeURIComponent(logoBgColor) +
    '&themeColor=' + encodeURIComponent(themeColor)
}

export default {
  /**
   * Called on reload, check if user is logged in and set user data
   */
  getPersonInfoWithTokens: ({ dispatch, state }) => {
    return new Promise(resolve => {
      api.getPersonInfoForEdit(state.userInfo.person.id).then(response => {
        if (response.item) {
          // Update user info with tokens
          dispatch('updatePersonInfo', response.item)
          resolve(true)
        }
      })
    })
  },

  trySessionRestoreAndSetUserInfo: ({ commit, dispatch, state }) => {
    return new Promise(resolve => {
      api.getUserInfo('my').then(response => {
        dispatch('globalErrorDisplay', response).then(errorsFound => {
          if (!errorsFound && response && response.item) {
            resolve(response)
            // Set user info after two ticks, then locale can be set
            // before sending any following API calls
            Vue.nextTick(() => {
              Vue.nextTick(() => {
                dispatch('setUserInfo', response.item)
              })
            })
          } else {
            resolve(response)
          }
          state.sessionRestoreTryCompleted = true
        })
      })
    })
  },

  logout ({ state }) {
    if (state.isDeveloper) {
      api.sendLogoutRequest().then(() => {
        location.reload()
      })
      return
    }
    goToLoginAppWithAction('logout')
  },

  login () {
    goToLoginAppWithAction('login')
  },

  updatePersonInfo ({ state }, personInfo) {
    Vue.set(state.userInfo, 'person', personInfo)
  },

  setUserInfo ({ commit, state }, userInfo) {
    if (!userInfo.working_region) {
      userInfo.working_region = []
    }
    if (!userInfo.street_address) {
      userInfo.street_address = {}
    }
    state.authenticated = true
    state.userInfo = userInfo
  },
}
