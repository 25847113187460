<template>
  <v-container>
    <v-row>
      <v-expansion-panels
        v-model="panel"
        accordion
        flat
      >
        <v-expansion-panel active-class="elevation-3">
          <v-expansion-panel-header class="h2">{{
            $i18n.t('activityParks.activityParkInfo').toUpperCase()
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  style="position: relative"
                  @click="
                    showActivityParkItemForEdit({
                      id: activityPark.id,
                      callback: getActivityParkInfo,
                    })
                  "
                >
                  <v-img
                    v-if="activityPark.images && activityPark.images[0]"
                    class="ap-image"
                    max-height="280px"
                    min-height="280px"
                    width="100%"
                    :src="
                      '/content/attachments/' +
                      activityPark.images[0].id +
                      '/' +
                      activityPark.images[0].filename
                    "
                  >
                  </v-img>
                  <v-btn
                    v-if="!readOnly"
                    fab
                    x-large
                    style="bottom: 30px; right: 30px; position: absolute"
                  >
                    <v-icon> fa-edit </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <b>{{ (activityPark.organization || {}).summary }}</b
                  ><br />
                  {{ activityPark.contact_person_name }}<br />
                  {{ activityPark.contact_person_phone }}<br />
                  {{ activityPark.contact_person_email }}<br />
                  <br />
                  <b>{{ activityPark.name }}</b
                  ><br />
                  {{ activityPark.name_extension }}
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <b>{{ $i18n.t('activityParks.activityParkPlayAreas') }}</b
                  ><br />
                  <div
                    v-if="
                      activityPark.play_areas &&
                      activityPark.play_areas.length > 1
                    "
                  >
                    <span
                      v-for="playArea in activityPark.play_areas"
                      :key="playArea.description"
                    >
                      <b
                        >{{ $i18n.t('playAreas.area') }}
                        {{ playArea.number }}</b
                      >: {{ playArea.description }}<br />
                    </span>
                  </div>
                  <p v-else>{{ $i18n.t('playAreas.onlyOne') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/store/api/'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'
import allMethods from '@/components/allMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'InspectionActivityParkInfo',

  data() {
    return {
      activityPark: {},
      panel: 0, // 0 - to auto open
    }
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getActivityParkInfo()
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...allMethods,

    getActivityParkInfo() {
      if (!this.inspection.activityPark || !this.inspection.activityPark.id) {
        return
      }
      api
        .getItemInfo(
          'activity_parks',
          this.inspection.activityPark.id,
          activityParksQueries.forEdit
        )
        .then((response) => {
          this.$store.dispatch('globalErrorDisplay', response)
          if (response && response.item) {
            this.activityPark = response.item
          }
        })
    },
  },
}
</script>
