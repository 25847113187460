const shared = [
  'id',
  'product_code',
  'ifs_identifier',
  'created_at',
  'updated_at',
  'installation_date',
  'in_operation',
  'name',
  'material',
  'foundation',
  'safe_zone_size',
  'organization summary',
  'remover summary',
  'manufacturer summary',
  'product_instance_category summary',
  'product_instance_type summary',
  'standard summary',
  'safety_platform_type summary',
  'activity_park summary,name,name_extension',
  'activity_park address summary',
  'activity_park_in_operation',
  'images filename,content_type,attachable_association,order',
  'product_number',
  'condition_score',
  'type_score',
  'play_value',
]

export default {
  forList: [...shared],
  forEdit: [...shared],
  forShow: [...shared],
}
