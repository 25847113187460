<template>
  <v-container fluid class="ma-0 priority-select">
    <v-row>
      <v-col
        v-if="showAllButton"
        :style="priorityStyle('')"
        :class="('' === priority ? 'active' : 'not-active') + ' priority-box'"
        @click="selectAll"
      >
        {{ $i18n.t('general.all') }}
      </v-col>
      <v-col
        v-for="(option, index) in options"
        :key="index"
        :color="$store.state.workPriorityColors[priority]"
        :style="priorityStyle(option)"
        :class="(isSelected(option) ? 'active' : 'not-active') + ' priority-box'"
        @click="select(option)"
      >
        {{ option.toUpperCase() }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import allMethods from '@/components/allMethods'

export default {
  name: 'PrioritySelect',

  data () {
    return {
      options: ['a', 'b', 'c', 'd', 'x'],
    }
  },

  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    showAllButton: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    priority: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    ...allMethods,

    isSelected (option) {
      if (this.multiple) {
        return this.priority.includes(option)
      }
      return option === this.priority
    },

    selectAll () {
      if (this.priority.length === this.options.length) {
        this.priority = []
        return
      }
      this.priority = JSON.parse(JSON.stringify(this.options))
    },

    select (priority) {
      if (this.multiple) {
        this.toggleInMultiple(priority)
        return
      }
      this.priority = priority
    },

    toggleInMultiple (priority) {
      const index = this.priority.indexOf(priority)
      if (index === -1) {
        this.$set(this.priority, this.priority.length, priority)
      } else {
        this.$delete(this.priority, index)
      }
    },
  },
}
</script>

<style lang="scss">
.priority-select {
  .priority-box {
    cursor: pointer;
    margin-right: 20px;
  }
  .priority-box.active {
    /* font-size: 26px; */
  }
  .priority-box.not-active {
    background: transparent !important;
    color: #888;
  }
}
</style>
