<template>
  <tr class="edit-row" v-if="item">
    <template v-if="modelName === 'work_cost_items'">
      <td>
        <v-autocomplete
          v-if="modelName === 'work_cost_items'"
          v-model="item.cost_type"
          :items="$store.getters.workTypes"
          :label="$i18n.t('works.cost_type') + ' *'"
          v-bind="textFieldFormatAttributes"
        />
      </td>
      <td>
        <v-text-field
          v-model="item.description"
          :label="$i18n.t('works.description')"
          v-bind="textFieldFormatAttributes"
        />
      </td>
    </template>
    <template v-else-if="modelName === 'expense_items'">
      <td style="width: 60%;">
        <FormSelectField
          :item="item"
          :edit="edit"
          :model="modelName"
          :parent="parent"
          :parent-field="parentField"
          :load-on-focus="true"
          :clearable="false"
          :label="$i18n.t('works.chooseExpense') + ' *'"
          field-name="expense"
          search-class="expenses"
          :for-class-name="modelName"
          :style-attributes="textFieldFormatAttributes"
          @change="updateItemOnDefaultsForChange('expense')"
        />
      </td>
    </template>
    <template v-else-if="modelName === 'spare_part_items'">
      <td style="width: 60%;">
        <FormSelectField
          :item="item"
          :edit="edit"
          :model="modelName"
          :parent="parent"
          :parent-field="parentField"
          :load-on-focus="true"
          :clearable="false"
          :label="$i18n.t('works.chooseSparePart') + ' *'"
          field-name="spare_part"
          search-class="spare_parts"
          :for-class-name="modelName"
          :style-attributes="textFieldFormatAttributes"
          @change="updateItemOnDefaultsForChange('spare_part')"
        />
      </td>
    </template>
    <td style="width: 35%;">
      <v-text-field
        v-model="item.quantity"
        step="1"
        :label="quantityLabel + ' *'"
        @keypress="allowOnlyNumbers"
        @blur="fixQuantity"
        v-bind="textFieldFormatAttributes"
      />
    </td>
    <td style="width: 5%;">
      <v-btn
        icon
        color="#d9534f"
        @click="deleteAndUpdateStore(item)"
      >
        <v-icon small>fa-trash</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import helper from '@/helper'
import workItemQueries from '@/components/Works/WorkItems/workItemQueries'
import FormSelectField from '@/components/Shared/FormSelectField'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'WorkItemEdit',

  components: {
    FormSelectField,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    modelName: {
      type: String,
      default: '',
    },
    parent: {
      type: Object,
      default: () => {},
    },
    parentField: {
      type: String,
      default: null,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    item: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    quantityLabel () {
      return this.item.quantity_unit && this.item.quantity_unit.name
    },
  },

  methods: {
    saveAndUpdateRowInStore () {
      const item = this.parent[this.parentField][this.rowIndex]
      this.$store.dispatch('saveItem', {
        modelName: 'salesDocumentItems',
        item,
        parentItem: this.parent,
        parentField: this.parentField,
      }).then(response => {
        this.$set(this.parent[this.parentField], this.rowIndex, response.data.item)
      })
    },

    deleteAndUpdateStore () {
      this.$delete(this.parent[this.parentField], this.rowIndex)
    },

    fixQuantity () {
      if (!this.item.quantity) { return }
      if (this.modelName === 'expense_items') {
        // Only half numbers
        this.item.quantity = Math.round(parseFloat(parseInt(parseFloat(this.item.quantity.replace(',', '.')) * 5) / 5) * 2) / 2
      } else {
        // Full integers
        this.item.quantity = parseInt(this.item.quantity)
      }
    },

    updateItemOnDefaultsForChange (changedFieldName) {
      this.$store.dispatch('getFormItemOnDefaultsForChange', {
        resource: helper.objectClassUnderscoredName(this.modelName),
        changedFieldName,
        item: this.item,
        queries: workItemQueries.get(this.modelName),
        targetResource: 'works',
        targetId: this.parent.token,
        targetField: this.parentField,
      }).then(response => {
        if (response && response.item) {
          // Add changed field to the response as this is not returned by the API
          response.item[changedFieldName] = this.item[changedFieldName]
          this.item = response.item
        }
      })
    },

    allowOnlyNumbers (e) {
      if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 46 && e.keyCode !== 44) {
        e.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss">
.edit-row {
  td {
    padding: 5px 5px;
  }
}
</style>
