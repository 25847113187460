<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <InspectionProductInstances
          :add-new="true"
          :state-ids="activeWorkStateIds"
          color="green"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InspectionProductInstances from '@/components/Inspection/InspectionProductInstances'
export default {
  name: 'InspectionStep3',

  components: { InspectionProductInstances },

  data () {
    return {
      activeWorkStateIds: [
        3, // New
      ],
    }
  },
}
</script>
