<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <InspectionActivityParkInfo/>
        <InspectionWorks
          :add-new="true"
          :state-ids="activeWorkStateIds"
          work-form-template="default"
          :title="$i18n.t('inspection.activeWorksTitle').toUpperCase()"
          color="green"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InspectionActivityParkInfo from '@/components/Inspection/InspectionActivityParkInfo'
import InspectionWorks from '@/components/Inspection/InspectionWorks'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'

export default {
  name: 'InspectionStep1',

  data () {
    return {
      activeWorkStateIds: [
        state.workStateIds.new,
        state.workStateIds.proposed,
        state.workStateIds.shoppingCart,
        state.workStateIds.ordered,
      ],
    }
  },

  components: { InspectionWorks, InspectionActivityParkInfo },
}
</script>
