<template>
  <v-container class="white elevation-3 mt-5">
    <v-row no-gutters>
      <v-col cols="12" class="h1 px-5 pt-5 pb-3">
        {{ $i18n.t('home.announcements').toUpperCase() }}
      </v-col>
      <template
        v-for="(item, index) in $store.state.announcements"
      >
        <v-col
          :key="index + 's'"
          class="px-3 py-0"
          cols="12"
        >
          <div class="h-spacer-0" />
        </v-col>
        <v-col
          :key="index"
          class="12"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-container>
                  <v-row>
                    <v-col
                      cols="8"
                      class="h2 py-0"
                    >
                      {{ item.header }}
                    </v-col>
                    <v-col cols="4" class="text-right pt-0 h4">
                      {{ formatDate(item.created_at) }}
                    </v-col>
                    <v-col
                      cols="12"
                     v-html="item.message"
                    />
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import helper from '@/helper'
import api from '@/components/Home/HomeRecentInspections'
import activityParksQueries from '@/components/ActivityParks/activityParksQueries'

export default {
  name: 'HomeMessages',

  methods: {
    formatDate (date) {
      return helper.formatDate(date)
    },

    getMessages () {
      const filters = {
        order: 'created_by desc',
        limit: 5,
      }
      // TODO - why use this activityParksQueries here?
      api.getListItems('messages', filters, activityParksQueries.forList, {})
        .then(response => {
          if (response && response.items) {
            this.inspections = response.items
          }
        })
    },
  },
}
</script>
