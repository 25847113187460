import axios from 'axios'

const userQueries = [
  ['name'],
  ['language identifier'],
  ['password_change_date'],
  ['last_login'],
  ['time_zone_info'],
]

const personQueries = [
  ['token,person_number,name,language'],
  ['first_name,last_name,ifs_person_identifier'],
  ['phone,email'],
  ['language identifier'],
  ['organization name'],
  ['street_address street_1,postal_code_string,postal_office_string,latitude,longitude'],
]

export default {
  getUserInfo (userId) {
    personQueries.forEach(personQuery => {
      userQueries.push('person ' + personQuery)
    })
    return this.getItemInfo('user_accounts', userId, userQueries)
  },

  getPersonInfoForEdit (userId) {
    return this.getItemInfoForEdit('people', userId, personQueries)
  },

  // For developer login only
  sendLoginRequest (credentials) {
    return new Promise((resolve) => {
      axios.post('/api/login/', credentials, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
          resolve(err.response.data)
        })
    })
  },

  // For developer login only
  sendLogoutRequest () {
    return new Promise((resolve) => {
      axios.post('/api/logout/', {}, this.requestOptions())
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
          resolve([])
        }).catch((err) => {
          resolve(err.response.data)
        })
    })
  },
}
