export default {
  '@class': 'string',
  id: 'number',
  token: 'string',
  additional_info: 'string',
  works: 'array',
  order_date: 'date',
  person: 'reference',
  organization: 'reference',
}
