export default {
  app: {
    attachment: {
      error: {
        media_type_mismatch: 'Wrong media type'
      },
    },
  },
  general: {
    actions: 'Actions',
    add: 'Add',
    addNewFile: 'Add new file',
    addPicture: 'Add picture',
    address: 'Address',
    all: 'All',
    cancelAndCloseForm: 'Cancel',
    city: 'City',
    clearSearch: 'Clear search',
    clock: 'At.',
    close: 'Close',
    county: 'County',
    detailedSearch: 'Detailed search',
    downloadImage: 'Download image',
    expandAll: 'Expand all',
    feedback: 'Feedback',
    files: 'Files',
    home: 'Home',
    hours: 'Hours',
    loading: 'Loading..',
    logout: 'Logout',
    minutes: 'Minutes',
    month_placeholder: 'mm',
    name: 'Name',
    next: 'Next',
    no_items_found: 'No items found',
    objectState: 'State',
    organization: 'Organization',
    page: 'Page',
    pleaseFillAllRequiredFields: 'Please fill all required fields',
    pressEnterForMultiple: 'Press ENTER to add multiple',
    previous: 'Previous',
    quantity: 'Pcs',
    save: 'Save',
    saveChanges: 'Save changes',
    saved: 'Saved',
    search: 'Search',
    searchByName: 'Search with name',
    searchExplanation: 'Fill one or more search conditions',
    searching: 'Searching...',
    searchResults: 'Search results',
    searchTerm: 'Search term',
    searchText: 'Search...',
    send: 'Send',
    showSearchResults: 'Show search results',
    userManual: 'User manual',
    year_placeholder: 'yyyy',
  },
  home: {
    aligned: 'assigned',
    allAligned: 'Assigned',
    allUnAligned: 'Not assigned',
    announcements: 'Announcements',
    events: 'Events',
    inspected: 'Inspected',
    inspectionWorks: 'Inspection works',
    lastLoginDate: 'Last login date',
    latestInspections: 'Latest inspections',
    reserveWork: 'Reserve work',
    startInspection: 'Start inspection',
    unaligned: 'Not assigned',
    welcome: 'Welcome',
    works: 'Latest works',
  },
  user: {
    address: 'Address',
    changePassword: 'Change password',
    email: 'E-mail',
    firstName: 'First name',
    index: 'Index',
    lastName: 'Last name',
    myInfo: 'My info',
    newPassword: 'New password',
    newPassword2: 'New password again',
    phone: 'Phone',
    state: 'State',
    organization: 'Organization',
  },
  change_password: {
    change_is_mandatory: 'New password must be different from the old password',
    fields_are_mandatory: 'All fields must be filled',
    invalid_password: 'Wrong password',
    new_password_again: 'Confirm new password',
    new_password: 'New password',
    not_authenticated: 'You need to be logged in before changing the password',
    old_password: 'Your current password',
    password_change_failed: 'Changing password failed. Password must be at least 8 characters long',
    password_changed: 'Password changed succesfully',
    password_expired: 'Your password has expired. Set a new password',
    passwords_dont_match: 'Password and password confirmation do not match',
    submit: 'Submit',
    title: 'Change password',
  },
  inspection: {
    activeWorksTitle: 'Active works',
    addNewAdditionalWork: 'Add new extra work',
    addNewWork: 'Add new work',
    createInspectionReportForActivityPark: 'Carry out inspection work',
    extraWorksTitle: 'Extra works',
    finish: 'Save and complete inspection',
    followUpWorkDescription: 'Long term planning',
    followUpWorkDescriptionText: 'Overall assessment of the activity park and its condition...',
    followUpWorkTitle: 'Long term planning',
    maintenanceTitle: 'Routine maintenance',
    maintenanceTitleText: 'Carry out routine maintenance',
    noMaintenanceTasks: 'No routine maintenance completed for the activity park',
    noWorkSelectedMessage: 'Please select a work to start an inspection',
    productInstancesTitle: 'Equipment',
    reportingInspectionWork: 'Complete inspection work',
    summaryDescription: 'Additional information',
    summaryDescriptionText: 'Additional information regarding the inspection (max. 60 marks)',
    summaryTimeSpentText: 'Report the time used for the inspection work, if needed',
    summaryTimeSpentTitle: 'Time used for inspection work',
    taskErrorDescription: 'Routine maintenance',
    taskWorkDescriptionPrefix: 'Routine maintenance completed:',
    tasks: {
      task1: 'Flattening of safety surfaces',
      task2: 'Brushing of wet pour or rubber slabs',
      task3: 'Brushing of sandbox sides',
      task4: 'Brushing of other surfaces',
      task5: 'Tightening of fasteners',
      task6: 'Greasing of swingframe bearings',
      task7: 'Adjusting of seat height',
      task8: 'Removal of extra chains from swing seats',
      task9: 'Cleaning of falling space',
    },
  },
  ratings: {
    title: 'Ratings',
    conditionRating: 'Overall condition',
    productRating: 'Play value',
    riskRating: 'Risk classification'
  },
  organizations: {
    chooseOne: 'Choose only one organization',
    country: 'Country',
    createdAt: 'Created at',
    language: 'Language',
    listEmpty: 'No organizations found',
    loading: 'Loading organizations..',
    name: 'Name',
    title: 'Organizations',
  },
  activityParks: {
    activity_park_areas: 'Activity park areas',
    activity_park_type: 'Activity park type',
    activityPark: 'Activity park',
    activityParkInfo: 'Activity park info',
    activityParkPlayAreas: 'Activity park areas',
    activityParkType: 'Activity park type',
    addNewEquipment: 'New equipment',
    addNewWork: 'New fault message',
    changeInfoTitle: 'Change activity park info',
    commentSaved: 'Comment sent!',
    daysAgo: 'd ago',
    deleteButton: 'Delete Activity Park',
    deleteReason: 'Delete reason',
    deleteTitle: 'Delete activity park',
    giveFeedback: 'Give feedback',
    hide: 'Hide',
    hideSuccess: 'Activity park hidden!',
    inspectedInLastYear: 'Inspected within a year',
    inspection: 'Inspection',
    inspectionReport: 'Inspection report',
    long_term_plans: 'Long term plans',
    orderedWorks: 'Ordered Works',
    organizationPerson: 'Responsible person',
    play_areas: 'Areas',
    search: 'Search with name',
    selectContactPerson: 'Activity park contact person',
    title: 'Activity parks',
    work_priority: 'Work priority',
    deleteConfirm: 'Are you sure you want to delete the activity park?',
  },
  playAreas: {
    area: 'Area',
    description: 'Description',
    number: 'Number',
    onlyOne: 'Only one play area',
  },
  productInstances: {
    addNewEquipment: 'Add new equipment',
    addNewEquipmentFor: 'Add new equipment for',
    changeInfoTitle: 'Change equipment info',
    conditionScore: 'Overall condition',
    conditionScoreNotSet: 'Not set',
    deleteConfirm: 'Are you sure you want to delete the equipment?',
    deleteTitle: 'Delete equipment',
    equipment: 'Equipment',
    installationDate: 'Installation date',
    manufacturer: 'Manufacturer',
    playValue: 'Play value',
    product_instance_category: 'Equipment category',
    product_instance_type: 'Equipment type',
    productCode: 'Product code',
    productNumber: 'Product number',
    safe_zone_size: 'Safety area size',
    safety_platform_type: 'Safety surface type',
    searchEquipmentInfoTitle: 'Equipment info',
    searchOrganizationInfoTitle: 'Organization info',
    standard: 'Standard',
    title: 'Equipment',
    type: 'Type',
    foundation: {
      deep: 'Deep foundation ',
      surface: 'Surface foundation',
      title: 'Foundation',
      wood: 'Wooden foundation',
      free_standing: 'Free standing',
    },
    material: {
      metal: 'Metal',
      plastic: 'Plastic',
      title: 'Material',
      wood: 'Wood',
      rubber_granulate: 'Rubber granulate',
      hpl_laminate: 'HPL laminate',
    },
    product_code: {
      lappset: 'LAPPSET',
      product: 'PRODUCT',
    },
  },
  workOrders: {
    cost: 'Cost',
    listEmpty: 'No work orders found',
    loading: 'Loading work orders..',
    mainDateShort: 'Date',
    orderer: 'Ordered by',
    orderNumber: 'Order number',
    title: 'Work orders',
  },
  works: {
    activityPark: 'Activity park',
    addNewWork: 'Add new fault message',
    addNewWorkFor: 'Add new fault message for',
    addPictureInfoText: 'Add a picture of a broken equipment or a fault',
    chooseExpense: 'Select cost type',
    chooseSparePart: 'Select spare part from stock',
    cost_type: 'Cost type',
    cost: 'Price',
    deleteConfirm: 'Are you sure you want to delete the fault message?',
    deleteTitle: 'Delete fault message',
    description: 'Description',
    editOldWork: 'Edit work',
    equipmentText: 'Select equipment',
    errorDescription: 'Fault description',
    errorDescriptionText: 'Or write the fault description',
    expense_items: 'Expenses',
    faultDescriptionText: 'Select fault description',
    inspection_description: 'Actions performed and additional information',
    inspectionDescriptionText: 'Write actions performed and additional information if needed. Max. 60 marks.',
    listEmpty: 'No fault messages found',
    loading: 'Loading fault messages..',
    mainDateShort: 'Date',
    organization: 'Organization',
    priority: 'Priority',
    productInstanceType: 'Equipment type',
    pts: 'LTP',
    searchActivityParkInfo: 'Activity park info',
    searchModalText: 'Fill one or multiple options',
    searchModalWorkInfo: 'Work info',
    searchOrganizationInfoTitle: 'Organization info',
    selectOrganizationPerson: 'Contact person',
    selectWorkType: 'Select work type',
    spare_part_items: 'Spare parts',
    standard: 'Standard',
    standardText: 'Choose standard',
    takeWorkConfirmation: 'Are you sure you want to reserve a work for this activity park?',
    title: 'Works',
    toggleWorkInReport_false: 'Hidden from report',
    toggleWorkInReport_true: 'Shown on report',
    tooMany: 'Too many works selected (over 1000)',
    work_cost_items: 'Work cost items',
    workDescription: 'Additional information',
    workDescriptionText: 'Write actions performed',
    workDescriptionTextForExtra: 'Write required actions',
    workNumber: 'Number',
    workPriority: 'Priority',
    workState: 'Work state',
    workType: 'Work type',
    zoneSelectsTitle: 'Limit by area',
    removeReason: {
      button: 'Remove work',
      title: 'Remove reason',
      work_dismantled: 'Work dismantled',
      work_fixed: 'Work fixed',
      work_removed: 'Work removed',
      work_unnecessary: 'Work unnecessary',
      work_updated: 'Work updated',
    },
    cost_types: {
      external: 'External',
      fixed_price: 'Fixed price',
      material: 'Material',
      personnel: 'Personnel',
    },
  },
  workCostItems: {
    cost_type: 'Cost type',
    catalog_no: 'Catalog no',
    description: 'Description',
    quantity: 'Quantity',
    unit_sales_price: 'Unit sales price',
    total_amount: 'Total amount',
  },
  shoppingCart: {
    cost: 'Cost',
    empty: 'Shopping cart is empty',
    extraInfo: 'Additional information',
    offerExtraInfo: 'Additional information about offer request',
    offerIsSent: 'Your offer request is sent',
    offerThankYouMessage: 'Thank you for the offer request!',
    orderExtraInfo: 'Additional order info',
    orderIsSent: 'Your order is sent',
    orderThankYouMessage: 'Thank you for your order!',
    sendOffer: 'Send offer request',
    sendOrder: 'Send order',
    title: 'Shopping cart',
  },
  privacy_policy_modal: {
    title: 'Accept privacy and cookie policies',
    button: 'Accept',
    privacy_policy_text: 'Read privacy policy from here:',
    privacy_policy_link: 'Privacy policy',
    cookie_policy_text: 'Read cookie policy from here:',
    cookie_policy_link: 'Cookie policy',
  },
  contact: {
    address: 'Torpparinmäentie 4, 00690 Helsinki',
    email: 'playcare@lappset.com',
    phone: '+358 207 750 150',
    title: 'Contacts',
    menu1: {
      title: 'Services',
      link1: {
        name: 'Installation',
        url: 'https://www.playcare.fi/palvelut/Leikki--ja-liikuntapaikan-asennus',
      },
      link2: {
        name: 'Inspection',
        url: 'https://www.playcare.fi/palvelut/Leikkipaikan-tarkastus',
      },
      link3: {
        name: 'Maintenance and spare parts',
        url: 'https://www.playcare.fi/palvelut/Leikkipaikan-ja-liikunta-alueen-huolto-ja-varaosat',
      },
      link4: {
        name: 'New Life',
        url: 'https://www.playcare.fi/palvelut/Leikkipuiston-korjaus-Uusi-Elama',
      },
      link5: {
        name: 'Cleaning of safety surfaces',
        url: 'https://www.playcare.fi/palvelut/Turva-alustan-puhdistus-ja-huolto',
      },
    },
    menu2: {
      title: 'Other services',
      link1: {
        name: 'Spare part search',
        url: 'https://www.playcare.fi/Varaosahaku',
      },
      link2: {
        name: 'Downloadable files',
        url: 'https://www.playcare.fi/UI/toolsmenu/Ladattavat-tiedostot',
      },
    },
  },
}
