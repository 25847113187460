<template>
  <v-container class="dialog-container">
    <v-row class="header-row">
      <v-col cols="10" class="h1">
        {{ $i18n.t('general.detailedSearch').toUpperCase() }}:
      </v-col>
      <v-col cols=2 class="text-right ma-0 pa-0">
        <v-btn small fab text @click="showWorksSearchForm = false">
          <v-icon >
            fa-times
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="content-row">
      <v-col>
        <v-container
          fluid class="search-form"
          :style="{ height: dialogHeight + 'px' }"
        >
          <v-row>
            <v-col cols="12">
              {{ $i18n.t('works.searchModalText') }}
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <v-col cols="12" class="h2">
              {{ $i18n.t('works.searchModalWorkInfo') }}:
            </v-col>

            <v-col
              cols="3"
              class="flex-grow-0 flex-shrink-1 align-self-center"
              style="min-width: 160px; max-width: 160px;"
            >
              {{ $i18n.t('works.workState') }}
            </v-col>

            <v-col cols="9">
              <v-btn-toggle
                v-model="filters.main_object_state_id"
                multiple
                color="black"
              >
                <v-btn
                  v-for="(stateOption, i) in workObjectStates"
                  :key="i + '_state'"
                  :value="stateOption.id"
                >
                  {{ stateOption[`name_${$i18n.locale || 'en'}`].toUpperCase() }}
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12">
              {{ $i18n.t('works.workType') }}
            </v-col>

            <v-col cols="12">
              <v-btn-toggle
                v-model="filters.pts"
                color="black"
              >
                <v-btn value='true'>
                  {{ $i18n.t('works.pts') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12">
              <MultiselectDropdown
                :item="filters"
                :label="$i18n.t('works.selectWorkType')"
                :load-on-focus="true"
                search-class="work_types"
                field-name="work_type"
                model="works"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>

            <v-col
              cols="3"
              class="flex-grow-0 flex-shrink-1 align-self-center"
              style="min-width: 160px; max-width: 160px;"
            >
              {{ $i18n.t('works.workPriority') }}
            </v-col>

            <v-col
              cols="9"
              v-bind="growButtonAttributes"
            >
              <PrioritySelect
                v-model="worksFilters.priority"
                :multiple="true"
                :show-all-button="true"
              />
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <v-col cols="12" class="h2">
              {{ $i18n.t('works.searchOrganizationInfoTitle') }}:
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="globalFilters"
                :label="$i18n.t('general.all') + ' ' + $i18n.t('organizations.title').toLocaleLowerCase()"
                search-class="organizations"
                field-name="organization"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>
            <v-col cols="12">
              <MultiselectString
                :item="filters"
                :label="$i18n.t('works.selectOrganizationPerson')"
                field-name="responsiblePeople"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <v-col cols="12" class="h2">
              {{ $i18n.t('works.searchActivityParkInfo') }}:
            </v-col>
            <v-col cols="12">
              <MultiselectDropdown
                :item="globalFilters"
                :label="$i18n.t('general.all') + ' ' + $i18n.t('activityParks.title').toLocaleLowerCase()"
                search-class="activity_parks"
                field-name="activity_park"
                :style-attributes="textFieldFormatAttributes"
              />
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <!-- v-col cols="12" class="h2">
              {{ $i18n.t('works.zoneSelectsTitle') }}:
            </v-col>
            <v-col cols="12">
              <v-combobox
                :items="[2000, 2001, 2002, 2003, 2004]"
                :label="$i18n.t('general.county')"
                multiple
                v-bind="textFieldFormatAttributes"
              />
            </v-col>

            <v-col cols="12"><div class="h-spacer"/></v-col>

            <v-col cols="12">
              <v-combobox
                :items="[2000, 2001, 2002, 2003, 2004]"
                :label="$i18n.t('general.city')"
                multiple
                v-bind="textFieldFormatAttributes"
              />
            </v-col -->
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="buttons-row">
      <v-col cols="6">
        <v-btn
          color="#8cc477"
          dark
          v-bind="popupButtonFormatAttributes"
          @click="search()"
        >
          {{ $i18n.t('general.showSearchResults') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="#666"
          block
          outlined
          v-bind="popupButtonFormatAttributes"
          @click="clear()"
        >
          {{ $i18n.t('general.clearSearch') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import PrioritySelect from '@/components/Shared/PrioritySelect'
import allMethods from '@/components/allMethods'
import worksMethods from '@/components/Works/worksMethods'
import MultiselectDropdown from '@/components/Shared/MultiselectDropdown'
import MultiselectString from '@/components/Shared/MultiselectString'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'WorksSearchForm',

  components: {
    MultiselectString,
    MultiselectDropdown,
    PrioritySelect,
  },

  data () {
    return {
      model: 'works',
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    filters () {
      return this.worksFilters
    },

    growButtonAttributes () {
      return {
        style: 'min-width: 50px; max-width: 100%;',
        class: 'flex-grow-1 flex-shrink-0',
      }
    },
  },

  methods: {
    ...allMethods,
    ...worksMethods,

    search () {
      this.goToFirstPage()
      this.getListItems()
      this.showWorksSearchForm = false
    },

    clear () {
      this.clearSearch()
      this.search()
    },
  },
}
</script>
